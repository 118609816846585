import {Loader} from "@components/general";
import {getAxiosClient} from "@lib/api";
import {useAfterMountEffect, useSessionStorage, useWebform} from "@lib/hooks";
import {handleError, successMessage} from "@lib/utils";
import {applyStatesAndConditions, flattenElements, foreachElements, normalizeValues} from "@lib/webform";
import {DrupalComplexWebform} from "@type/entity";
import {WebformValues, WizardPageElement} from "@type/general";
import classNames from "classnames";
import {cloneDeep, findKey, first, keys, map, reduce} from "lodash";
import md5 from "md5";
import {useRouter} from "next/router";
import {useEffect, useMemo, useRef, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import {t} from "@lib/translations-provider";
import classes from "../Webform.module.scss";
import Style from "../Webform.module.scss";

type ComplexWebformProps = {
  webform: DrupalComplexWebform;
};

type WizardWebformProps = {
  onSubmit: (values: WebformValues, wizardId: string) => void;
  onChange?: (values: WebformValues, wizardId: string) => void;
  onPrev: () => void;
  wizardId: string;
  elements: WizardPageElement;
  visible?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  defaultValues?: WebformValues;
};

type ComplexWebformData = Record<string, WebformValues>;

/**
 * Complex webforms and wizard forms.
 *
 */
const WizardWebform = ({
  elements,
  onSubmit,
  onChange,
  onPrev,
  wizardId,
  visible = false,
  isLast = false,
  isFirst = false,
  defaultValues,
}: WizardWebformProps) => {
  const form = useForm<WebformValues>({
    defaultValues,
  });
  const {isSubmitting} = form.formState;
  const beforeSubmit = (values: WebformValues) => {
    onSubmit(values, wizardId);
  };

  /**
   * Pass the values to the parent component.
   */
  useAfterMountEffect(() => {
    onChange && onChange(form.watch(), wizardId);
  }, [md5(JSON.stringify(form.watch()))]);

  return (
    <Form
      className={classNames({
        "d-none": !visible,
        "disabled": isSubmitting,
      })}
      onSubmit={form.handleSubmit(beforeSubmit)}
    >
      {/* The elements */}
      <FormProvider {...form}>{foreachElements(elements)}</FormProvider>

      {/* Form actions */}
      <div className={classNames(classes.formActions)}>
        {/* Prev button */}
        {!isFirst && (
          <Button aria-label="Previous" disabled={isSubmitting} onClick={onPrev}>
            {t("Previous")}
          </Button>
        )}

        {/* Next/Submit button */}
        <Button aria-label="Next" disabled={isSubmitting} variant="primary-rounded" className={Style.submitButton} type="submit">
          {isLast ? t("Submit") : t("Next")}
        </Button>
      </div>
    </Form>
  );
};

/**
 * The complete webform, contains many wizard forms.
 *
 * @param props
 */
export const ComplexWebform = ({webform}: ComplexWebformProps) => {
  const {sessionStorageValue, setSessionStorageValue, remove} = useSessionStorage<ComplexWebformData>(
    `webform_${webform.id}`,
  );
  const [data, setData] = useState<ComplexWebformData | undefined>(sessionStorageValue);
  const {components} = useWebform();

  /**
   * We flatten the values in data to make it easier for "applyStatesAndConditions" to access the values.
   */
  const flattenedValues: Record<string, string> = useMemo(
    () =>
      reduce(
        data,
        (prev, curr) => {
          return {...prev, ...curr};
        },
        {},
      ),
    [data],
  );

  const router = useRouter();

  /**
   * Gets the form from 'page' query.
   */
  const activeFormFromQuery = useMemo(() => {
    const urlParams = new URLSearchParams(typeof window !== "undefined" ? window.location.search : "");
    return (router.query.page || urlParams.get("page")) as string;
  }, [router.query.page, router.isReady]);

  const [activeForm, setActiveFormId] = useState<string>();
  const initialized = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Handles the change from wizard forms and update the data, the data is used later to save it to the session storage.
   */
  const handleChange = (values: WebformValues, wizardId: string) => {
    if (router.isReady && !isLoading) {
      setData({
        ...data,
        [wizardId]: values,
      });
    }
  };

  /**
   * Push the formId to page query.
   */
  const setActiveForm = (formId: string) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          page: formId,
        },
      },
      undefined,
      {shallow: true},
    );
  };

  /**
   * Gets the value from the flattenedValues.
   */
  const getFormFlattenedValues = (key: string) => {
    return flattenedValues[key];
  };

  /**
   * Flatten all elements.
   */
  const flattenedElements = useMemo(() => flattenElements(webform.elements), [webform.elements]);

  /**
   * Get all the wizard pages keys.
   */
  const formKeys = useMemo(() => {
    return keys(webform.elements);
  }, [webform]);

  /**
   * This apply the states and conditions to the elements.
   */
  const doApplyStates = (): Promise<void> =>
    new Promise((resolve) => {
      applyStatesAndConditions(webform.elements, flattenedElements, getFormFlattenedValues);
      applyStatesAndConditions(webform.elements, flattenedElements, getFormFlattenedValues);
      resolve();
    });

  /**
   * Apply states twice. Do it 2 times because some states depend on other states.
   *
   * This will solve the problem of states depending on the order of the elements.
   *
   * @todo This might not be the best solution.
   */
  useEffect(() => {
    const doEffect = async () => {
      setIsLoading(true);
      await doApplyStates();
      setIsLoading(false);
    };

    doEffect();
  }, [webform.elements, flattenedElements, flattenedValues, activeFormFromQuery]);

  /**
   * Saves the data to session storage.
   */
  useEffect(() => {
    if (data) {
      setSessionStorageValue(data);
    }
  }, [data]);

  /**
   * This effect will watch the query parameter 'page' and set the active form according to it.
   */
  useEffect(() => {
    if (router.isReady && initialized.current && activeForm !== activeFormFromQuery) {
      // activeFormFromQuery might be undefined because there is no query. If that
      // is the case, make sure to redirect to the first wizard ID.
      if (activeFormFromQuery) {
        setActiveFormId(activeFormFromQuery);
      } else {
        const firstWizardId = first(keys(webform.elements));
        if (firstWizardId) {
          setActiveFormId(firstWizardId);
        }
      }
    }
  }, [activeFormFromQuery]);

  /**
   * This useEffect is used to initialize the
   */
  useEffect(() => {
    const firstWizardId = first(keys(webform.elements));
    if (activeFormFromQuery) {
      setActiveFormId(activeFormFromQuery);
    } else if (firstWizardId) {
      setActiveFormId(firstWizardId);
    } else {
      console.warn("No wizard page was found");
    }
  }, []);

  /**
   * When the router is ready, check if the active form is valid, if not, redirect to the first valid form.
   *
   * Also, this will set initialized to true.
   */
  useEffect(() => {
    const firstWizardId = first(keys(webform.elements));
    if (router.isReady) {
      initialized.current = true;
    }

    if (
      activeFormFromQuery &&
      (!sessionStorageValue || !sessionStorageValue[activeFormFromQuery]) &&
      router.isReady &&
      firstWizardId
    ) {
      setActiveForm(firstWizardId);
    }
  }, [router.isReady]);

  /**
   * This will scroll to the top of the page when the active form changes.
   */
  useEffect(() => {
    if (containerRef.current) {
      // scroll at the top with an offset
      setTimeout(() => {
        window.scrollTo({
          top: containerRef.current ? containerRef.current.offsetTop - 400 : 0,
          behavior: "smooth",
        });
      });
    }
  }, [activeForm]);

  /**
   * Check if there is a next wizard page after wizardId.
   */
  const hasNext = (wizardId: string) => {
    const num = findKey(formKeys, (formId) => formId === wizardId);
    if (num) {
      return formKeys[parseInt(num) + 1];
    } else {
      return false;
    }
  };

  /**
   * Check if the wiazrdID is the first wizard page.
   */
  const isFirst = (wizardId: string) => {
    const num = findKey(formKeys, (formId) => formId === wizardId);
    return !!(num && parseInt(num) === 0);
  };

  /**
   * Go to the next wizard page.
   */
  const goNext = async (values: WebformValues, wizardId: string) => {
    const newData = cloneDeep(data) || {};
    newData[wizardId] = values;
    setData(newData);

    if (hasNext(wizardId)) {
      const currentKey = findKey(formKeys, (formId) => formId === activeForm);
      if (currentKey) {
        const nextFormId = formKeys[parseInt(currentKey) + 1];
        if (nextFormId) {
          setActiveForm(nextFormId);
        } else {
          console.error("You cannot go next if there is no next form");
        }
      } else {
        console.warn("No wizard page was found");
      }
    } else {
      await handleSubmit(newData);
    }
  };

  /**
   * Go to the previous wizard page.
   */
  const goPrev = () => {
    const currentKey = findKey(formKeys, (formId) => formId === activeForm);
    if (currentKey) {
      const prevFormId = formKeys[parseInt(currentKey) - 1];
      if (prevFormId) {
        setActiveForm(prevFormId);
      } else {
        console.error("You cannot go prev if there is no previous form");
      }
    } else {
      console.warn("No wizard page was found");
    }
  };

  /**
   * This method is called when the last wizard page is submitted.
   */
  const handleSubmit = async (values: ComplexWebformData) => {
    setIsLoading(true);

    // Seperate the fields into one normal object instead of complex pages with fields.
    let normalizedValues: WebformValues = reduce(
      values,
      (prev, curr) => {
        return {...prev, ...curr};
      },
      {},
    );

    // Actually call the submit API.
    try {
      normalizedValues = await normalizeValues(normalizedValues, flattenedElements, components);
      const axiosClient = getAxiosClient();
      await axiosClient.post(`/api/webform/${webform.id}`, normalizedValues);
      // TODO: Message.
      successMessage(t("Your submission has been sent."));
      remove();
      router.push("/");
    } catch (e: unknown) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div ref={containerRef}>
      <Loader status={[isLoading, !router.isReady]} keepContent contentType="page">
        {() =>
          map(webform.elements, (element, wizardId) => {
            return (
              <WizardWebform
                key={wizardId}
                defaultValues={data?.[wizardId]}
                wizardId={wizardId}
                visible={wizardId === activeForm}
                isLast={!hasNext(wizardId)}
                isFirst={isFirst(wizardId)}
                elements={element}
                onPrev={goPrev}
                onSubmit={goNext}
                onChange={handleChange}
              />
            );
          })
        }
      </Loader>
    </div>
  );
};
