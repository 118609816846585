import moment from "moment";
import React, {useMemo} from "react";
import DatePicker from "react-datepicker";
import Calander from "@icons/yellow-calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import {DateElement, GenericControlController} from "@type/general";
import {useRouter} from "next/router";
import {isValidDate} from "@lib/utils";
import {t} from "@lib/translations-provider";
import {Icon} from "@components/elements";

const WebformDatePicker = ({value, webformElement, onChange, name}: GenericControlController<DateElement>) => {
  const placeHolder = webformElement["#placeholder"] ?? t("Select Date");
  const safeValue = useMemo(() => {
    if (value) {
      const date = moment(value).toDate();
      if (isValidDate(date)) {
        return date;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [value]);

  const locale = useRouter().locale;
  let placement;
  locale === "ar" ? (placement = "bottom-end") : (placement = "bottom-start");

  /**
   * Handles the change.
   */
  const handleChange = (date: Date | null) => {
    try {
      if (date) {
        onChange(moment(date).locale("en").format("YYYY-MM-DD"));
      } else {
        onChange(null);
      }
    } catch (e) {
      onChange(null);
    }
  };

  return (
    <div className="w-fit relative">
      <DatePicker
        popperPlacement={"bottom-end" || placement}
        showPopperArrow={false}
        placeholderText={placeHolder}
        name={name}
        selected={safeValue}
        minDate={new Date("1900-01-01")}
        onChange={handleChange}
      />
      <Icon
        icon={Calander}
        className="w-[24px] h-[24px] custom-stroke-blue-300  absolute top-[50%] left-4 translate-y-[-50%]"
      />
    </div>
  );
};

export default WebformDatePicker;
