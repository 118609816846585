import {Button} from "@components/elements";
import {getDirection, t} from "@lib/translations-provider";
import {ChangeEvent} from "react";
import ArrowIcon from "@icons/arrow-left.svg";
import classNames from "classnames";
type Props = {
  props: {
    disableFirstButtons: boolean;
    disableLastButtons: boolean;
    activePage: number;
    totalPages: number;
    onPageChange: (number: number) => void;
    selectedPage: number | string;
    setSelectedPage: (number: number | string) => void;
  };
};
const InputPagination = ({props}: Props) => {
  const isRtl = getDirection() === "rtl";
  const {activePage, totalPages, onPageChange, selectedPage, setSelectedPage} = props;
  const handlePageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setSelectedPage("");
    } else {
      const parsedValue = parseInt(value);
      if (parsedValue > 0 && parsedValue <= totalPages && !Number.isNaN(parsedValue)) {
        setSelectedPage(parsedValue);
      }
    }
  };
  return (
    <div className={"flex-auto  max-tablet:flex-grow-0  "}>
      <div
        className={
          "flex flex-wrap justify-end  items-center max-tablet:flex-col  max-tablet:mt-6 max-tablet:justify-start"
        }
      >
        <div className="fs-24 font-normal max-tablet:flex-[100%] max-tablet:w-full">
          <span className="">{t("Go to")} </span>
        </div>
        <div className="flex">
          <div className={"ms-[12px] max-tablet:ms-0"}>
            <input
              aria-label="Pager"
              className="max-w-[78px] min-h-[48px] rounded-lg w-full text-center border-[#dbdbdb] focus:border-[#dbdbdb] focus:shadow-none focus:ring-0 max-tablet:w-[50px]"
              onBlur={(e) => (e.target.value = `${selectedPage}`)}
              onFocus={(e) => (e.target.value = " ")}
              type="text"
              value={selectedPage}
              onChange={(e) => handlePageChange(e)}
            />
          </div>
          <div>
            <Button
              aria-label="Next"
              variant="minimal"
              icon={ArrowIcon}
              iconClassName="w-[24px] h-[24px]"
              disabled={selectedPage === activePage + 1}
              onClick={() => {
                const parsedValue = parseInt(selectedPage as string);
                onPageChange(parsedValue - 1);
              }}
              className={classNames(
                "py-[9px] px-[12px] border-primary ms-[12px] flex-1 justify-center font-normal rounded-lg border max-w-[48px] min-h-[48px] flex items-center ",
                isRtl ? "transform rotate-0" : "transform rotate-180",
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputPagination;
