import {Slider} from "@components/general";
import {SliderProps} from "@components/general/Slider";
import EntityRenderer from "@components/hoc/EntityRenderer";
import generalViewsClasses from "@components/views-components/views.module.scss";
import {ViewPartComponentProps} from "@type/layout";
import classNames from "classnames";
import map from "lodash/map";
import {useMemo} from "react";
export const CarouselList = ({resources, viewMode, swiperSettings = {}, equalizer, meta}: ViewPartComponentProps) => {
  const {itemsPerView = 1, ...rest} = swiperSettings as Omit<SliderProps, "items">;
  const classNameForPerView = itemsPerView > 1 ? "items-per-view" : "";
  const resourcesElements = useMemo(() => {
    return map(resources, (resource) => {
      return <EntityRenderer key={resource.id} resource={resource} viewMode={viewMode} />;
    });
  }, [resources]);
  return (
    <Slider
      className={classNames(classNameForPerView, equalizer && generalViewsClasses.equalizer)}
      items={resourcesElements}
      perView={itemsPerView}
      {...rest}
    />
  );
};
export default CarouselList;
