import {MenuParentProps} from "@type/general";
import classNames from "classnames";
import {DrupalMenuLinkContent} from "next-drupal";
import {Dispatch, ForwardedRef, SetStateAction, forwardRef, useEffect, useState} from "react";
import React from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import {CaretDownIcon} from "@radix-ui/react-icons";
import map from "lodash/map";
import Link from "next/link";
import {getMenuItem, isActive} from "@lib/menu-utils";
import {useLayout, useMediaQuery} from "@lib/hooks";
import {useRouter} from "next/router";
import {Button} from "@components/elements";
import some from "lodash/some";

interface MenuProps {
  menuContent: DrupalMenuLinkContent[];
  menuToTop: boolean;
  hidemenu: boolean;
  handleScroll: (e: Event) => void;
  setMenuToTop: Dispatch<SetStateAction<boolean>>;
}
type DesktopMenuProps = MenuProps;

const ListItem = React.forwardRef<
  HTMLAnchorElement,
  {
    className?: string;
    menuLink: DrupalMenuLinkContent;
    menuToTop: boolean;
    isHomepage: boolean;
    hidemenu: boolean;
  }
>(({className, menuLink, menuToTop, hidemenu, isHomepage, ...props}, forwardedRef) => {
  const isActiveMenu = isActive(menuLink);
  return (
    <Link
      prefetch={false}
      aria-label={menuLink.title}
      href={menuLink.url}
      className={classNames(
        "font-scale-22  py-[7px] 2desktop:px-[20px] px-2 max-desktop:px-2 rounded-full group flex select-none items-center justify-between gap-2 text-[22px] font-medium outline-none focus:shadow-none focus:border-0",
        menuToTop && (isHomepage || hidemenu) ? "text-primary" : "text-white",
        isActiveMenu && menuToTop && "text-primary-darker",
        isActiveMenu && !menuToTop && "bg-primary-darker",
        "transition-all ",
        className,
      )}
      passHref
      {...props}
      ref={forwardedRef}
    >
      {menuLink.title}
    </Link>
  );
});
type ListDropdownProps = {
  className?: string;
  menuLink: MenuParentProps;
  setMenuToTop?: (value: boolean) => void;
  handleScroll?: (e: Event) => void;
  menuToTop: boolean;
  isHomepage: boolean;
  hidemenu: boolean;
};

const ListDropdown = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ListDropdownProps>(
  (
    {className, menuLink, isHomepage, hidemenu, menuToTop = false, setMenuToTop, handleScroll, ...props},
    forwardedRef,
  ) => {
    const isParentMenuActive = some(menuLink.children, (child) => isActive(child));
    return (
      <>
        <NavigationMenu.Trigger
          onClick={() => {
            setMenuToTop && setMenuToTop(true);
          }}
          onScroll={(e) => handleScroll && handleScroll(e as unknown as Event)}
          asChild
        >
          <div
            className={classNames(
              " py-2 2desktop:px-5 px-2 rounded-full max-laptop:px-[10px] max-desktop group flex select-none items-center justify-between gap-2 outline-none focus:shadow-none focus:border-0",
              isParentMenuActive && !hidemenu && "bg-primary-darker",

              "transition-all",
            )}
            aria-label={menuLink.title}
          >
            <Button
              ref={forwardedRef as ForwardedRef<HTMLButtonElement>}
              onClick={() => {
                setMenuToTop && setMenuToTop(true);
              }}
              variant="minimal"
              className="text-white leading-normal font-scale-22 font-medium"
              aria-label={menuLink.title}
            >
              {menuLink.title}
            </Button>
            <CaretDownIcon
              className={classNames(
                "text-primary top-[1px] transition-transform duration-200 ease-in group-data-[state=open]:-rotate-180",
                hidemenu || (isHomepage && menuToTop) ? "[&>*]:fill-primary" : "[&>*]:fill-white",
                isParentMenuActive && hidemenu && "[&>*]:fill-primary-darker",
              )}
              aria-hidden
            />
          </div>
        </NavigationMenu.Trigger>
      </>
    );
  },
);

export const DesktopMenu = forwardRef<HTMLElement, DesktopMenuProps>(
  ({menuContent, menuToTop, hidemenu, setMenuToTop, handleScroll}, ref) => {
    const {resource} = useLayout();
    const router = useRouter();
    const menuItems = getMenuItem(menuContent);
    const isHomepage = (resource.canonical as string).includes("/node/1");
    return (
      <NavigationMenu.Root
        ref={ref}
        dir={router.locale === "ar" ? "rtl" : "ltr"}
        className={classNames("desktop-menu flex w-full justify-center z-[100] transition-all fixed top-[82%]", {
          "-translate-y-[-300%] opacity-0 transition-all duration-300 -z-10": !isHomepage || menuToTop,
        })}
      >
        <div className={classNames("container flex [&>div]:w-full items-end")}>
          <NavigationMenu.List
            className={classNames(
              "center container bg-primary shadow-main-menu-fixed gap-2 m-0 flex list-none justify-center rounded-[50px] px-[20px] transition-all py-[16px]",
            )}
          >
            {map(menuItems, (menuLink: MenuParentProps, index) => {
              return (
                <NavigationMenu.Item value={menuLink.id} key={menuLink.id}>
                  {menuLink.children && menuLink.children.length > 0 ? (
                    <ListDropdown
                      menuLink={menuLink}
                      handleScroll={handleScroll}
                      menuToTop={menuToTop}
                      setMenuToTop={setMenuToTop}
                      isHomepage={isHomepage}
                      hidemenu={hidemenu}
                    />
                  ) : (
                    <ListItem menuToTop={menuToTop} menuLink={menuLink} isHomepage={isHomepage} hidemenu={hidemenu} />
                  )}
                </NavigationMenu.Item>
              );
            })}
          </NavigationMenu.List>
        </div>
      </NavigationMenu.Root>
    );
  },
);

export const StickyMenu = forwardRef<HTMLElement, MenuProps>(
  ({menuContent, menuToTop, hidemenu, setMenuToTop, handleScroll}, ref) => {
    const [showDesktopMenu, setShowDesktopMenu] = useState(false);
    const isMobile = useMediaQuery(1200);
    useEffect(() => {
      if (showDesktopMenu == false && !isMobile) {
        setShowDesktopMenu(true);
      } else {
        if (showDesktopMenu == true && isMobile) {
          setShowDesktopMenu(false);
        }
      }
    }, [isMobile]);
    return !isMobile && showDesktopMenu ? (
      <DesktopMenu
        ref={ref}
        menuContent={menuContent}
        menuToTop={menuToTop}
        hidemenu={hidemenu}
        setMenuToTop={setMenuToTop}
        handleScroll={handleScroll}
      />
    ) : null;
  },
);
