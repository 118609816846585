import React, {PropsWithChildren, useState} from "react";
import classNames from "classnames";
import * as Accordion from "@radix-ui/react-accordion";
import PlusIcon from "@icons/add.svg";
import MinusIcon from "@icons/minus.svg";
const AccordionItem = React.forwardRef<HTMLDivElement, PropsWithChildren<Accordion.AccordionItemProps>>(
  ({children, className, ...props}, forwardedRef) => (
    <Accordion.Item
      className={classNames(
        "mt-px overflow-hidden mb-8 first:mt-0 first:rounded-t last:rounded-b focus-within:relative focus-within:z-10 ",
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Item>
  ),
);

const AccordionTrigger = React.forwardRef<HTMLDivElement, PropsWithChildren<Accordion.AccordionTriggerProps>>(
  ({children, className, ...props}, forwardedRef) => {
    const [state, setState] = useState("closed");
    const Icon = state === "open" ? MinusIcon : PlusIcon;
    return (
      <Accordion.Header className="flex">
        <Accordion.Trigger
          onClick={() => {
            setState(state === "open" ? "closed" : "open");
          }}
          className={classNames(
            "bg-blue text-white  p-0 items-center focus:ring-0 focus:!outline-none focus:!shadow-none focus:!border-0 group flex flex-1 cursor-default  justify-between  leading-none text-[26px]",
            className,
          )}
          {...props}
        >
          {children}
          <Icon className="w-4 h-4" aria-hidden />
        </Accordion.Trigger>
      </Accordion.Header>
    );
  },
);

const AccordionContent = React.forwardRef<HTMLDivElement, PropsWithChildren<Accordion.AccordionContentProps>>(
  ({children, className, ...props}, forwardedRef) => (
    <Accordion.Content
      className={classNames(
        "text-mauve11 bg-mauve2 data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[15px]",
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      <div className="py-[15px] px-5">{children}</div>
    </Accordion.Content>
  ),
);

export {AccordionContent, AccordionItem, AccordionTrigger};
