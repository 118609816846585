import moment from "moment";
import { useMemo } from "react";
import DatePicker from "react-datepicker";

import { isValidDate } from "@lib/utils";
import { DateElement, GenericControlController } from "@type/general";
import { useRouter } from "next/router";
import "react-datepicker/dist/react-datepicker.css";

const WebformDatetimePicker = ({value, onChange, name}: GenericControlController<DateElement>) => {
  const safeValue = useMemo(() => {
    if (value) {
      const date = moment(value).toDate();
      if (isValidDate(date)) {
        return date;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [value]);

  const locale = useRouter().locale;
  let placement;
  locale === "ar" ? (placement = "bottom-end") : (placement = "bottom-start");

  /**
   * Handles the change.
   */
  const handleChange = (date: Date | null) => {
    try {
      if (date) {
        onChange(moment(date).format("YYYY-MM-DDTHH:mm:ss"));
      } else {
        onChange(null);
      }
    } catch (e) {
      onChange(null);
    }
  };

  return (
    <DatePicker
      popperPlacement={"bottom-end" || placement}
      showPopperArrow={false}
      name={name}
      showTimeSelect={true}
      dateFormat="yyyy-MM-dd HH:mm:ss"
      selected={safeValue}
      minDate={new Date("1900-01-01")}
      onChange={handleChange}
    />
  );
};

export default WebformDatetimePicker;
