import {Swiper, SwiperProps, SwiperRef, SwiperSlide} from "swiper/react";
import {Navigation, Scrollbar, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import map from "lodash/map";
import {SwiperModule} from "swiper/types";
import CustomArrow from "./customArrows";
import {useEffect, useMemo, useRef} from "react";
import classNames from "classnames";

export interface SliderProps extends Omit<SwiperProps, "loop"> {
  items: JSX.Element[];
  perView?: number;
  space?: number;
  customArrow?: boolean;
  loop: boolean | Record<number, boolean>;
  itemsPerView?: number;
  hideArrowsOnMobile?: boolean;
}
const Slider = ({
  items,
  perView = 1,
  space = 0,
  navigation = false,
  pagination = false,
  scrollbar = false,
  autoplay = true,
  breakpoints,
  customArrow = false,
  loop = false,
  itemsPerView,
  hideArrowsOnMobile = false,
  ...rest
}: SliderProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  const modules: SwiperModule[] = useMemo(() => {
    const modules: SwiperModule[] = [];
    if (navigation) {
      modules.push(Navigation);
    }
    if (pagination) {
      modules.push(Pagination);
    }
    if (scrollbar) {
      modules.push(Scrollbar);
    }
    return modules;
  }, [navigation, pagination, scrollbar]);

  // const handleBreakpointChange = (swiper: SwiperType) => {
  //   // eslint-disable-next-line no-console
  //   console.log(swiper, "test");
  //   if (typeof loop === "boolean") {
  //     return;
  //   }
  //   if (autoplay == true) {
  //     swiper.autoplay.start();
  //   }
  //   if (swiperRef && swiperRef.current) {
  //     swiperRef.current.swiper.update();
  //     swiperRef.current.swiper.init();
  //   }
  //   const currentBreakpoint = swiper.currentBreakpoint as number;
  //   if (
  //     swiper.params.slidesPerView &&
  //     swiper.params.slidesPerView != "auto" &&
  //     swiper.slides.length <= swiper.params.slidesPerView
  //   ) {
  //     setLoopNow(false);
  //   } else {
  //     setLoopNow(loop[currentBreakpoint]);
  //   }
  // };
  // const handleResize = () => {
  //   // eslint-disable-next-line no-console
  //   console.log(swiperRef.current, swiperRef, "test2");
  //   if (swiperRef && swiperRef.current) {
  //     swiperRef.current.swiper.update();
  //     swiperRef.current.swiper.init();
  //     if (swiperRef.current.swiper.allowSlideNext != showArrows) {
  //       setShowArrows(swiperRef.current.swiper.allowSlideNext);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (swiperRef && swiperRef.current) {
  //     swiperRef.current.swiper.update();
  //     swiperRef.current.swiper.init();
  //     if (swiperRef.current.swiper.allowSlideNext != showArrows) {
  //       setShowArrows(swiperRef.current.swiper.allowSlideNext);
  //     }
  //   }

  //   if (swiperRef && swiperRef.current) {
  //     setShowArrows(swiperRef.current.swiper.allowSlideNext);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [swiperRef?.current?.swiper.allowSlideNext]);
  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   console.log(swiperRef.current, swiperRef, "test1");
  //   if (swiperRef && swiperRef.current) {
  //     swiperRef.current.swiper.update();
  //     swiperRef.current.swiper.init();
  //     // eslint-disable-next-line no-console
  //     console.log(swiperRef.current.swiper.currentBreakpoint, "Test4");
  //     // update breakpoint
  //     handleBreakpointChange(swiperRef.current.swiper);

  //     if (swiperRef.current.swiper.allowSlideNext != showArrows) {
  //       setShowArrows(swiperRef.current.swiper.allowSlideNext);
  //     }
  //   }
  //   if (swiperRef && swiperRef.current) {
  //     setShowArrows(swiperRef.current.swiper.allowSlideNext);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // });
  useEffect(() => {
    // if rest.breakpoint is undefined, we don't need to do anything
    if (!breakpoints) {
      return;
    }

    // if the swiperRef is undefined, we don't need to do anything
    if (!swiperRef.current) {
      return;
    }
    if (breakpoints) {
      swiperRef.current.swiper.params.breakpoints = breakpoints;
      swiperRef.current.swiper.getBreakpoint(breakpoints);
    }
  }, [breakpoints]);
  return (
    <Swiper
      ref={swiperRef}
      autoplay={autoplay}
      pagination={pagination}
      a11y={{
        enabled: true,
        prevSlideMessage: "Previous slide",
        nextSlideMessage: "Next slide",
      }}
      updateOnWindowResize
      initialSlide={0}
      scrollbar={scrollbar}
      modules={modules}
      spaceBetween={space}
      loopedSlides={items.length}
      breakpoints={breakpoints}
      onInit={(swiper) => {
        swiper.init();
      }}
      onSwiper={(swiper) => {
        swiper.init();
        swiper.update();
      }}
      slidesPerView={"auto"}
      {...rest}
    >
      {map(items, (item, index) => (
        <SwiperSlide key={index}>{item}</SwiperSlide>
      ))}
      {customArrow && (
        <CustomArrow
          length={items.length}
          swiperRef={swiperRef}
          className={classNames(hideArrowsOnMobile && "block max-mobile:hidden")}
        />
      )}
    </Swiper>
  );
};
export default Slider;
