import classNames from "classnames";
import React from "react";
type Props = {
  variant?: "none" | "medium-both" | "medium-top" | "medium-bottom" | "large-both" | "large-top" | "large-bottom";
  className?: string;
  children: React.ReactNode;
  background?: "white" | "gray";
} & React.HTMLAttributes<HTMLDivElement>;

const Section = ({variant = "medium-both", background = "white", className, children, ...rest}: Props) => {
  switch (variant) {
    case "medium-both":
      className = classNames("padding-medium-both", className);
      break;
    case "medium-top":
      className = classNames("padding-medium-top", className);
      break;
    case "medium-bottom":
      className = classNames("padding-medium-bottom", className);
      break;
    case "large-both":
      className = classNames("padding-large-both", className);
      break;
    case "large-top":
      className = classNames("padding-large-top", className);
      break;
    case "large-bottom":
      className = classNames("padding-large-bottom", className);
      break;
    default:
      break;
  }
  return (
    <section className={classNames(className)} {...rest}>
      {children}
    </section>
  );
};

export default Section;
