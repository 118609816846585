import map from "lodash/map";
import React from "react";
import {FieldError as ReactHookFieldError, Merge} from "react-hook-form";
type Props = {
  error: ReactHookFieldError | Merge<ReactHookFieldError, (ReactHookFieldError | undefined)[]> | undefined;
};

const FieldError = (props: Props) => {
  const {error} = props;
  if (!error) {
    return null;
  }
  if (Array.isArray(error)) {
    return (
      <div className="flex flex-col gap-y-4">
        {map(error, (e, i) => (
          <span className="text-red rfs-16" key={i}>
            {e.message}
          </span>
        ))}
      </div>
    );
  }
  return <span className="text-red rfs-16">{error.message}</span>;
};

export default FieldError;
