import {DrupalMenuLinkContent} from "next-drupal";
import {useRouter} from "next/router";

export const ifMenuItemHasChildren = (menuItem: DrupalMenuLinkContent | any): menuItem is any => {
  return "children" in menuItem && menuItem.children.length > 0;
};

export const getParentItem = (menu: DrupalMenuLinkContent[]) => {
  const parentItems = menu?.length > 0 ? menu.filter((item) => item.parent === "") : [];
  return parentItems;
};
export const getChildItems = (menu: DrupalMenuLinkContent[]) => {
  const childItems = menu?.length > 0 ? menu.filter((item) => item.parent !== "") : [];
  return childItems;
};

export const getMenuItem = (menu: DrupalMenuLinkContent[]) => {
  const parentItems = getParentItem(menu);
  const childItems = getChildItems(menu);
  const menuItems = parentItems.map((item) => {
    return {
      ...item,
      children: childItems
        .filter((child) => child.parent === item.id)
        .map((item) => {
          return {
            ...item,
            children: childItems.filter((child) => child.parent === item.id),
          };
        }),
    };
  });
  return menuItems;
};

export const isActive = (item: DrupalMenuLinkContent) => {
  const router = useRouter();
  const link = item.url.replace("/" + router.locale, "");
  if (link != "" && link == router.asPath) {
    return true;
  } else {
    if (link == "" && router.asPath == "/") {
      return true;
    }
  }
  return false;
};
