import {t} from "@lib/translations-provider";
import Zod from "zod";

export const NewsletterFormSchema = Zod.object({
  newsletter_mail: Zod.string({
    required_error: t("{{field}} is required", {
      field: t("Email"),
    }),
    invalid_type_error: t("{{field}} is invalid", {
      field: t("Email"),
    }),
  }).email(
    t("{{field}} is invalid", {
      field: t("Email"),
    }),
  ),
});
