import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {GeneralIcon} from "@type/general";
import classNames from "classnames";
import React, {HTMLAttributes} from "react";

type Props = {
  icon: GeneralIcon;
  className?: string;
} & (FontAwesomeIconProps | HTMLAttributes<SVGElement>);

const Icon = ({icon: Icon, className = "w-[25px] h-[25px]", ...rest}: Props) => {
  if (typeof Icon === "function") {
    return <Icon className={classNames(className)} {...rest} />;
  } else {
    return <FontAwesomeIcon className={classNames(className)} {...rest} icon={Icon} />;
  }
};

export default Icon;
