import React from "react";
import {DetailsWebformElement, WebformContainerComponent} from "@type/general";
import {AccordionContent, AccordionItem, AccordionTrigger} from "@components/general/Accordion";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";

const Details = ({webformElement, children, className}: WebformContainerComponent<DetailsWebformElement>) => {
  return (
    <Accordion.Root key="accordion-list-1" type="single" collapsible>
      <AccordionItem value="2" className={classNames(className, "p-6 rounded-lg border border-light-border")}>
        <AccordionTrigger
          className="bg-transparent !text-blue rfs-28"
          iconClassName="flex items-center justify-center [&>svg]:w-6 [&>svg]:h-6 w-10 h-10 p-2 bg-primary rounded-full"
        >
          <h2>
            {webformElement["#title"]}
            {webformElement["#required"] ? " *" : ""}
          </h2>
        </AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </Accordion.Root>
  );
};

export default Details;
