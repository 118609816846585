import {isCustomBlock, isPluginBlock, isViewBlock, isWebformBlock} from "@lib/layout-builder";
import React from "react";
import {LayoutComponent} from "@type/layout";
import BlockComponent from "./blocks/CustomBlock";
import WebformBlock from "./blocks/WebformBlock";
import ViewBlockComponent from "./views/ViewBlockComponent";
import PluginBlock from "@components/hoc/LayoutBuilder/components/plugin-block";
type Props = {
  component: LayoutComponent;
};

const Component = ({component}: Props) => {
  let render: JSX.Element | string | null = null;
  if (component.error) {
    render = (
      <div className="text-danger">
        Error in &apos;{component.configuration.id}&apos;: {component.error}
      </div>
    );
  } else if (isViewBlock(component)) {
    render = (
      <ViewBlockComponent
        component={component}
        label={component.props?.meta?.title}
        displayLabel={component.configuration.label_display}
      />
    );
  } else if (isCustomBlock(component)) {
    render = (
      <BlockComponent
        component={component}
        label={component.configuration?.label}
        displayLabel={component.configuration.label_display}
      />
    );
  } else if (isWebformBlock(component)) {
    render = <WebformBlock component={component} />;
  } else if (isPluginBlock(component)) {
    render = (
      <PluginBlock
        component={component}
        label={component.configuration?.label}
        displayLabel={component.configuration.label_display}
      />
    );
  } else {
    render = `No component found for block "${component.configuration.id}"`;
  }

  return <div className="layout-builder-block">{render}</div>;
};

export default Component;
