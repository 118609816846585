import {GeneralIcon} from "@type/general";
import React from "react";
import Icon from "../Icon";
import classNames from "classnames";

export type LandingFieldValueProps = {
  label: string;
  children: any;
  icon?: GeneralIcon;
  iconClassName?: string;
  dependency?: unknown;
  textColor?: "gray" | "green" | "blue";
} & React.HTMLAttributes<HTMLDivElement>;

const LandingFieldValue = ({
  label,
  textColor = "gray",
  children,
  icon,
  iconClassName,
  className,
  dependency,
  ...rest
}: LandingFieldValueProps) => {
  // Do not render if the dependency is empty.
  if (
    typeof dependency !== "undefined" &&
    ((dependency instanceof Array && dependency.length === 0) || dependency === "" || dependency === null)
  ) {
    return null;
  }

  if (!icon) {
    return (
      <div className={classNames(className, "")} {...rest}>
        <label>{label}</label>
        <div>{children}</div>
      </div>
    );
  } else {
    return (
      <div className="landing-field ">
        <div className={classNames("flex px-6 py-3 flex-wrap", className)} {...rest}>
          <div className="items-center flex basis-full">
            <Icon className={classNames("w-7 me-3 h-7", iconClassName)} icon={icon} />
            <label className="text-[#616E7C] rfs-24 font-medium">{label}</label>
          </div>
          <div>
            <div className={classNames("text-blue rfs-26 font-bold ps-10")}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default LandingFieldValue;
