import FullCalendar from "@fullcalendar/react";
import moment, {Moment} from "moment";
import React, {RefObject, useEffect, Dispatch, SetStateAction, forwardRef} from "react";
import DatePicker from "react-datepicker";
import {Button, Icon} from "@components/elements";
import cheveronsRight from "@icons/chevron-right.svg";
import cheveronsLeft from "@icons/chevron-left.svg";
import CalendarIcon from "@icons/Calendar.svg";
import classNames from "classnames";
import {getLangcode} from "@lib/translations-provider";

export type CalendarHeaderProps = {
  calendarRef: RefObject<FullCalendar>;
  handleEventView?: Dispatch<SetStateAction<"left-teaser" | "calendar">>;
  eventView?: "left-teaser" | "calendar";
  setDate: Dispatch<SetStateAction<Moment | null>>;
  date: Moment | null;
};

const CalendarHeader = forwardRef<HTMLDivElement, CalendarHeaderProps>(({calendarRef, setDate, date}, ref) => {
  useEffect(() => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      setDate(moment(calApi.getDate()));
    }
  }, []);

  const handleDateChange = (direction: "prev" | "today" | "next"): void => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      if (direction === "prev") {
        calApi.prev();
      } else if (direction === "next") {
        calApi.next();
      } else {
        calApi.today();
      }
      setDate(moment(calApi.getDate()));
    }
  };

  const handleDateChangeForDatePicker = (date: Date): void => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      calApi.gotoDate(date);
      setDate(moment(calApi.getDate()));
    }
  };
  const CalendarHeading = ({onClick}: {onClick?: () => void}) => {
    return (
      <div className="flex items-center gap-3" onClick={onClick}>
        <span className="text-[#585757] font-bold text-4xl">{date?.format("MMMM YYYY")}</span>
        <Icon icon={CalendarIcon} className="w-[24px] h-[24px]" />
      </div>
    );
  };
  return (
    <div className={classNames("flex items-center gap-5 mb-8 justify-end")} dir="rtl">
      <Button aria-label="Next button" onClick={() => handleDateChange("next")} variant="minimal">
        <Icon className={classNames("w-[24px] h-[24px]", "stroke-secondary cursor-pointer")} icon={cheveronsRight} />
      </Button>
      <DatePicker
        showMonthYearPicker
        locale={getLangcode()}
        selected={date?.toDate()}
        customInput={<CalendarHeading />}
        onChange={handleDateChangeForDatePicker}
      />
      <Button aria-label="prev button" variant="minimal" onClick={() => handleDateChange("prev")}>
        <Icon className={classNames("w-[24px] h-[24px]", "stroke-secondary cursor-pointer")} icon={cheveronsLeft} />
      </Button>
    </div>
  );
});

export default CalendarHeader;
