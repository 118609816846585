import {MenuParentProps} from "@type/general";
import classNames from "classnames";
import {DrupalMenuLinkContent} from "next-drupal";
import {ForwardedRef, forwardRef, useEffect, useState} from "react";
import React from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import {CaretDownIcon} from "@radix-ui/react-icons";
import map from "lodash/map";
import Link from "next/link";
import {getMenuItem, isActive} from "@lib/menu-utils";
import {useLayout, useMediaQuery} from "@lib/hooks";
import {useRouter} from "next/router";
import some from "lodash/some";

interface MenuProps {
  menuContent: DrupalMenuLinkContent[];
  menuToTop: boolean;
  hidemenu: boolean;
}
type DesktopMenuProps = MenuProps;

const ListItem = React.forwardRef<
  HTMLAnchorElement,
  {
    className?: string;
    menuLink: DrupalMenuLinkContent;
    menuToTop: boolean;
    isHomepage: boolean;
    hidemenu: boolean;
  }
>(({className, menuLink, menuToTop, hidemenu, isHomepage, ...props}, forwardedRef) => {
  return (
    <Link
      prefetch={false}
      aria-label={menuLink.title}
      href={menuLink.url}
      className={classNames(
        "font-scale-22  py-[7px] 2desktop:px-[20px] px-2 max-desktop:px-2  rounded-full group flex select-none items-center justify-between gap-2 text-[22px] font-medium outline-none focus:shadow-none focus:border-0",
        "text-white",
        "transition-all",
        className,
      )}
      passHref
      {...props}
      ref={forwardedRef}
    >
      {menuLink.title}
    </Link>
  );
});
type ListDropdownProps = {
  className?: string;
  menuLink: MenuParentProps;
  setMenuToTop?: (value: boolean) => void;
  handleScroll?: (e: Event) => void;
  menuToTop: boolean;
  isHomepage: boolean;
  hidemenu: boolean;
};

const ListDropdown = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ListDropdownProps>(
  (
    {className, menuLink, isHomepage, hidemenu, menuToTop = false, setMenuToTop, handleScroll, ...props},
    forwardedRef,
  ) => {
    const isParentMenuActive = some(menuLink.children, (child) => isActive(child));

    return (
      <>
        <NavigationMenu.Trigger onScroll={(e) => handleScroll && handleScroll(e as unknown as Event)} asChild>
          <div
            className={classNames(
              " py-2 2desktop:px-5 px-2 rounded-full max-laptop:px-[10px] max-desktop group flex select-none items-center justify-between gap-2 outline-none focus:shadow-none focus:border-0",
              isParentMenuActive && "bg-primary-darker",

              "transition-all",
            )}
            aria-label={menuLink.title}
          >
            <Link
              prefetch={false}
              aria-label={menuLink.title}
              ref={forwardedRef as ForwardedRef<HTMLAnchorElement>}
              href={menuLink.url}
              className={classNames(
                "text-white",
                "leading-normal font-scale-22 font-medium ",
                isParentMenuActive && "text-primary-darker",
              )}
            >
              {menuLink.title}
            </Link>

            <CaretDownIcon
              className={classNames(
                "text-primary top-[1px] transition-transform duration-200 ease-in group-data-[state=open]:-rotate-180 [&>*]:fill-white",
                isParentMenuActive && "[&>*]:fill-primary-darker",
              )}
              aria-hidden
            />
          </div>
        </NavigationMenu.Trigger>
        <NavigationMenu.Content
          className={classNames(
            "z-10 data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight  data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute ",
            hidemenu || (isHomepage && menuToTop) ? "top-[73px]" : "top-[81px]",
          )}
        >
          <ul className="one m-0 list-none  bg-white min-w-[320px] shadow-md [&>li:not(:last-child)]:border-b-[1px] [&>li:not(:last-child)]:border-[#ECE7E3] ">
            {map(menuLink.children, (childLink) => {
              const isActiveMenu = isActive(childLink);
              return (
                <li key={childLink.id} className="relative px-5 py-[9.5px]">
                  <NavigationMenu.Link asChild>
                    <Link
                      prefetch={false}
                      aria-label={menuLink.title}
                      href={childLink.url}
                      className={classNames(
                        "text-[24px] leading-normal font-scale-22 block select-none text-primary",
                        isActiveMenu && "text-primary-darker",

                        className,
                      )}
                      {...props}
                    >
                      {childLink.title}
                    </Link>
                  </NavigationMenu.Link>
                </li>
              );
            })}
          </ul>
        </NavigationMenu.Content>
      </>
    );
  },
);

export const DesktopMenu = forwardRef<HTMLElement, DesktopMenuProps>(({menuContent, menuToTop, hidemenu}, ref) => {
  const {resource} = useLayout();
  const router = useRouter();
  const menuItems = getMenuItem(menuContent);
  const isHomepage = (resource.canonical as string).includes("/node/1");
  const menuClasses = () => {
    let className = "opacity-100 transition-opacity duration-300 w-full";
    if (!isHomepage) {
      if (menuToTop) {
        className = "opacity-100";
      }
    } else {
      className = "opacity-0 -translate-y-[100%]";
    }
    return className;
  };
  return (
    <NavigationMenu.Root
      ref={ref}
      dir={router.locale === "ar" ? "rtl" : "ltr"}
      className={classNames("desktop-menu flex w-full justify-center z-[100] transition-all", menuClasses())}
    >
      <div className={classNames(menuToTop && "container flex [&>div]:w-full items-end")}>
        <NavigationMenu.List
          className={classNames(
            "center container bg-primary w-full gap-2 m-0 flex list-none justify-center px-[20px] transition-all py-[16px]",
          )}
        >
          {map(menuItems, (menuLink: MenuParentProps, index) => {
            return (
              <NavigationMenu.Item value={menuLink.id} key={menuLink.id}>
                {menuLink.children && menuLink.children.length > 0 ? (
                  <ListDropdown menuLink={menuLink} menuToTop={menuToTop} isHomepage={isHomepage} hidemenu={hidemenu} />
                ) : (
                  <ListItem menuToTop={menuToTop} menuLink={menuLink} isHomepage={isHomepage} hidemenu={hidemenu} />
                )}
              </NavigationMenu.Item>
            );
          })}
        </NavigationMenu.List>
      </div>
    </NavigationMenu.Root>
  );
});

export const Menu = forwardRef<HTMLElement, MenuProps>(({menuContent, menuToTop, hidemenu}, ref) => {
  const [showDesktopMenu, setShowDesktopMenu] = useState(false);
  const isMobile = useMediaQuery(1200);
  useEffect(() => {
    if (showDesktopMenu == false && !isMobile) {
      setShowDesktopMenu(true);
    } else {
      if (showDesktopMenu == true && isMobile) {
        setShowDesktopMenu(false);
      }
    }
  }, [isMobile]);
  return !isMobile && showDesktopMenu ? (
    <DesktopMenu ref={ref} menuContent={menuContent} menuToTop={menuToTop} hidemenu={hidemenu} />
  ) : null;
});
