import classNames from "classnames";
import moment from "moment";
import React from "react";
import {useRouter} from "next/router";
import momentHijri from "moment-hijri";

type Props = {
  date: string;
  format: string;
  hijri?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const DateFormat = ({date, format, className, hijri = false, ...rest}: Props) => {
  const router = useRouter();

  let formater;
  if (hijri) {
    formater = momentHijri(date)
      .locale(router.locale === "ar" ? "ar-SA" : "en")
      .format(format);
  } else {
    formater = moment(date)
      .locale(router.locale === "ar" ? "ar-SA" : "en")
      .format(format);
  }

  return (
    <span className={classNames("h-full flex items-center rfs-26 font-bold text-blue", className)} {...rest}>
      {formater}
    </span>
  );
};

export default DateFormat;
