import {Field} from "@components/general";
import {cleanUpComplexWebform, isComplexWebform} from "@lib/utils";
import {
  FieldGroupProps,
  WebformContextContextProps,
  WebformDefaultComponents,
  WebformProps as GeneralWebformProps,
} from "@type/general";
import React from "react";
import {ComplexWebform} from "./components/ComplexWebform";
import {SimpleWebform} from "./components/SimpleWebform";

export const WebformContext = React.createContext<WebformContextContextProps>({});

type WebformProps = GeneralWebformProps & {
  fieldGroupComponent?: React.FC<FieldGroupProps>;
  components?: WebformDefaultComponents;
};

/**
 * The main webform.
 */
const Webform = ({webform, fieldGroupComponent = Field, components = {}}: WebformProps) => {
  return (
    <WebformContext.Provider
      value={{
        fieldGroupComponent,
        components,
      }}
    >
      {isComplexWebform(webform) ? (
        <ComplexWebform webform={cleanUpComplexWebform(webform)} />
      ) : (
        <SimpleWebform webform={webform} />
      )}
    </WebformContext.Provider>
  );
};

export default Webform;
