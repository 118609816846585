import {useRouter} from "next/router";
import React from "react";
import PhoneInput, {PhoneInputProps} from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import "react-phone-input-2/lib/style.css";
const TelephoneElement = ({country = "sa", ...rest}: PhoneInputProps) => {
  // get current language
  const router = useRouter();
  const {locale} = router;

  return (
    <PhoneInput
      inputProps={{dir: "ltr"}}
      localization={locale == "ar" ? ar : undefined}
      placeholder=""
      inputClass={'!w-full !rounded-2 !border !border-color-gray rfs-16 !h-[50px] !text-start !pe-[75px] !m-auto'}
      country={country}
      {...rest}
      excludeCountries={["il"]}
    />
  );
};

export default TelephoneElement;
