import forEach from "lodash/forEach";
import arJson from "@locales/ar/common.json";
import enJson from "@locales/en/common.json";

type CurrentLanguage = {
  langcode: string;
  dir: "rtl" | "ltr";
};

export const currentLanguage: CurrentLanguage = {
  langcode: "ar",
  dir: "rtl",
};
export const getLangcode = (): string => {
  if (typeof window === "undefined") {
    return currentLanguage.langcode;
  } else return document.getElementsByTagName("html")[0]?.getAttribute("lang") || "ar";
};

export const t = (text: string, params?: Record<string, string | number>) => {
  const lang = getLangcode();
  const json = (lang === "ar" ? arJson : enJson) as Record<string, string | undefined>;
  let translated = json[text] || text;
  if (params) {
    forEach(params, (value, key) => {
      translated = translated.replace(`{{${key}}}`, value.toString());
    });
  }

  return translated;
};
export const getDirection = (): string => {
  if (typeof window === "undefined") {
    return currentLanguage.dir;
  } else return document.getElementsByTagName("html")[0]?.getAttribute("dir") || "ltr";
};

/**
 * Get translation with language
 * @param text text to get ita key
 * @param lang language you are sending
 * @returns get translation key return text if not found
 */
export const getTranslationKey = (text: string, lang: string) => {
  const json = (lang === "ar" ? arJson : enJson) as Record<string, string | undefined>;
  const item = Object.keys(json).find((key) => json[key] === text);
  return item || text;
};
