import {Button} from "@components/elements";
import Field from "@components/general/Field";
import {validateEmail} from "@lib/forms";
import {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {t} from "@lib/translations-provider";
import {CommentRequestBody} from "../..";
import classNames from "classnames";
import { useRouter } from "next/router";

type Props = {
  onSubmit: (values: CommentRequestBody) => Promise<false | CommentRequestBody>;
};

const CommentsForm = ({onSubmit}: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<CommentRequestBody>();
  const [loading, setLoading] = useState(false);

  /**
   * Act before submitting the form to the parent.
   */
  const beforeSubmit = async (values: CommentRequestBody) => {
    setLoading(true);
    const res = await onSubmit(values);
    if (res) {
      reset();
    }
    setLoading(false);
  };

  // Reset the form when the route changes.
  const router = useRouter();
  useEffect(() => {
    return () => {
      reset();
    };
  }, [router]);
  /**
   * Calls useForm's register with required = true.
   */
  const registerAsRequired = (name: keyof CommentRequestBody, options: Parameters<typeof register>[1] = {}) => {
    return register(name, {required: true, ...options});
  };

  return (
    <Form className={"flex flex-wrap comments-block"} onSubmit={handleSubmit(beforeSubmit)}>
      <Field
        error={errors.name}
        className={"flex-[50%] pe-4 max-tablet:pe-0 max-tablet:flex-[100%]"}
        label={t("Full name")}
      >
        <Form.Control
          maxLength={255}
          {...registerAsRequired("name", {})}
          type="text"
          placeholder={t("Enter your name here")}
        />
      </Field>
      <Field
        error={errors.email}
        className={classNames("flex-[50%]  ps-4 max-tablet:ps-0 max-tablet:flex-[100%]")}
        label={t("Email")}
      >
        <Form.Control
          maxLength={255}
          {...registerAsRequired("email", {
            validate: (v) => validateEmail(v, t),
          })}
          type="text"
          placeholder={t("Enter your email here")}
        />
      </Field>
      <Field error={errors.body} label={t("Comment")} className={classNames("flex-[100%] ")}>
        <Form.Control {...registerAsRequired("body")} as="textarea" rows={5} placeholder={t("Comment")} />
      </Field>
      <div className={" flex flex-[100%] "}>
        <Button aria-label="add comment" className="ms-auto" disabled={loading}>
          {t("Add Comment")}
        </Button>
      </div>
    </Form>
  );
};

export default CommentsForm;
