import classNames from "classnames";
import moment from "moment";
import momentHijri from "moment-hijri";
import {useRouter} from "next/router";
import React, {useEffect, useState} from "react";
import {Icon} from "@components/elements";
import {GeneralIcon} from "@type/general";
import {t} from "@lib/translations-provider";

type Props = {
  date: string | string[];
  variant: "created" | "time-ago" | "changed" | "landing" | "date-range" | "short-date-range";
  icon?: GeneralIcon;
} & React.HTMLAttributes<HTMLSpanElement>;
const Date = ({children, date: dateValue, variant, icon, className, ...rest}: Props) => {
  const router = useRouter();
  let text: string;
  let element: JSX.Element | null = null;
  const [dateText, setDateText] = useState<string>("");
  const hijriLocale = router.locale === "ar" ? "ar-SA" : "en";
  switch (variant) {
    case "created":
      const gregorian = moment(dateValue)
        .locale(router.locale === "ar" ? "ar-SA" : "en")
        .format("DD MMMM YYYY");

      const hijri = momentHijri(dateValue).locale(hijriLocale).format("iDD iMMMM iYYYY");
      text = `${gregorian} - ${hijri}`;
      break;
    case "time-ago":
      text = moment(dateValue)
        .locale(router.locale ?? "ar")
        .fromNow();
      break;
    case "changed":
      text = t("Latest update: {{hijri}} - {{gregorian}}", {
        gregorian: moment(dateValue)
          .locale(router.locale ?? "ar")
          .format("DD MMMM YYYY"),
        hijri: momentHijri(dateValue).locale(hijriLocale).format("iDD iMMMM iYYYY"),
      });
      break;
    case "landing":
      return (
        <span {...rest}>
          {icon && <Icon icon={icon} />}
          {moment(dateValue)
            .locale(router.locale ?? "ar")
            .format("DD MMMM YYYY")}{" "}
          - {momentHijri(dateValue).locale(hijriLocale).format("iDD iMMMM iYYYY")}
        </span>
      );
    case "date-range":
      if (dateValue instanceof Array) {
        const firstDate = moment(dateValue[0]).locale(router.locale ?? "ar");
        const firstDateH = momentHijri(dateValue[0]).locale(hijriLocale);
        const secondDate = moment(dateValue[1]).locale(router.locale ?? "ar");
        const secondDateH = momentHijri(dateValue[1]).locale(hijriLocale);
        if (moment(dateValue[0]).locale("en").format("MM") == moment(dateValue[1]).locale("en").format("MM")) {
          text = `${firstDate.format("DD")} -  ${secondDate.format("DD MMMM YYYY")} \n 
          - ${firstDateH.format("iDD")} - ${secondDateH.format("iDD iMMMM iYYYY")}`;
          element = (
            <span>
              {firstDate.format("DD")} - {secondDate.format("DD MMMM YYYY")} <br />
              {firstDateH.format("iDD")} - {secondDateH.format("iDD iMMMM iYYYY")}
            </span>
          ) as JSX.Element;
        } else {
          text = `${firstDate.format("DD MMMM")} - ${secondDate.format("DD MMMM YYYY")}\n - ${firstDateH.format(
            "iDD iMMMM",
          )} - ${secondDateH.format("iDD iMMMM iYYYY")}`;
          element = (
            <span>
              {firstDate.format("DD MMMM")} - {secondDate.format("DD MMMM YYYY")} <br />
              {firstDateH.format("iDD iMMMM")} - {secondDateH.format("iDD iMMMM iYYYY")}
            </span>
          ) as JSX.Element;
        }
      } else {
        throw new Error("You can't use date-range value if the value not array");
      }
    case "short-date-range":
      if (dateValue instanceof Array) {
        const firstDate = moment(dateValue[0]);
        const secondDate = moment(dateValue[1]);
        if (moment(dateValue[0]).locale("en").format("MM") == moment(dateValue[1]).locale("en").format("MM")) {
          text = firstDate.format("DD") + " - " + secondDate.format("DD MMMM YYYY");
        } else {
          text = firstDate.format("DD MMMM YYYY") + " - " + secondDate.format("DD MMMM YYYY");
        }
      } else {
        throw new Error("You can't use date-range value if the value not array");
      }
  }
  useEffect(() => {
    setDateText(text);
  }, [text]);
  return (
    <span className={classNames("h-full flex items-center rfs-22 font-normal", className)} {...rest}>
      {icon && <Icon className={"w-[18px] h-[18px] me-[8px]"} icon={icon} />}
      <time suppressHydrationWarning dateTime={dateText}>
        {element != null ? element : dateText}
      </time>
    </span>
  );
};

export default Date;
