import {isDrupalTerm, isTermWithColor} from "@lib/utils";
import {DrupalTermWithColor} from "@type/entity";
import {TagValue} from "@type/general";
import classNames from "classnames";
import map from "lodash/map";
import {DrupalTaxonomyTerm} from "next-drupal";
import React from "react";
import classes from "./Tag.module.scss";
import Link from "next/dist/client/link";

type TagsProps = {
  items: DrupalTaxonomyTerm | (DrupalTaxonomyTerm | DrupalTermWithColor | TagValue)[];
  tagClassName?: string;
  color?: string;
  backgroundColor?: string;
  useBackendColors?: boolean;
  clickable?: boolean;
  variant?: "normal";
} & React.HTMLAttributes<HTMLDivElement>;

const Tags = ({
  children,
  items,
  color = "#14415A",
  backgroundColor,
  useBackendColors = true,
  tagClassName,
  clickable,
  className,
  variant = "normal",
  ...rest
}: TagsProps) => {
  if (!items) {
    return null;
  }
  if (!(items instanceof Array)) {
    items = [items];
  }

  if (items.length === 0) {
    return null;
  }

  const uniqueIds: string[] = [];
  const uniqueItems = items.filter((item) => {
    if (uniqueIds.includes(item.id)) {
      return false;
    }
    uniqueIds.push(item.id);
    return true;
  });

  let isBackgroundColor = false;
  {
    isBackgroundColor = backgroundColor ? true : false;
  }
  return (
    <div className={classNames(className, classes[variant])} {...rest}>
      {map(uniqueItems, (item) => {
        color = "#14415A";
        if (isTermWithColor(item) && useBackendColors) {
          color = item.field_color.color;
        }
        {
          backgroundColor = isBackgroundColor ? backgroundColor : color + "15";
        }
        return (
          <Tag
            color={color}
            backgroundColor={backgroundColor}
            className={classNames(classes.marginRight, tagClassName)}
            key={isDrupalTerm(item) ? item.drupal_internal__tid : item.id}
            link={clickable && isDrupalTerm(item) ? item.canonical : null}
          >
            {/* @ts-ignore */}
            {isDrupalTerm(item) ? (item.name ? item.name : null) : item.value}
          </Tag>
        );
      })}
    </div>
  );
};

type TagProps = {
  color?: string;
  backgroundColor?: string;
  link?: string | null;
} & React.HTMLAttributes<HTMLSpanElement>;

export const Tag = ({color, backgroundColor, className, children, link, ...rest}: TagProps) => {
  const styles = {
    color,
    backgroundColor,
  };

  if (!children) {
    return null;
  }

  if (link) {
    return (
      <Link href={link} style={styles} className={classNames(classes.tag, className)} {...rest}>
        {children}
      </Link>
    );
  } else {
    return (
      <span {...rest} style={styles} className={classNames(classes.tag, className)}>
        {children}
      </span>
    );
  }
};

export default Tags;
