import map from "lodash/map";
import React from "react";
import Section from "./components/Section";
import {EntityWithLayout} from "@type/entity";

type Props = {
  resource: EntityWithLayout;
};

const LayoutBuilder = ({resource}: Props) => {
  const sections = resource.layout_builder_serialized.sections;
  return (
    <div className="drupal-layout-builder">
      {map(sections, (section, index) => {
        return <Section section={section} key={index} />;
      })}
    </div>
  );
};

export default LayoutBuilder;
