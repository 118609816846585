/* eslint-disable no-console */
import EntityRenderer from "@components/hoc/EntityRenderer";
import map from "lodash/map";
import {ViewPartComponentProps} from "@type/layout";
import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Autoplay, Pagination, Navigation} from "swiper";
import CustomArrow from "@components/general/Slider/customArrows";
import classNames from "classnames";
import {useRef} from "react";
import {useMediaQuery} from "@lib/hooks";
const SliderList = ({resources, viewMode, swiperSettings = {}, meta}: ViewPartComponentProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  const isMobile = useMediaQuery(1024);
  let disableSwipe = false;
  if (swiperSettings.itemsPerView) {
    delete swiperSettings.itemsPerView;
  }
  if (!isMobile) {
    disableSwipe = resources.length < parseInt(swiperSettings.slidesPerView);
  } else {
    disableSwipe = false;
  }
  console.log(swiperSettings, "swiperSettings sliderList");
  return (
    <>
      <Swiper
        ref={swiperRef}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay, Pagination, Navigation]}
        navigation={true}
        pagination={{clickable: true}}
        allowSlideNext={!disableSwipe}
        allowSlidePrev={!disableSwipe}
        className={classNames(disableSwipe && "disable-swipe")}
        {...swiperSettings}
      >
        {map(resources, (resource) => {
          return (
            <SwiperSlide key={resource.id}>
              <EntityRenderer resource={resource} viewMode={viewMode} />
            </SwiperSlide>
          );
        })}
        {!isMobile && swiperSettings.customArrow && <CustomArrow swiperRef={swiperRef} length={resources.length} />}
      </Swiper>
      {isMobile && swiperSettings.customArrow && (
        <CustomArrow
          className="static justify-center flex items-center mt-4"
          swiperRef={swiperRef}
          length={resources.length}
        />
      )}
    </>
  );
};
export default SliderList;
