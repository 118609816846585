import {Button} from "@components/elements";
import {ViewPartComponentProps} from "@type/layout";
import {t} from "@lib/translations-provider";
import classes from "./Loadmore.module.scss";

const Loadmore = ({fetchData, pagination, setPage, status}: ViewPartComponentProps) => {
  const showLoadmore = pagination.currentPage + 1 < pagination.totalPages;
  return showLoadmore ? (
    <div className={classes.loadmoreWrapper}>
      <Button
        aria-label="Load more"
        disabled={status === "loading"}
        onClick={() => {
          setPage(pagination.currentPage + 1);
          fetchData(true);
        }}
      >
        {t("Load more")}
      </Button>
    </div>
  ) : null;
};

export default Loadmore;
