import {Field} from "@components/general";
import Recaptcha from "@components/general/Recaptcha";
import {getAxiosClient} from "@lib/api";
import {useWebform} from "@lib/hooks";
import {handleError, successMessage} from "@lib/utils";
import {
  applyStatesAndConditions,
  flattenElements,
  foreachElements,
  hasAccessToElement,
  normalizeValues,
} from "@lib/webform";
import {WebformProps, WebformValues} from "@type/general";
import classNames from "classnames";
import omitBy from "lodash/omitBy";
import {useRouter} from "next/router";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {t} from "@lib/translations-provider";
import Style from "../Webform.module.scss";
import * as Form from "@radix-ui/react-form";
import {AxiosError} from "axios";
import {map} from "lodash";

/**
 * Simple webforms.
 */
export const SimpleWebform = ({webform}: WebformProps) => {
  const form = useForm<WebformValues>();
  const router = useRouter();
  const {components} = useWebform();
  const flattenedElements = flattenElements(webform.elements);
  const submitWebform = async (data: WebformValues) => {
    form.resetField("captcha");
    try {
      // Before posting, clean up the data, remove any disabled or hidden elements.
      data = omitBy(data, (value, name) => {
        if (name !== "captcha") {
          return flattenedElements[name]["#disabled"] || !hasAccessToElement(flattenedElements[name]);
        }
      });
      data = await normalizeValues(data, flattenedElements, components);
      const axiosClient = getAxiosClient();
      await axiosClient.post(`/api/webform/${webform.id}`, data);
      router.push("/");
      successMessage(t("Your submission has been sent."));
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 400) {
          const body = JSON.parse(e.response.data.message) as {
            error: Record<string, string>;
            message: string;
          };
          map(body.error, (error, name) => {
            form.setError(name, {message: error});
          });
        } else {
          handleError(e);
        }
      } else {
        handleError(e);
      }
    }
  };
  const onSubmit = async (data: WebformValues) => {
    await submitWebform(data);
  };

  // Apply states twice. Do it 2 times because some states depend on other states. Also
  // This will solve the problem of states depending on the order of the elements.
  // TODO: This might not be the best solution.
  applyStatesAndConditions(webform.elements, flattenedElements, form.watch);
  applyStatesAndConditions(webform.elements, flattenedElements, form.watch);
  return (
    <Form.Root
      onSubmit={form.handleSubmit(onSubmit)}
      className={classNames("drupal-webform", `webform--${webform.id}`, Style.drupalWebform)}
    >
      <FormProvider {...form}>{foreachElements(webform.elements)}</FormProvider>
      <Field error={form.formState.errors.captcha}>
        <Controller
          name="captcha"
          control={form.control}
          rules={{required: true}}
          render={({field: {onChange, value}}) => (
            <Recaptcha value={value as string | null} onChange={onChange} className={Style.Recaptcha} />
          )}
        />
      </Field>
    </Form.Root>
  );
};
