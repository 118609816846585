/**
 * @file Defines React hooks.
 */

import {WebformContext} from "@components/hoc/Webform";
import {
  ExposedFitlerValue,
  FormattedFacet,
  FormattedFacetValue,
  getViewExtended,
  PaginationConfig,
  SlugProps,
  SolrPaginationConfig,
  SolrResponse,
  StatusString,
  useExposedFiltersReturn,
  UseGetConfig,
  UseGetReturn,
  UseSessionStorageParams,
  UseSolrReturn,
  UseViewConfig,
  UseViewReturn,
  ViewSortByParam,
  WebformHookReturn,
} from "@type/general";
import {clone, cloneDeep, find, forEach, isObject, map, mapValues, sortBy, uniqBy} from "lodash";
import md5 from "md5";
import {JsonApiResource} from "next-drupal";
import {useRouter} from "next/router";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {getAxiosClient} from "./api";
import {ExposedFiltersContext} from "@components/general/ExposedFilters";
import {isCompoundValue, isHierarchyRequest} from "./utils";
import {ViewPartComponentProps} from "@type/layout";
import {RatingContextProvider} from "@components/layout/components";
export const useGet = <T>({url, automatic = true, params, defaultValue = null}: UseGetConfig<T>): UseGetReturn<T> => {
  const oldControlRef = useRef(0);
  const [control, setControl] = useState<number>(0);
  const [data, setData] = useState<T | null>(defaultValue);
  const [status, setStatus] = useState<StatusString>(automatic ? "loading" : "ready");
  const paramsKeys = useMemo(() => {
    return md5(JSON.stringify(params ?? {}));
  }, [params]);

  const doFetchData = async () => {
    try {
      setStatus("loading");
      const res = await getAxiosClient().get<T>(url, {params});
      setData(res.data);
      setStatus("success");
    } catch (e) {
      setStatus("failed");
    }
  };

  const fetchData = () => {
    setControl(control + 1);
  };

  useEffect(() => {
    if (automatic || control !== oldControlRef.current) {
      oldControlRef.current = control;
      doFetchData();
    }
  }, [automatic, control, url, paramsKeys]);

  useEffect(() => {
    setData(defaultValue);
  }, [defaultValue]);

  return {
    data,
    setData,
    status,
    fetchData,
  };
};

/**
 * It's a hook that fetches data from a Drupal View and returns the data, the status of the request,
 * and a pagination object
 * @param {UseViewConfig}  - viewId - The view id
 * @return {UseViewReturn}
 */
export const useView = ({
  viewId,
  displayId,
  defaultPage = 0,
  viewArguements,
  defaultfilters = {},
  layoutExposedFilters = {},
  include,
  ...rest
}: UseViewConfig): UseViewReturn => {
  const oldData = useRef<getViewExtended>();
  const [actualData, setActualData] = useState<getViewExtended>();
  const [data, setData] = useState<JsonApiResource[]>([]);
  const [page, setPage] = useState<number>(defaultPage);
  const [exposedFilters, setExposedFilters] = useState<Record<string, string>>(defaultfilters);
  const [args, setArgs] = useState<string[] | undefined>(viewArguements as string[] | undefined);
  const [sortBy, setSortBy] = useState<ViewSortByParam | null>();
  const keepPreviousPageRef = useRef<boolean>(false);
  const [pagination, setPagination] = useState<PaginationConfig>({
    currentPage: defaultPage,
    fullCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
  });
  const router = useRouter();
  const exposedFiltersQuery = useMemo(() => {
    const newExposedFilters: Record<string, string> = {};
    forEach(exposedFilters, (value, key) => {
      newExposedFilters[`views-filter[${key}]`] = value;
    });
    return newExposedFilters;
  }, [exposedFilters]);

  const layoutBuilderFiltersQuery = useMemo(() => {
    const newlayOutFilters: Record<string, string> = {};
    forEach(layoutExposedFilters, (value, key) => {
      newlayOutFilters[`views-filter[${key}]`] = value;
    });
    return newlayOutFilters;
  }, [layoutExposedFilters]);

  const argumentsQuery: (string | number)[] = useMemo(() => {
    const newArguments: (string | number)[] = [];
    forEach(args, (value, key) => {
      newArguments[key] = value;
    });
    return newArguments;
  }, [args]);

  const sortsQuery = useMemo(() => {
    if (sortBy) {
      return {
        "views-sort[sort_by]": sortBy.sort_by,
        "views-sort[sort_order]": sortBy.sort_order,
      };
    }

    return undefined;
  }, [sortBy]);

  const {
    data: originalData,
    status,
    fetchData: doFetchData,
  } = useGet<getViewExtended<JsonApiResource[]>>({
    url: "/api/view",
    params: {
      viewId,
      displayId,
      "page": page.toString(),
      "views-argument": argumentsQuery,
      ...exposedFiltersQuery,
      ...sortsQuery,
      ...layoutBuilderFiltersQuery,
      include,
      "langcode": router.locale,
    },
    ...rest,
  });

  const fetchData = (keepPreviousPage?: boolean) => {
    // This 'keepPreviousPageRef' will be used to keep the previous page data when using loadmore.
    keepPreviousPageRef.current = keepPreviousPage ?? false;
    doFetchData();
  };

  useEffect(() => {
    if (originalData) {
      const newData = cloneDeep(originalData);

      // This 'keepPreviousPageRef' will be used to keep the previous page data when using
      // loadmore component.
      if (keepPreviousPageRef.current) {
        const oldDataResults = oldData.current?.results ?? [];
        newData.results = [...oldDataResults, ...newData.results];

        // Filter out results with the same id. Sometimes, this hook is called twice
        // TODO: Find out a more efficient way.
        // TODO: Find out why this hooks is being called more than necessary.
        newData.results = uniqBy(newData.results, "id");
      }

      setData(newData.results);

      // Meta should not change.
      if (oldData.current) {
        newData.meta = cloneDeep(oldData.current.meta);
      }
      if (originalData?.meta?.pager.id === "full") {
        setPagination({
          currentPage: page,
          fullCount: originalData.meta.pager.count,
          itemsPerPage: originalData.meta.pager.configurations.items_per_page,
          totalPages: Math.ceil(originalData.meta.pager.count / originalData.meta.pager.configurations.items_per_page),
        });
      }

      oldData.current = newData;
      setActualData(newData);
    }
  }, [originalData]);

  useEffect(() => {
    setPage(0);
  }, [exposedFilters, sortBy]);

  return {
    viewData: actualData,
    data,
    setData,
    setExposedFilters,
    setArgs,
    args,
    setSortBy,
    exposedFilters,
    sortBy,
    status,
    pagination,
    setPage,
    fetchData,
  };
};

export const useSolr = function <T>(config: UseGetConfig<SolrResponse<T>>): UseSolrReturn<T> {
  const {status, data, fetchData} = useGet<SolrResponse<T>>(config);
  const pagination = useMemo(() => {
    return data?.pager;
  }, [data]);
  const foramttedFacets: FormattedFacet[] = useMemo(() => {
    const tempFacets: FormattedFacet[] = [];
    if (data) {
      forEach(data.facets, (facet) => {
        const formattedValues: FormattedFacetValue[] = [];
        // Get the first key of facet[0]
        const facetItem = facet[0];

        // If it is empty.
        if ("#attributes" in facetItem || "0" in facetItem) {
          return;
        }

        const key = Object.keys(facetItem)[0];

        const metadata = find(data.facets_metadata, {field_id: key});

        if (!metadata) {
          return;
        }

        forEach(facetItem[key], (value) => {
          // Get the pathname and query from url and merge them into one string.
          try {
            const pathname = new URL(value.url).pathname;
            const query = new URL(value.url).search;
            const url = pathname + query;

            formattedValues.push({
              raw_value: value.raw_value,
              label: value.values.value,
              url,
              count: value.values.count,
              active: value.values.active,
            });
          } catch (e) {
            console.error(e);
          }
        });

        tempFacets.push({
          id: metadata.field_id,
          label: metadata.label,
          weight: metadata.weight,
          values: formattedValues,
        });
      });
      // Sort the facets by weight.
      return sortBy(tempFacets, "weight");
    } else {
      return [];
    }
  }, [data]);

  const formatSolrPagination = (pagination: SolrPaginationConfig): PaginationConfig => {
    return {
      currentPage: pagination.current_page,
      fullCount: pagination.total_items,
      itemsPerPage: pagination.items_per_page,
      totalPages: pagination.total_pages,
    };
  };

  return {
    results: data?.search_results,
    facets: foramttedFacets,
    fetchData,
    pagination: pagination ? formatSolrPagination(pagination) : undefined,
    status,
  };
};

/**
 * After mount effect hook.
 */
export const useAfterMountEffect = (func: () => void, deps: any[]) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      func();
    } else {
      mounted.current = true;
    }
  }, deps);
};

export const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback(
    (e: any) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    },
    [targetReached],
  );

  useEffectAsEarlyAsPossible(() => {
    if (typeof window === "undefined") {
      return;
    }
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addEventListener("change", updateTarget);
    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeEventListener("change", updateTarget);
  }, []);

  return targetReached;
};

export const useWebform = (): WebformHookReturn => {
  return useContext(WebformContext) as WebformHookReturn;
};
export const LayoutContext = React.createContext<SlugProps>({} as SlugProps);

export const useLayout = (): SlugProps => {
  const values = useContext(LayoutContext);
  return values;
};

export const useEffectAsEarlyAsPossible = (effect: React.EffectCallback, deps?: React.DependencyList) => {
  const hasBeenCalled = useRef(false);
  if (!hasBeenCalled.current) {
    effect();
    hasBeenCalled.current = true;
  }

  useEffect(effect, deps);
};

/**
 * Uses the session storage.
 */
export const useSessionStorage = <T = Record<string, any>>(key: string): UseSessionStorageParams<T> => {
  const browserSide = typeof window !== "undefined";
  const sessionStorageValue = useMemo(() => {
    if (!browserSide) return;
    const value = sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }, []);

  const setSessionStorageValue = (value: any) => {
    // Set the local storage as JSON.
    if (isObject(value) && browserSide) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  };

  const remove = () => {
    browserSide && sessionStorage.removeItem(key);
  };

  return {
    sessionStorageValue,
    setSessionStorageValue,
    remove,
  };
};
export const useExposedFiltersContext = <T = ExposedFitlerValue>(): useExposedFiltersReturn<T> => {
  return useContext<useExposedFiltersReturn<T>>(ExposedFiltersContext);
};

/**
 *
 * TODO: Correct typing instead of any.
 *
 * @param props
 * @param beforeSubmit
 * @returns
 */
export const useExposedFilters = (
  props: ViewPartComponentProps<any, any>,
  beforeSubmit?: (values: any) => void,
): useExposedFiltersReturn<any> => {
  const [values, setValues] = useState<Record<string, ExposedFitlerValue>>(props.exposedFilters || {});
  const debounceRef = useRef(false);
  const timeoutRef = useRef<any>();
  useAfterMountEffect(() => {
    clearTimeout(timeoutRef.current);
    if (values === props.exposedFilters) {
      return;
    }
    if (debounceRef.current) {
      timeoutRef.current = setTimeout(syncExposedFilters, 1000);
    } else {
      syncExposedFilters();
    }
  }, [values]);

  /**
   * Sync the local values to the view exposed values.
   */
  const syncExposedFilters = () => {
    // Cleanup.
    const newExposedValues = mapValues(values, (value) => {
      if (isHierarchyRequest(value)) {
        let tmpArray = [];

        if (value.parentValue) {
          const children = value.parentValue?.children;
          if (children) {
            forEach(children, (child) => {
              tmpArray.push(child.value);
            });
          }
          tmpArray.push(value.parentValue?.value);
        }
        if (value.childValue) {
          tmpArray = [value.childValue?.value];
        }
        return tmpArray;
      } else if (isCompoundValue(value)) {
        return value.value;
      } else if (value instanceof Array) {
        if (value.length === 0) {
          return null;
        }
        return map(value, (item) => (item.value ? item.value : item));
      } else {
        return value;
      }
    });
    if (beforeSubmit) {
      beforeSubmit(newExposedValues);
    }
    props.setExposedFilters(newExposedValues);
    props.fetchData();
  };

  const setExposedFilter = (key: string, value: any, debounce: boolean = false) => {
    const newValues = clone(values);
    newValues[key] = value;
    setValues(newValues);
    debounceRef.current = debounce;
  };

  const setViewArguments = (values: string[]) => {
    props.setArgs(values);
    props.fetchData();
  };

  const setSortBy = (sortBy: ViewSortByParam | null) => {
    props.setSortBy(sortBy);
    props.fetchData();
  };

  return {
    values,
    setSortBy,
    sortValue: props.sortBy,
    setExposedFilter,
    setValues,
    setViewArguments,
    args: props.args,
  };
};

export const useRating = () => {
  const providerValues = useContext(RatingContextProvider);
  if (providerValues) {
    return providerValues;
  } else {
    throw new Error("useRating must be used within a RatingContextProvider");
  }
};
