import classNames from "classnames";
import React from "react";
type Props = {
  children: any;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Teaser = ({children, className}: Props) => {
  return (
    <article
      className={classNames(className, " bg-white flex flex-col h-full shadow-teaser rounded-lg overflow-hidden")}
    >
      {children}
    </article>
  );
};

type HeaderProps = {
  children: any;
  className?: string;
  pattern?: boolean;
};

const Header = ({children, className, pattern = false}: HeaderProps) => {
  return <div className={classNames(className, "relative", pattern && "teaser-pattern")}>{children}</div>;
};

const Body = ({children, className}: Props) => {
  return <div className={classNames(className, "py-[16px] px-[24px]")}>{children}</div>;
};

const Footer = ({children, className}: Props) => {
  return <div className={classNames(className, "pb-[16px] px-[24px] mt-auto")}>{children}</div>;
};

Teaser.Header = Header;
Teaser.Body = Body;
Teaser.Footer = Footer;

export default Teaser;
