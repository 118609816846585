import React, {useRef} from "react";
import FullCalendar from "@fullcalendar/react";
import daysGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {getLangcode, t} from "@lib/translations-provider";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import classNames from "classnames";
import Link from "next/link";
import {EventApi} from "@fullcalendar/core";
import {JsonApiResource} from "next-drupal";
import CalendarHeader from "./CalendarHeader";
import { useMediaQuery } from "@lib/hooks";

type CalendarProps = {
  results: JsonApiResource[];
};

export const Calendar = (props: CalendarProps) => {
  const calendarRef = useRef<FullCalendar>(null);
  const [date, setDate] = React.useState<moment.Moment | null>(moment());
  const isMobile = useMediaQuery(768);
  return (
    <div>
      <CalendarHeader calendarRef={calendarRef} date={date} setDate={setDate} />
      <FullCalendar
        ref={calendarRef}
        headerToolbar={false}
        dateIncrement={{month: 1}}
        locale={getLangcode()}
        direction={getLangcode() === "ar" ? "rtl" : "ltr"}
        dayHeaderClassNames="calendar-day-header"
        plugins={[daysGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        dayCellClassNames="calendar-day-cell"
        weekends={true}
        aspectRatio={2}
        slotMinTime={"00:00:00"}
        slotMaxTime={"24:00:00"}
        dragScroll={false}
        contentHeight="auto"
        dateClick={() => {}}
        events={props.results.map((event) => {
          return {
            title: event.title,
            date: event.field_date_range_1.value,
            canonical: event.canonical,
            title1: event.title,
          };
        })}
        eventContent={false}
        slotEventOverlap={false}
        moreLinkContent={true}
        firstDay={0}
        allDayContent={true}
        dayHeaderFormat={{
          weekday: isMobile ? "narrow" : "long",
        }}
        nextDayThreshold={"00:00:00"}
        dayCellContent={(arg) => {
          const getEventsByDate = calendarRef.current
            ?.getApi()
            .getEvents()
            .filter((event) => {
              const eventDate = moment(event.start).locale("en").format("YYYY-MM-DD");
              const selectedDate = moment(arg.date).locale("en").format("YYYY-MM-DD");
              return eventDate === selectedDate ? true : false;
            });
          if (typeof getEventsByDate === "undefined") return null;
          return (
            <div className={classNames("border-none")}>
              <div className="ps-3">
                <span className={classNames(arg.isToday && "text-[#2E5156] font-bold p-1 py-0")}>
                  {arg.dayNumberText}
                </span>
              </div>
              <div>
                {getEventsByDate.length > 0 ? (
                  <>
                    {getEventsByDate?.map((ev, index) => (
                      <EventContent ev={ev} key={index} />
                    ))}
                  </>
                ) : (
                  <div className="" />
                )}
              </div>
            </div>
          );
        }}
        eventMinHeight={167}
        allDayText={t("All day")}
        allDaySlot={true}
        allDayClassNames="calendar-all-day"
      />
    </div>
  );
};

const EventContent = ({ev}: {ev: EventApi}) => {
  const {extendedProps} = ev;

  return (
    <Link aria-label={extendedProps.title1} href={extendedProps.canonical ?? ""} className="bg-[#CFAB95] rounded-md mt-4 inline-block">
      <p className="text-white ps-1 line-clamp-2 max-tablet:text-sm">{extendedProps.title1}</p>
    </Link>
  );
};

export default Calendar;
