import {Button} from "@components/elements";
import {getDirection, t} from "@lib/translations-provider";
import {DrupalMediaFile} from "@type/entity";
import React from "react";
import {Gallery, Item} from "react-photoswipe-gallery";
import PreviewIcon from "@icons/preview.svg";
import {useMediaQuery} from "@lib/hooks";
type Props = {
  pdf: DrupalMediaFile;
  label?: string;
};
const FilePreview = ({pdf, label}: Props) => {
  const isPhone = useMediaQuery(767);

  const iframe = `<iframe id="iframepdf" width="100%" height="100%" src="${pdf.field_media_file.uri.url}"></iframe>`;
  const isRtl = getDirection() === "rtl";

  return isPhone ? (
    <a href={pdf.field_media_file.uri.url} target="_blank" rel="noreferrer">
      <Button
        className="d-none d-lg-block leading-none"
        variant="primary"
        aria-label="Preview"
        iconClassName="ms-[8px] w-[20px] h-[20px]"
        iconPosition="right"
        icon={PreviewIcon}
      >
        {label ? t(label) : t("Preview")}
      </Button>
    </a>
  ) : (
    <Gallery
      onOpen={(photoswipe) => {
        photoswipe.template?.setAttribute("dir", "ltr");
        {
          if (isRtl) {
            photoswipe.template?.classList.add("flip-arrows");
          } else {
            photoswipe.template?.classList.remove("flip-arrows");
          }
        }
      }}
    >
      <Item html={iframe} width="1024" height="768">
        {({ref, open}) => (
          <Button
            className="d-none d-lg-block leading-none"
            variant="primary"
            aria-label="Preview"
            iconClassName="ms-[8px] w-[20px] h-[20px]"
            iconPosition="right"
            icon={PreviewIcon}
            ref={ref as React.RefObject<HTMLButtonElement>}
            onClick={open}
          >
            {label ? t(label) : t("Preview")}
          </Button>
        )}
      </Item>
    </Gallery>
  );
};

export default FilePreview;
