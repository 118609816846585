/**
 * @file Defines general and validation functions for react-hook-form.
 */

import {DatelistSelectElement, SelectValues} from "@components/webform-elements/DatelistElement/types";
import {EmailConfirmValue, EmailConfirmWebformElement, PollRadiosWebformElement, ValidateFunction} from "@type/general";
import yaml from "js-yaml";
import {every} from "lodash";
import moment from "moment";
import {ValidateResult} from "react-hook-form";
import {getChildrenKeys} from "./webform";

/**
 * validates email.
 */
export const validateEmail = (email: string | undefined, t: undefined | ((str: string) => string)): ValidateResult => {
  if (!email) {
    return true;
  }

  const match = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  if (!match) {
    return t ? t("Email is not a valid address") : "Email is not a valid address";
  }

  return;
};

/**
 * This function only exists to act as a bridge between webforms and validateEmail function.
 *
 * @webform
 */
export const validateEmailWebform: ValidateFunction = (email, element, t) => {
  return validateEmail(email, t);
};

/**
 * validates email confirmation.
 *
 * @webform
 */
export const validateConfirmEmail: ValidateFunction<EmailConfirmValue, EmailConfirmWebformElement> = (
  value,
  element,
  t,
) => {
  if (value && value.email !== value.confirm) {
    return t("Email addresses do not match");
  }

  // Handle #required case because the value is an object.
  const valueIsEmpty = !value || !value.email || !value.confirm;
  if (element["#required"] && valueIsEmpty) {
    return t("This field is required");
  }

  // Validate both emails.
  const emailError = validateEmailWebform(value?.email, element, t);
  const confirmError = validateEmailWebform(value?.confirm, element, t);

  return emailError || confirmError;
};

export const validatePoll: ValidateFunction<Record<string, string>, PollRadiosWebformElement> = (
  value: Record<string, string> | undefined,
  element,
  t,
) => {
  element = element as PollRadiosWebformElement;
  if (element["#required"]) {
    if (!value) {
      return t("This field is required");
    }
    const questions = yaml.load(element["#questions"]) as Record<string, string>;
    const isValid = every(questions, (question, questionKey) => {
      return typeof value[questionKey] === "string" || typeof value[questionKey] === "number";
    });

    if (!isValid) {
      return t("Some of the questions are not answered");
    }
  }

  return;
};

export const validateDatelist: ValidateFunction<SelectValues, DatelistSelectElement> = (value, element, t) => {
  if (element["#required"]) {
    // Keys of SelectValues.
    const allKeys: Array<keyof SelectValues> = getChildrenKeys(element) as Array<keyof SelectValues>;

    // All values in SelectValues should exist and are not empty.
    const isValid = value && every(allKeys, (key) => value[key] !== undefined && value[key] !== "");

    if (!isValid) {
      return t("This field is required");
    }
  }

  return undefined;
};

export const normalizeDatelist = (value?: SelectValues) => {
  const year = (value && value.year) || moment().year();
  const month = (value && value.month) || "01";
  const day = (value && value.day) || "01";
  const hour = (value && value.hour) || "00";
  const minute = (value && value.minute) || "00";

  // Format into something like "2022-11-27T16:30:00"
  return `${year}-${month}-${day}T${hour}:${minute}:00`;
};
