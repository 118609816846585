import classNames from "classnames";
import React from "react";
import {FormCheck, FormCheckProps} from "react-bootstrap";
import classes from "./Radio.module.scss";
type Props = Omit<FormCheckProps, "type"> & {
  showCircle?: boolean;
};

const Radio = React.forwardRef<HTMLInputElement, Props>(({id, placeholder, showCircle = true, ...rest}, ref) => {
  return (
    <FormCheck className={classes.radioCheck} bsPrefix="circle-radio" {...rest}>
      <FormCheck.Input bsPrefix={classNames(classes.radioInput, "d-none")} type="radio" id={id} ref={ref} {...rest} />
      <FormCheck.Label className={classes.radioLabel} htmlFor={id}>
        {placeholder} {showCircle && <RadioCircle />}
      </FormCheck.Label>
    </FormCheck>
  );
});

const RadioCircle = () => {
  return (
    <div className={classes.circleWrapper}>
      <div className={classes.bigCircle}>
        <div className={classes.smallCircle}></div>
      </div>
    </div>
  );
};

export default Radio;
