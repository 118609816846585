import React from "react";
import {CustomBlockComponent, LabelDisplay} from "@type/layout";
import dynamic from "next/dynamic";
import {EntityDisplayProps} from "@type/general";
import { Heading } from "@components/elements";
import TitlePattern from "@icons/title-pattern.svg";

type Props = {
  component: CustomBlockComponent;
  label?: string;
  displayLabel?: LabelDisplay;
};

const BlockComponent = ({component, label, displayLabel}: Props) => {
  let Component;
  let bundleId: string;
  try {
    if (component.props) {
      const componentType = component.props?.type.split("--");
      if (componentType.length > 0) {
        bundleId = componentType[0];
      }
      Component = dynamic<EntityDisplayProps>(() =>
        import(`@components/displays/${bundleId}/${componentType[1]}/index`).then((res) => {
          return res.default;
        }),
      );
      const renderBlockTitle = () => {
        if (displayLabel === "visible" && label) {
          return (
            <Heading className="mb-[30px]" icon={TitlePattern} iconPosition={"right"} element="h2" type="medium">
              {label}
            </Heading>
          );
        }
      };
      if (Component) {
        return (
          <>
          {renderBlockTitle()}
          <div className={`custom-block ${component.props.type}`}>
            <Component resource={component.props} />
          </div>
          </>
        );
      } else {
        return <p>No component found for custom block &quot;{component.props?.type}&quot; was not found.</p>;
      }
    } else {
      return <p>No component props found for custom block &quot;{component.configuration.type}&quot; was not found.</p>;
    }
  } catch (error) {
    return <p>No component found for custom block &quot;{component.props?.type}&quot; was not found.</p>;
  }
};

export default React.memo(BlockComponent, (prevProps, nextProps) => {
  return prevProps.component.uuid === nextProps.component.uuid;
});

