import {clone, filter, isArray, map} from "lodash";
import React, {useMemo} from "react";
import {FormCheck} from "react-bootstrap";
import {
  CheckboxesWebformElement,
  EntityCheckboxesWebformElement,
  GenericControlController,
  TermCheckboxesWebformElement,
} from "@type/general";

const CheckboxesOptionsElement = React.forwardRef<
  any,
  GenericControlController<CheckboxesWebformElement | EntityCheckboxesWebformElement | TermCheckboxesWebformElement>
>(({webformElement, onChange, value}, ref) => {
  const modifiedValue = useMemo<string[]>(() => {
    if (isArray(value)) {
      return value;
    } else {
      return [];
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValues = clone(modifiedValue);
    if (e.target.checked) {
      newValues.push(e.target.name);
    } else {
      newValues = filter(newValues, (name) => e.target.name !== name);
    }

    onChange(newValues);
  };

  return (
    <>
      {map(webformElement["#options"], (label, name) => (
        <FormCheck
          key={name}
          id={`${webformElement["#webform_key"]}${name}`}
          onChange={handleChange}
          checked={modifiedValue.includes(name)}
          name={name}
          label={label}
          ref={ref}
        />
      ))}
    </>
  );
});

export default CheckboxesOptionsElement;
