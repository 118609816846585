import {isEmpty, map, omitBy} from "lodash";
import React, {useMemo} from "react";
import {FormSelect, FormSelectProps} from "react-bootstrap";

type Props = {
  options: Record<string, string>;
  emptyLabel?: string;
} & FormSelectProps;

// TODO: Move this component to be a general component.
const Select = ({options, emptyLabel = "- None -", ...rest}: Props, ref: React.ForwardedRef<HTMLSelectElement>) => {
  // Make sure to remove any empty value.
  const modifiedOptions = useMemo(() => {
    return omitBy(options, (label, value) => isEmpty(value));
  }, [options]);

  return (
    <div className="select-wrapper">
      <FormSelect ref={ref} {...rest}>
        <option value="">{emptyLabel}</option>
        {map(modifiedOptions, (label, value) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </FormSelect>
    </div>
  );
};

export default React.forwardRef<HTMLSelectElement, Props>(Select);
