import {GenericControlRef, TextareaWebformElement} from "@type/general";
import React from "react";
import {Form} from "react-bootstrap";

const TextareaElement = React.forwardRef<any, GenericControlRef<TextareaWebformElement>>(
  ({webformElement, ...rest}: GenericControlRef<TextareaWebformElement>, ref) => (
    <Form.Control className="textarea-element" {...rest} ref={ref} as="textarea" rows={6} />
  ),
);

export default TextareaElement;
