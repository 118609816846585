import {Button} from "@components/elements";
import {t} from "@lib/translations-provider";
import React from "react";
import {NewsletterFormSchemaType} from "../types";
import {useFormContext} from "react-hook-form";
import {Form} from "@radix-ui/react-form";
import classNames from "classnames";

type Props = {
  onSubmit: (data: NewsletterFormSchemaType) => void;
};

const NewsletterFooter = ({onSubmit}: Pick<Props, "onSubmit">) => {
  const {
    register,
    handleSubmit,
    formState: {isSubmitting},
  } = useFormContext<NewsletterFormSchemaType>();
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames("news-letter-wrapper", isSubmitting && "pointer-events-none")}
    >
      <input placeholder={t("Join our Newsletter")} type="text" {...register("newsletter_mail")} />
      <Button aria-label="Subscription">{t("Subscription")}</Button>
    </Form>
  );
};

export default NewsletterFooter;
