import React from "react";
import classes from "./DefaultViewWrapper.module.scss";
import classNames from "classnames";
import {ViewWrapperProps} from "@type/layout";
import {t} from "@lib/translations-provider";
import {Heading} from "@components/elements";

const DefaultViewWrapper = ({
  rowWrapper: Wrapper,
  list: List,
  pager: Pager,
  exposedFilter: ExposedFilter,
  header: Header,
  footer: Footer,
  viewComponentProps,
}: ViewWrapperProps) => {
  const listRef = React.useRef<HTMLDivElement>(null);

  return (
    <Wrapper {...viewComponentProps}>
      {Header && (
        <div className={classNames("view-header")}>
          <Header {...viewComponentProps} />
        </div>
      )}
      {ExposedFilter && (
        <div className={classNames("exposed-filters", classes.exposedFiltersWrapper)}>
          <ExposedFilter {...viewComponentProps} />
        </div>
      )}
      {viewComponentProps.resources?.length > 0 ? (
        <div ref={listRef} id={viewComponentProps.viewData.viewId}>
          <List {...viewComponentProps} />
        </div>
      ) : (
        <Heading element="h2" type="medium" className={"text-center mt-8"}>
          {t(viewComponentProps.emptyBehavior)}
        </Heading>
      )}
      {Footer && (
        <div className="view-footer">
          <Footer {...viewComponentProps} />
        </div>
      )}
      <Pager {...viewComponentProps} listRef={listRef}/>
    </Wrapper>
  );
};

export default DefaultViewWrapper;
