import React from "react";
import { t } from "@lib/translations-provider";

type props = {
  values: number;
  totals: number;
};

const RatingResult = ({values, totals}: props) => {
  return (
    <div className="result-wrapper">
      {t("{{Result}} visitors liked the page's content out of {{Total}} posts", {
        Result: values,
        Total: totals,
      })}
    </div>
  );
};

export default RatingResult;
