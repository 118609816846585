import React, {RefObject} from "react";
import AddBookmark from "@icons/add-bookmark.svg";
import WhiteAddBookmark from "@icons/white-archive.svg";
import PrintPage from "@icons/print-outline.svg";
import Mail from "@icons/mail.svg";
import Share from "@icons/share.svg";
import {hasCanonical, isDrupalNode, successMessage} from "@lib/utils";
import {t} from "@lib/translations-provider";
import {useLayout} from "@lib/hooks";
import {Button, Heading, Icon} from "@components/elements";
import {Tooltip} from "react-tooltip";
import classNames from "classnames";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import TwitterIcon from "@icons/twitter.svg";
import LinkedinIcon from "@icons/linkedin.svg";
import FacebookIcon from "@icons/facebook.svg";
import CloseIcon from "@icons/close.svg";
import * as Dialog from "@radix-ui/react-dialog";
import filter from "lodash/filter";
type PageAccessoriesProps = {
  mainContentRef: RefObject<HTMLDivElement>;
  type?: string[];
};

const PageAccessories = ({mainContentRef, type}: PageAccessoriesProps) => {
  const {resource} = useLayout();
  const checkIsTypeExclude = (resourceType: string) => {
    return filter(type, (item) => resourceType == item).length > 0;
  };

  const handleCopyText = (text: string) => {
    if (!navigator.clipboard) {
      const textField: HTMLTextAreaElement = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      successMessage(t("Text Copied to clipboard"), {
        className: "toast-content",
      });
    } else {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          successMessage(t("Text Copied to clipboard"), {
            className: "toast-content",
          });
        })
        .catch(() => {
          successMessage(t("Error Copying the text"), {
            className: "toast-content",
          });
        });
    }
  };
  const handleAddBookmark = () => {
    if (typeof window !== "undefined") {
      alert(
        t("You can add this page to your bookmarks by pressing ") +
          (navigator.userAgent.toLowerCase().indexOf("mac") != -1 ? "Command/Cmd" : "CTRL") +
          " + D" +
          t("to bookmark this page."),
      );
    }
  };
  let currentUrl: string = "";
  if (typeof window !== "undefined") {
    if (hasCanonical(resource)) {
      currentUrl = `${process.env.NEXT_PUBLIC_NEXTJS_DOMAIN}${resource.canonical}`;
    } else {
      if (isDrupalNode(resource)) {
        currentUrl = `${process.env.NEXT_PUBLIC_NEXTJS_DOMAIN}/l/${resource.drupal_internal__nid}`;
      } else {
        currentUrl = window.location.href;
      }
    }
  }
  const documentTitle: string = typeof document !== "undefined" ? document.title : "";

  const handleSendEmail = () => {
    if (typeof window !== "undefined") {
      window.open(
        `mailto:?subject=${documentTitle} &body=${t(
          "I would like to refer this Portal Page to you. You can visit the page at the following link",
        )}: ${currentUrl}`,
        "emailWindow",
      );
    }
  };

  return (
    <>
      <div className={classNames("page-accessories", checkIsTypeExclude(resource.type) && "white-icon")}>
        <div className="flex gap-3 items-center">
          <Button
            onClick={handleAddBookmark}
            aria-label="Add to Bookmark"
            className="!p-0 bg-transparent [&>svg>path]:fill-none"
            data-tooltip-id="my-tooltip"
            data-tooltip-content={t("Add to Bookmark")}
            tabIndex={0}
            variant="transparent"
            data-tooltip-place="bottom"
          >
            <Icon icon={checkIsTypeExclude(resource.type) ? WhiteAddBookmark : AddBookmark} className="w-6 h-6" />
          </Button>

          <Button
            aria-label="Print"
            className="!p-0 bg-transparent"
            variant="transparent"
            onClick={() => window.print()}
            tabIndex={0}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={t("Printer")}
            data-tooltip-place="bottom"
          >
            <Icon icon={PrintPage} />
          </Button>
          <Button
            variant="transparent"
            aria-label="Email"
            className="!p-0 bg-transparent [&>svg]:fill-[#9AA5B1]"
            onClick={handleSendEmail}
            tabIndex={0}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={t("Share by Email")}
            data-tooltip-place="bottom"
          >
            <Icon icon={Mail} />
          </Button>

          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button
                variant="transparent"
                className={classNames("!p-0 bg-transparent")}
                tabIndex={0}
                aria-label="Share"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={t("Share")}
                data-tooltip-place="bottom"
              >
                <Icon icon={Share} />
              </Button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-gray/30 data-[state=open]:animate-overlayShow fixed inset-0 z-[2] " />
              <Dialog.Content className="data-[state=open]:animate-contentShow flex flex-col gap-8 z-50 items-center fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[580px] translate-x-[-50%] translate-y-[-50%] border border-[#E4E7EB] bg-white   rounded-lg">
                <div className="p-[24px] flex flex-col gap-8 items-center w-full">
                  <div className="flex w-full items-center justify-between">
                    <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                      <Heading className="font-somer text-[34px] font-bold" type="medium">
                        {t("Share this with your community")}
                      </Heading>
                    </Dialog.Title>
                    <Dialog.Close asChild>
                      <button aria-label="Close">
                        <Icon icon={CloseIcon} className="w-6 h-6" />
                      </button>
                    </Dialog.Close>
                  </div>
                  <div>
                    <FacebookShareButton
                      aria-label="Facebook"
                      resetButtonStyle={false}
                      url={currentUrl}
                      quote={documentTitle}
                      data-tooltip-id="share-tooltip"
                      data-tooltip-content={t("facebook.footer")}
                    >
                      <Icon icon={FacebookIcon} className="[&>*]:fill-blue w-12 h-12" />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      aria-label="LinkedIn"
                      resetButtonStyle={false}
                      url={currentUrl}
                      title={documentTitle}
                      data-tooltip-id="share-tooltip"
                      data-tooltip-content={t("linkedin.footer")}
                    >
                      <Icon icon={LinkedinIcon} className="[&>*]:fill-blue w-12 h-12" />
                    </LinkedinShareButton>
                    <TwitterShareButton
                      aria-label="Twitter"
                      resetButtonStyle={false}
                      url={currentUrl}
                      title={documentTitle}
                      data-tooltip-id="share-tooltip"
                      data-tooltip-content={t("twitter.footer")}
                    >
                      <Icon icon={TwitterIcon} className="[&>*]:fill-blue w-12 h-12" />
                    </TwitterShareButton>
                    <Tooltip id="share-tooltip" />
                  </div>
                  <div className="w-full  flex rounded-lg flex-row py-[8.5px] px-4 bg-gray-lighter justify-between items-center">
                    <input
                      type="text"
                      className="focus:!shadow-none focus:!outline-none !border-0 !ring-transparent !border-transparent rfs-26 max-mobile:text-[18px] basis-[82%] bg-transparent appearance-none text-[#9AA5B1]"
                      value={currentUrl}
                      readOnly
                    />
                    <Button
                      variant="transparent"
                      className="px-0 py-0 text-primary"
                      onClick={() => handleCopyText(currentUrl)}
                    >
                      {t("copy.footer")}
                    </Button>
                  </div>
                </div>
                <div className="w-full h-full modal-pattern"></div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>

        <Tooltip id="my-tooltip" />
      </div>
    </>
  );
};

export default PageAccessories;
