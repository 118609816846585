import {createContext, useCallback, useState} from "react";

type ContextType = {
  increment: () => void;
  decrement: () => void;

  handleSize: (arg0: number) => void;

  counter: number;
};

export const ThemeContext = createContext<ContextType>({
  increment: () => {},
  decrement: () => {},

  handleSize: () => {},

  counter: 0,
});

const ThemeContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [counter, setCounter] = useState(0);
  const handleSize = (num: number) => {
    setCounter(num);
  };

  const increment = useCallback(() => {
    if (counter < 12) {
      setCounter((previousCounter) => previousCounter + 3);
    } else {
      return;
    }
  }, [counter]);
  const decrement = useCallback(() => {
    if (counter > 0) {
      setCounter((previousCounter) => previousCounter - 3);
    } else {
      return;
    }
  }, [counter]);

  return <ThemeContext.Provider value={{counter, increment, decrement, handleSize}}>{children}</ThemeContext.Provider>;
};

export default ThemeContextProvider;
