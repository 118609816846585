import {Heading} from "@components/elements";
import {t} from "@lib/translations-provider";
import {ViewWrapperProps} from "@type/layout";
import classNames from "classnames";

const MinimalViewWrapper = ({
  rowWrapper: Wrapper,
  list: List,
  pager: Pager,
  exposedFilter: ExposedFilter,
  header: Header,
  footer: Footer,
  viewComponentProps,
}: ViewWrapperProps) => {
  return (
    <Wrapper {...viewComponentProps}>
      {Header && (
        <div className={classNames("view-header")}>
          <Header {...viewComponentProps} />
        </div>
      )}
      {ExposedFilter && (
        <div className={classNames("exposed-filters")}>
          <ExposedFilter {...viewComponentProps} />
        </div>
      )}
      {viewComponentProps.resources.length > 0 ? (
        <List {...viewComponentProps} />
      ) : (
        <Heading>{t(viewComponentProps.emptyBehavior)}</Heading>
      )}
      {Footer && (
        <div className="view-footer">
          <Footer {...viewComponentProps} />
        </div>
      )}
      <Pager {...viewComponentProps} />
    </Wrapper>
  );
};

export default MinimalViewWrapper;
