import {Button} from "@components/elements";
import {Body} from "@components/fields";
import {t} from "@lib/translations-provider";
import {FieldFormattedBody} from "@type/fields";
import classNames from "classnames";
import React, {useState} from "react";
import classes from "./LongBody.module.scss";

type Props = {
  body: FieldFormattedBody;
  bodyLength?: number;
  onReadMoreClick?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const LongBody = ({body, bodyLength = 800, onReadMoreClick, ...rest}: Props) => {
  const isLong = body.value.length > bodyLength;
  const [isOpen, setOpen] = useState(false);
  return (
    <div {...rest}>
      <Body body={body} className={classNames(isLong && !isOpen ? classes.hide : "max-h-[unset]")} />
      {isLong ? (
        <div className={classNames(!isOpen && "relative")}>
          <Button
            type="button"
            aria-label="Read button"
            className="text-lg font-bold"
            onClick={() => {
              onReadMoreClick ? onReadMoreClick() : setOpen(!isOpen);
            }}
          >
            {isOpen ? t("Read less") : t("Read more")}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default LongBody;
