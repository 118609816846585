import {urlToNextJs} from "@lib/api";
import {t} from "@lib/translations-provider";
import {handleEmptyDescription, hasCanonical, isDrupalNode, isDrupalTerm} from "@lib/utils";
import {EntityDisplayProps} from "@type/general";
import {NextSeo} from "next-seo";
import {MetatagsProps, OverridableMetatagsProps} from "./types";

// TODO : replace langcode to getLangcode
const ExtendedHead = ({
  resource,
  title,
  description,
  image,
  langcode,
}: EntityDisplayProps & OverridableMetatagsProps & MetatagsProps) => {
  let canonical: string | undefined;
  const siteName = t("King Abdul Aziz Center for Purebred Arabian Horses");
  if (hasCanonical(resource)) {
    canonical = `${process.env.NEXT_PUBLIC_NEXTJS_DOMAIN}${resource.canonical}`;
  } else if (isDrupalNode(resource)) {
    canonical = `${process.env.NEXT_PUBLIC_NEXTJS_DOMAIN}/${langcode}/node/${resource.drupal_internal__nid}`;
  }
  if (!title) {
    if (resource && isDrupalTerm(resource)) {
      title = resource.name;
    } else if (resource && isDrupalNode(resource)) {
      title = resource.title;
    }
  }

  if (!image) {
    image = "/default-metatag-image.png";
  }

  // If image starts with /, add the domain.
  if (image && image.startsWith("/")) {
    image = urlToNextJs(image);
  }

  if (!description) {
    description = handleEmptyDescription(resource, t);
  }

  const fullTitle = title ? `${title} | ${siteName}` : siteName;
  return (
    <NextSeo
      title={fullTitle}
      canonical={canonical}
      openGraph={{
        url: process.env.NEXT_PUBLIC_NEXTJS_DOMAIN + (resource.canonical || ""),
        title: fullTitle,
        description: description,
        site_name: siteName,
        images: [{url: image}],
      }}
      twitter={{
        handle: "@handle",
        site: "@site",
        cardType: "summary_large_image",
      }}
      description={description}
    />
  );
};

export default ExtendedHead;
