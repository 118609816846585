import map from "lodash/map";
import React from "react";
import {
  LayoutBuilderSection,
  LayoutRegion,
  Seeds1ColLayoutSection,
  Seeds2ColLayoutSection,
  Seeds3ColLayoutSection,
} from "types/layout";
import {convertAttributes, convertLayoutFieldAttributes, getComponents} from "@lib/layout-builder";
import Component from "./Component";
type Props = {
  section: LayoutBuilderSection;
};

const renderComponents = (region: LayoutRegion, section: LayoutBuilderSection) => {
  const components = getComponents(section.components, region);
  return map(components, (component) => {
    return <Component key={component.uuid} component={component} />;
  });
};

const Section = ({section}: Props) => {
  switch (section.layout_id) {
    case "seeds_1col":
      return <Seeds1ColSection section={section} />;
    case "seeds_2col":
      return <Seeds2ColSection section={section} />;
    case "seeds_3col":
      return <Seeds3ColSection section={section} />;
    default:
      return null;
  }
};

// Seeds1col section component.
interface Seeds1ColSectionProps {
  section: Seeds1ColLayoutSection;
}
const Seeds1ColSection = ({section}: Seeds1ColSectionProps) => {
  // Setup the attributes.
  const sectionAttributes = convertAttributes(
    section.layout_settings.section_attributes,
    {className: "layout-section"},
    convertLayoutFieldAttributes(section.layout_settings.layout_fields_attributes),
  );

  const columnParentAttributes = convertAttributes(section.layout_settings.columns_parent_attributes);
  const contentAttributes = convertAttributes(section.layout_settings.content_attributes);
  // Render the section.
  return (
    <section {...sectionAttributes}>
      <div className={!section.layout_settings.container ? "no-container" : "container"}>
        <div {...columnParentAttributes}>
          <div {...contentAttributes}>{renderComponents("content", section)}</div>
        </div>
      </div>
    </section>
  );
};

// Seeds2col section component.
interface Seeds2ColSectionProps {
  section: Seeds2ColLayoutSection;
}
const Seeds2ColSection = ({section}: Seeds2ColSectionProps) => {
  // Setup the attributes.
  const sectionAttributes = convertAttributes(
    section.layout_settings.section_attributes,
    {className: "layout-section"},
    convertLayoutFieldAttributes(section.layout_settings.layout_fields_attributes),
  );
  const columnParentAttributes = convertAttributes(section.layout_settings.columns_parent_attributes);
  const leftclassNamees = section.layout_settings.left_column?.join(" ");
  const rightclassNamees = section.layout_settings.right_column?.join(" ");
  const leftAttributes = convertAttributes(section.layout_settings.left_attributes, {className: leftclassNamees});
  const rightAttributes = convertAttributes(section.layout_settings.right_attributes, {className: rightclassNamees});

  return (
    <section {...sectionAttributes}>
      <div className={`${!section.layout_settings.container ?? "container"} mx-auto`}>
        <div {...columnParentAttributes}>
          <div {...leftAttributes}>{renderComponents("left", section)}</div>
          <div {...rightAttributes}>{renderComponents("right", section)}</div>
        </div>
      </div>
    </section>
  );
};

// Seeds3col section component.
interface Seeds3ColSectionProps {
  section: Seeds3ColLayoutSection;
}
const Seeds3ColSection = ({section}: Seeds3ColSectionProps) => {
  // Setup the attributes.
  const sectionAttributes = convertAttributes(
    section.layout_settings.section_attributes,
    {className: "layout-section"},
    convertLayoutFieldAttributes(section.layout_settings.layout_fields_attributes),
  );
  const columnParentAttributes = convertAttributes(section.layout_settings.columns_parent_attributes);
  const leftclassNamees = section.layout_settings.left_column.join(" ");
  const rightclassNamees = section.layout_settings.right_column.join(" ");
  const centerclassNamees = section.layout_settings.center_column.join(" ");
  const leftAttributes = convertAttributes(section.layout_settings.left_attributes, {className: leftclassNamees});
  const rightAttributes = convertAttributes(section.layout_settings.right_attributes, {className: rightclassNamees});
  const centerAttributes = convertAttributes(section.layout_settings.center_attributes, {className: centerclassNamees});

  return (
    <section {...sectionAttributes}>
      <div className={`${!section.layout_settings.container ?? "container"} mx-auto`}>
        {" "}
        <div {...columnParentAttributes}>
          <div {...leftAttributes}>{renderComponents("left", section)}</div>
          <div {...centerAttributes}>{renderComponents("center", section)}</div>
          <div {...rightAttributes}>{renderComponents("right", section)}</div>
        </div>
      </div>
    </section>
  );
};

export default Section;
