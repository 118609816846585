import classNames from "classnames";
import React from "react";
type Props = {
  children: any;
  className?: string;
  reverse?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const LargeRightTeaser = ({children, className, reverse = false}: Props) => {
  return (
    <article
      className={classNames(
        className,
        "shadow-teaser relative border-solid border flex-wrap border-[#E4E7EB] rounded-[8px] flex",
        reverse ? "flex-row-reverse" : "flex-row",
      )}
    >
      {children}
    </article>
  );
};

type HeaderProps = {
  children: any;
  className?: string;
  pattern?: boolean;
  reversePattern?: boolean;
};

const Header = ({children, className, pattern = false, reversePattern = false}: HeaderProps) => {
  return (
    <div
      className={classNames(
        "relative",
        pattern && " large-right-teaser-pattern",
        reversePattern && "reverse-pattern",
        className,
      )}
    >
      {children}
    </div>
  );
};

const Body = ({children, className}: Props) => {
  return (
    <div
      className={classNames(
        "flex-1 max-tablet:flex-[100%] py-[16px] px-[24px] flex justify-between flex-col",
        className,
      )}
    >
      {children}
    </div>
  );
};

const Footer = ({children, className}: Props) => {
  return <div className={className}>{children}</div>;
};

LargeRightTeaser.Header = Header;
LargeRightTeaser.Body = Body;
LargeRightTeaser.Footer = Footer;

export default LargeRightTeaser;
