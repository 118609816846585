import {Icon} from "@components/elements";
import {BreadcrumbFragment} from "@type/general";
import {map} from "lodash";
import Link from "next/link";
import ChevronLeft from "@icons/chevron-left.svg";
import classNames from "classnames";
import {getLangcode} from "@lib/translations-provider";
type Props = {
  breadcrumbs: BreadcrumbFragment[];
  type?: string[];
};

const Breadcrumbs = ({breadcrumbs, type}: Props) => {
  return (
    <div className="text-sm">
      <ul className="flex items-center gap-1 max-mobile:flex-wrap">
        {map(breadcrumbs, (frag, i) => {
          return (
            <li key={i}>
              <Link
                aria-label={frag.text}
                className={classNames(`rfs-20 flex items-center text-primary`)}
                href={frag.front ? "/" : frag.url}
                key={frag.url}
              >
                {frag.text}
                {i != breadcrumbs.length - 1 && (
                  <Icon
                    icon={ChevronLeft}
                    className={classNames(
                      "inline-block w-4 h-4 max-laptop:w-3 max-laptop:h-3  ml-1 stroke-primary",
                      getLangcode() === "en" ? "transform rotate-180" : "",
                    )}
                  />
                )}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
