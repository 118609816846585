'use client';
import { SkeletonLoaderConfig } from "@components/views/news/homepage/skeleton";
import {ViewDisplayComponent} from "@type/layout";
import {useEffect, useState} from "react";

export const loadConfig = async (
  viewId: string,
  displayId: string,
): Promise<Pick<ViewDisplayComponent, "params" | "props"> | null> => {
  try {
    // Use dynamic import to load the config file
    const configModule = await import(`@components/views/${viewId}/${displayId}/config`);

    // Assuming the config is exported as a default export in the module
    return configModule.default;
  } catch (error) {
    return null;
  }
};

const loadComponent = async (
  viewId: string,
  displayId: string,
): Promise<Omit<ViewDisplayComponent, "props" | "params"> | null> => {
  try {
    // Use dynamic import to load the config file
    const configModule = await import(`@components/views/${viewId}/${displayId}/component`);

    // Assuming the config is exported as a default export in the module
    return configModule.default;
  } catch (error) {
    return null;
  }
};
const loadSkeleton = async (
  viewId: string,
  displayId: string,
): Promise<Omit<ViewDisplayComponent, "props" | "params"> | null> => {
  try {
    // Use dynamic import to load the config file
    const configModule = await import(`@components/views/${viewId}/${displayId}/skeleton`);
    // Assuming the config is exported as a default export in the module
    return configModule.default;
  } catch (error) {
    return null;
  }
};
const viewComponent = (viewId: string, displayId: string) => {
  const [config, setConfig] = useState<SkeletonLoaderConfig>({});

  useEffect(() => {
    loadComponent(viewId, displayId).then((configObject) => {
      setConfig(configObject as SkeletonLoaderConfig ?? {});
    });
  }, [viewId, displayId]);

  return config;
};
const viewSkeleton = (viewId: string, displayId: string) => {
  const [config, setConfig] = useState<SkeletonLoaderConfig>({});

  useEffect(() => {
    loadSkeleton(viewId, displayId).then((configObject) => {
      setConfig(configObject as SkeletonLoaderConfig ?? {});
    });
  }, [viewId, displayId]);

  return config;
};

const viewConfig = (viewId: string, displayId: string) => {
  const [config, setConfig] = useState<Pick<ViewDisplayComponent, "props" | "params">>({});

  useEffect(() => {
    loadConfig(viewId, displayId).then((configObject) => {
      setConfig(configObject ?? {});
    });
  }, [viewId, displayId]);

  return config;
};

export {viewConfig, viewComponent, viewSkeleton};
