import {urlToFile} from "@lib/api";
import {DrupalMediaRemoteVideo, DrupalMediaVideo} from "@type/entity";
import classNames from "classnames";
import React from "react";

type Props = {
  video: DrupalMediaVideo | DrupalMediaRemoteVideo;
  className?: string;
  controls?: boolean;
  muted?: boolean;
};

const handleYoutubeLink = (src: string) => {
  if (src.includes("youtu.be")) {
    const substring = src.substring(src.search("youtu.be/") + 9);
    src = `https://www.youtube.com/embed/${substring}`;
  } else {
    src = src.replace("watch?v=", "embed/");
    src = src.replace("/v/", "/embed/");
  }

  return src;
};

const Video = React.forwardRef(
  ({video, className, controls = true, muted = false, ...rest}: Props, ref: React.ForwardedRef<HTMLVideoElement>) => {
    let src;

    if (!video) {
      return null;
    }

    if (video.type === "media--remote_video") {
      src = handleYoutubeLink(video.field_media_oembed_video);
      return (
        <iframe
          {...rest}
          className={classNames("m-auto 2k:w-full max-w-full h-[650px] max-tablet:h-[300px]", className)}
          width="1070"
          src={src}
        ></iframe>
      );
    } else {
      src = urlToFile(video.field_media_video_file.uri.url);
      return (
        <video
          className={classNames("m-auto max-w-full 2k:w-full h-[650px] max-laptop:h-[auto] max-mobile:h-[444px]", className)}
          width="1070"
          ref={ref}
          controls={controls}
          loop
          autoPlay
          muted={muted}
          height="auto"
          playsInline
        >
          <source {...rest} src={src} type="video/mp4" />
        </video>
      );
    }
  },
);

export default Video;
