import React, {useState, useEffect} from "react";
import {useRating} from "@lib/hooks";
import {t} from "@lib/translations-provider";
import {Button, Icon} from "@components/elements";
import Happy from "@images/icons/happy.svg";
import Sad from "@images/icons/sad.svg";
import classNames from "classnames";
import RatingResult from "./RatingResult";
import Checkbox from "@components/form/CheckBox";
import {FormProvider, useForm} from "react-hook-form";
import {useRouter} from "next/router";

const feedbackAcceptAnswer = [
  {
    label: "It was useful",
    value: "it_was_useful",
  },
  {
    label: "The answers were related",
    value: "the_answers_were_related",
  },
  {
    label: "Well written",
    value: "well_written",
  },
  {
    label: "The design makes it easy to read",
    value: "the_design_makes_it_easy_to_read",
  },
  {
    label: "Other",
    value: "other",
  },
];
const feedbackNotAcceptAnswer = [
  {
    label: "There is a technical problem",
    value: "there_is_a_technical_problem",
  },
  {
    label: "couldn't find a related answer",
    value: "could_not_find_a_related_answer",
  },
  {
    label: "Poorly written",
    value: "poorly_written",
  },
  {
    label: "The design made it hard to read",
    value: "the_design_made_it_hard_to_read",
  },
  {
    label: "Other",
    value: "other",
  },
];

const RatingUseful = () => {
  const {rating, rate, isLoading} = useRating();
  const [usefulValue, setUsefulValue] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [feedbackYes, setFeedbackYes] = useState<boolean>(false);
  const [feedbackNo, setFeedbackNo] = useState<boolean>(false);
  const [checkLimit, setCheckLimit] = useState<number>(0);
  const [checkValue, setCheckValue] = useState<Record<string, boolean>>({});
  const [submitMessage, setSubmitMessage] = useState<boolean | undefined>(false);
  const usefullMethods = useForm();
  const notUseMethods = useForm();
  const {asPath} = useRouter();
  useEffect(() => {
    if (!isLoading && rating) {
      setUsefulValue(rating.useful.value);
      setTotal(rating.useful.total);
    }
  }, [isLoading]);

  // Close modal if the path Changed.
  useEffect(() => {
    setFeedbackYes(false);
    setFeedbackNo(false);
    setSubmitMessage(false);
  }, [asPath]);

  const handleUsefulSubmit = (data: any) => {
    const usefullFilterList: any = [];
    const vote = data["useful_vote"] || data["notuseful_vote"];
    for (const [key, value] of Object.entries(data)) {
      if (value !== false && key !== "other_text" && key !== "useful_vote" && key !== "notuseful_vote") {
        usefullFilterList.push(value);
      }
    }
    rate("useful", vote, usefullFilterList, data["other_text"]);
    setSubmitMessage(true);
  };
  const handleCheckLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target;
    const updatedCheckedItems = {...checkValue, [value]: checked};

    setCheckValue(updatedCheckedItems);

    if (checked) {
      setCheckLimit(checkLimit + 1);
    } else {
      setCheckLimit(checkLimit - 1);
    }
  };

  const handleFeedbackYesVisible = () => {
    const feedYes = feedbackYes;
    setFeedbackYes(!feedYes);
    setFeedbackNo(false);
    setCheckValue({});
    setCheckLimit(0);
    usefullMethods.setValue("it_was_useful", false);
    usefullMethods.setValue("the_answers_were_related", false);
    usefullMethods.setValue("other", false);
    usefullMethods.setValue("well_written", false);
    usefullMethods.setValue("the_design_makes_it_easy_to_read", false);
  };
  const handleFeedbackNoVisible = () => {
    const feedNo = feedbackNo;
    setFeedbackNo(!feedNo);
    setFeedbackYes(false);
    setCheckValue({});
    setCheckLimit(0);
    notUseMethods.setValue("there_is_a_technical_problem", false);
    notUseMethods.setValue("could_not_find_a_related_answer", false);
    notUseMethods.setValue("other", false);
    notUseMethods.setValue("poorly_written", false);
    notUseMethods.setValue("the_design_made_it_hard_to_read", false);
  };
  const checkValueSubmit = () => {
    const values: boolean[] = [];
    Object.values(checkValue).map((element) => {
      if (element === true) {
        values.push(element);
      }
    });
    return values.every((element) => !element);
  };
  return (
    <div className="flex justify-between items-start flex-wrap py-[40px] border-t border-blue-main">
      {submitMessage ? (
        <h2>{t("Thank you for your feedback, Submit successfully")}</h2>
      ) : rating?.useful.user_has_voted ? (
        <h2>{t("You have already provided feedback")}</h2>
      ) : (
        <div>
          <div className="flex gap-3 flex-row flex-wrap items-center">
            <p>{t("Did you found this content useful?")}</p>
            <div className="flex gap-3 my-4">
              <Button
                aria-label="Usefull"
                variant="primary-outline"
                onClick={handleFeedbackYesVisible}
                className={classNames(
                  "flex items-center justify-center group hover:bg-primary hover:text-white hover:border-primary  text-[20px] font-medium gap-[4px] px-[16px] py-[4px]",
                  feedbackYes && "!bg-primary !text-white !border-primary",
                )}
              >
                {t("Useful")}
                <Icon
                  icon={Happy}
                  className={classNames(
                    "group-hover:[&>*]:stroke-white w-[18px] h-[18px]",
                    feedbackYes && "[&>*]:!stroke-white",
                  )}
                />
              </Button>
              <Button
                aria-label="Not useful"
                variant="primary-outline"
                onClick={handleFeedbackNoVisible}
                className={classNames(
                  "flex items-center group  justify-center hover:bg-primary hover:text-white hover:border-primary text-[20px] font-medium gap-[4px]  px-[16px] py-[4px]",
                  feedbackNo && "!bg-primary !text-white !border-primary",
                )}
              >
                {t("Not Useful")}
                <Icon
                  icon={Sad}
                  className={classNames(
                    "group-hover:[&>*]:fill-white w-[18px] h-[18px]",
                    feedbackNo && "[&>*]:!fill-white",
                  )}
                />
              </Button>
            </div>
          </div>
          <div className={classNames("w-full", feedbackYes || feedbackNo ? "block" : "hidden")}>
            <p>
              {t("Add a reason")} <span>{t("( Two options max )")}</span>
            </p>
            <FormProvider {...usefullMethods}>
              <form
                onSubmit={usefullMethods.handleSubmit(handleUsefulSubmit)}
                className={classNames("feedback-yes", feedbackYes ? "block" : "hidden")}
              >
                {feedbackAcceptAnswer.map((element, index) => (
                  <Checkbox
                    key={index}
                    {...usefullMethods.register(element.value, {
                      onChange(event) {
                        handleCheckLimit(event);
                      },
                    })}
                    label={t(element.label)}
                    value={element.value}
                    checked={checkValue[element.value]}
                    disabled={!checkValue[element.value] && checkLimit === 2 ? true : false}
                    is={element.value === "other" ? "other_text" : undefined}
                  />
                ))}
                <input {...usefullMethods.register("useful_vote")} value="1" type="hidden" />

                <div className="py-4 gap-2">
                  <Button aria-label="Send" type="submit" disabled={Object.keys(checkValue).length === 0 || checkValueSubmit()}>
                    {t("Send")}
                  </Button>
                  <Button aria-label="Reset" type="reset" onClick={() => setFeedbackYes(false)} variant="primary-outline">
                    {t("Cancel")}
                  </Button>
                </div>
              </form>
            </FormProvider>

            <FormProvider {...notUseMethods}>
              <form
                onSubmit={notUseMethods.handleSubmit(handleUsefulSubmit)}
                className={classNames("feedback-no", feedbackNo ? "block" : "hidden")}
              >
                {feedbackNotAcceptAnswer.map((element, index) => (
                  <Checkbox
                    key={index}
                    {...notUseMethods.register(element.value, {
                      onChange(event) {
                        handleCheckLimit(event);
                      },
                    })}
                    label={t(element.label)}
                    value={element.value}
                    checked={checkValue[element.value]}
                    disabled={!checkValue[element.value] && checkLimit === 2 ? true : false}
                    is={element.value === "other" ? "other_text" : undefined}
                  />
                ))}
                <input {...notUseMethods.register("notuseful_vote")} value="-1" type="hidden" />
                <div className="py-4 gap-2">
                  <Button type="submit" disabled={Object.keys(checkValue).length === 0 || checkValueSubmit()}>
                    {t("Send")}
                  </Button>
                  <Button type="reset" onClick={() => setFeedbackNo(false)} variant="primary-outline">
                    {t("Cancel")}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      )}
      <RatingResult values={usefulValue} totals={total} />
    </div>
  );
};

export default RatingUseful;
