import {Heading, Icon} from "@components/elements";
import {t} from "@lib/translations-provider";
import React, {useEffect, useState} from "react";
import Colored from "@icons/Colored.svg";
import Gray from "@icons/Gray.svg";
import classNames from "classnames";
type GrayScaleProps = {};

const GrayScale = (props: GrayScaleProps) => {
  const [darkModeClass, setDarkModeClass] = useState(false);

  const handleAddDarkMode = () => {
    const html = document.getElementById("html-id");
    html?.classList.add("html-gray-filter");
    localStorage.setItem("darkMode", "html-gray-filter");
    setDarkModeClass(true);
  };
  const handleRemoveDarkMode = () => {
    const html = document.getElementById("html-id");
    html?.classList.remove("html-gray-filter");
    localStorage.removeItem("darkMode");
    setDarkModeClass(false);
  };

  useEffect(() => {
    if (localStorage.getItem("darkMode") === "html-gray-filter") {
      const html = document.getElementById("html-id");
      html?.classList.add("html-gray-filter");
      setDarkModeClass(true);
    }
  }, []);
  return (
    <div>
      <Heading type="small" color="text-primary">
        {t("The appearance")}
      </Heading>
      <div className="mt-4 py-2 flex-col flex gap-3">
        <div
          onClick={handleRemoveDarkMode}
          className={classNames(
            "cursor-pointer group hover:bg-primary p-3 flex flex-row gap-3 items-center ltr:flex-row",
            !darkModeClass && "bg-primary",
          )}
        >
          <Icon icon={Colored} />
          <span
            className={classNames(
              "text-primary group-hover:text-white text-[24px] font-[500]",
              !darkModeClass && "text-white",
            )}
          >
            {t("Colored Scale")}
          </span>
        </div>
        <div
          onClick={handleAddDarkMode}
          className={classNames(
            "cursor-pointer group p-3 flex flex-row gap-3 hover:bg-primary  items-center  ltr:flex-row",
            darkModeClass && "bg-primary",
          )}
        >
          <Icon icon={Gray} />
          <span
            className={classNames(
              "text-primary group-hover:text-white text-[24px] font-[500]",
              darkModeClass && "text-white",
            )}
          >
            {t("Gray Scale")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GrayScale;
