import {Heading} from "@components/elements";
import {t} from "@lib/translations-provider";
import React, {useContext} from "react";
import classNames from "classnames";
import {ThemeContext} from "@lib/ThemeContext";
type FontSizeProps = {};

const FontSize = (props: FontSizeProps) => {
  const ctx = useContext(ThemeContext);
  let pinned = 0;
  if (ctx.counter) {
    pinned = ctx.counter;
  }
  const handleFontSize = (num: number) => {
    ctx.handleSize(num);
  };
  return (
    <div>
      <Heading type="small" color="text-primary">
        {t("font size")}
      </Heading>
      <div className="mt-4 p-2 flex flex-wrap flex-row">
        <button
          aria-label="Small"
          onClick={() => {
            handleFontSize(-2);
          }}
          className={classNames(
            "font-scale-24 rfs-[24px] rounded-[8px] border-none py-[16.5px] px-[29.5px]",
            pinned === -2 ? "bg-primary text-white" : "bg-white text-primary",
          )}
        >
          {t("Small")}
        </button>
        <button
          aria-label="Medium"
          onClick={() => {
            handleFontSize(0);
          }}
          className={classNames(
            "font-scale-24 rfs-[24px] rounded-[8px] border-none py-[16.5px]  px-[29.5px]",
            pinned === 0 ? "bg-primary text-white" : "bg-white text-primary",
          )}
        >
          {t("Medium")}
        </button>
        <button
          aria-label="Big"
          onClick={() => {
            handleFontSize(2);
          }}
          className={classNames(
            "font-scale-24 rfs-[24px] rounded-[8px] border-none py-[16.5px] px-[29.5px]",
            pinned === 2 ? "bg-primary text-white" : "bg-white text-primary",
          )}
        >
          {t("Big")}
        </button>
        <button
          aria-label="Very big"
          onClick={() => {
            handleFontSize(4);
          }}
          className={classNames(
            "font-scale-24 rfs-[24px] rounded-[8px] border-none py-[16.5px] px-[29.5px]",
            pinned === 4 ? "bg-primary text-white" : "bg-white text-primary",
          )}
        >
          {t("Very Big")}
        </button>
      </div>
    </div>
  );
};

export default FontSize;
