import classNames from "classnames";
import {Icon} from "@components/elements";
import {useRouter} from "next/router";
import {PaginationConfig} from "@type/general";
import ChevronRight from "@icons/chevron-right.svg";
import ChevronLeft from "@icons/chevron-left.svg";
import React from "react";

type Props = {
  props: {
    disableFirstButtons: boolean;
    disableLastButtons: boolean;
    activePage: number;
    totalPages: number;
    onPageChange: (number: number) => void;
    selectedPage: number | string;
    setSelectedPage: (number: number | string) => void;
    pagination: PaginationConfig;
    size: number;
  };
};
const NumberPagination = (props: Props) => {
  const {onPageChange, pagination, size} = props.props;
  const {totalPages, currentPage: activePage} = pagination;
  const router = useRouter();
  const isRtl = router.locale === "ar";
  const disableFirstButtons = activePage == 0;
  const disableLastButtons = activePage >= totalPages - 1;
  const pages = [activePage + 1];

  for (let i = 0; i < size; i++) {
    const prev = pages[0] - 1;
    if (prev > 0) {
      pages.unshift(prev);
    }

    const next = pages[pages.length - 1] + 1;
    if (next < totalPages + 1) {
      pages.push(next);
    }
  }

  const pagesRender = pages.map((page) => {
    const isActive = activePage + 1 == page;
    return (
      <button
        aria-label={`Page ${page}`}
        className={classNames(
          "py-[9px] px-[12px] mx-1 flex-1 justify-center min-w-[48px] text-primary font-normal rounded-lg border border-primary max-w-[48px] max-h-[48px] flex items-center max-tablet:hidden",
          isActive ? "opacity-100" : "opacity-50",
        )}
        key={page}
        onClick={isActive ? undefined : () => onPageChange(page - 1)}
      >
        {page}
      </button>
    );
  });
  return pagination && totalPages > 1 ? (
    <div className="flex-[0_0_50%] flex justify-end items-center  max-laptop:justify-start max-laptop:!ms-0 max-tablet:flex-[2]">
      <div className={classNames("flex max-w-fit translate-x-[-50%] max-laptop:translate-x-0")}>
        <button
          aria-label="Next"
          className={classNames(
            "py-[9px] px-[12px] me-[20px] flex-1 justify-center font-normal rounded-lg border max-w-[48px] min-h-[48px] flex items-center max-desktop:me-[5px]",
            disableFirstButtons ? "border-secondary" : "border-primary",
          )}
          onClick={() => onPageChange(activePage - 1)}
          disabled={disableFirstButtons}
        >
          <Icon
            className={classNames("w-[24px] h-[24px]", disableFirstButtons ? "stroke-secondary" : "stroke-primary")}
            icon={isRtl ? ChevronRight : ChevronLeft}
          />
        </button>
        {pagesRender}
        <button
          aria-label="Prev"
          className={classNames(
            "py-[9px] px-[12px] ms-[20px] flex-1 justify-center font-normal rounded-lg border max-w-[48px]  min-h-[48px] flex items-center max-desktop:ms-[5px]",
            disableLastButtons ? "border-secondary" : "border-primary",
          )}
          onClick={() => onPageChange(activePage + 1)}
          disabled={disableLastButtons}
        >
          <Icon
            className={classNames("w-[24px] h-[24px]", disableLastButtons ? "stroke-secondary" : "stroke-primary")}
            icon={isRtl ? ChevronLeft : ChevronRight}
          />
        </button>
      </div>
    </div>
  ) : null;
};

export default NumberPagination;
