import {useEffect, useState} from "react";
import {SwiperRef} from "swiper/react";
import Button from "../../elements/Button";
import {Icon} from "@components/elements";
import ChevronLeft from "@icons/chevron-left.svg";
import ChevronRight from "@icons/chevron-right.svg";
import {getDirection} from "@lib/translations-provider";
import classNames from "classnames";
type Props = {
  swiperRef?: React.RefObject<SwiperRef>;
  length: number;
} & JSX.IntrinsicElements["div"];

const CustomArrow = (props: Props) => {
  const {swiperRef, length} = props;
  const isRtl = getDirection() === "rtl";
  const swiper = swiperRef?.current?.swiper;
  const [displayed, setDisplayed] = useState(true);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  useEffect(() => {
    swiperRef?.current?.swiper.on("slideChange", (e) => {
      setIsBeginning(e.isBeginning);
      setIsEnd(e.isEnd);
    });
  }, [swiperRef]);
  useEffect(() => {
    if (swiper == null || swiper!.wrapperEl == null) {
      return;
    }

    if (displayed) {
      swiper!.wrapperEl.classList.remove("no-slider");
    } else {
      swiper!.wrapperEl.classList.add("no-slider");
    }
  }, [displayed]);
  useEffect(() => {
    if (length <= 4) {
      setDisplayed(false);
    }
  });
  return displayed ? (
    <div className={classNames("pagenation-wrapper", props.className)}>
      <div className="arrows">
        <Button
          variant="minimal"
          className=" prev-arrow px-[0] py-[0]"
          aria-label="Previous"
          disabled={isBeginning}
        >
          <Icon
            onClick={() => {
              swiper != undefined && swiper!.slidePrev();
            }}
            icon={isRtl ? ChevronRight : ChevronLeft}
            className={classNames("w-[40px] h-[40px]]")}
          />
        </Button>
        <Button
          variant="minimal"
          className="px-[0] py-[0]"
          aria-label="Next"
          disabled={isEnd}
        >
          <Icon
            onClick={() => swiper != undefined && swiper!.slideNext()}
            icon={isRtl ? ChevronLeft : ChevronRight}
            className={classNames(" end-arrow w-[40px] h-[40px")}
          />
        </Button>
      </div>
    </div>
  ) : null;
};

export default CustomArrow;
