import {DrupalMediaImage} from "@type/entity";
import React from "react";
import {Gallery, Item} from "react-photoswipe-gallery";
import {useRouter} from "next/router";
import {ResponsiveImage} from "@components/fields";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

type Props = {
  images: DrupalMediaImage[];
  className?: string;
};

const Photoswipe = ({images, className}: Props) => {
  const isRtl = useRouter().locale == "ar";
  // TODO: There is already a function, remove this.
  const handleImage = (src: string, imageStyleId: string) => {
    return src.substring(src.search("/sites")).replace(`/styles/${imageStyleId}/public`, "");
  };

  return (
    <Gallery
      onOpen={(photoswipe) => {
        photoswipe?.template?.setAttribute("dir", "ltr");

        if (isRtl) {
          photoswipe?.template?.classList.add("flip-arrows");
        } else {
          photoswipe?.template?.classList.remove("flip-arrows");
        }
      }}
    >
      {/* <Masonry columnsCount={4} gutter={'30px'}> */}
      <ResponsiveMasonry columnsCountBreakPoints={{750: 2, 900: 3, 1200: 4}}>
        <Masonry columnsCount={4} gutter={"30px"}>
          {images.map((image, index) => {
            return (
              <Item
                key={image.id}
                id={index}
                original={handleImage(image.field_image.links.scale_width_350?.href || "/", "scale_width_350")}
                width={image.field_image.resourceIdObjMeta?.width}
                height={image.field_image.resourceIdObjMeta?.height}
              >
                {({ref, open}) => (
                  <div ref={ref as React.RefObject<HTMLDivElement>} onClick={open}>
                    <ResponsiveImage
                      hover
                      imageStyle="scale_width"
                      className="cursor-pointer"
                      image={image?.field_image.links}
                      alt={image.field_image.resourceIdObjMeta?.alt}
                    />
                  </div>
                )}
              </Item>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </Gallery>
  );
};

export default Photoswipe;
