import GenericInputElement from "@components/webform-elements/TextElement";
import {WebformElement} from "@type/general";
import React from "react";

type Props = {
  other: WebformElement;
  onChange: (value: string) => void;
  value: string;
  parentName: string;
} & Omit<React.HTMLAttributes<HTMLElement>, "onChange">;

const OtherElement = ({other, onChange, value, parentName, ...rest}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  if (other["#type"] === "textfield" || other["#type"] === "number") {
    return (
      <GenericInputElement
        {...rest}
        webformElement={other}
        onChange={handleChange}
        name={`${parentName}[other]`}
        value={value}
      />
    );
  }

  console.error("OtherElement: Unsupported other type", other["#type"]);
  return null;
};

export default OtherElement;
