import React, {useState, useEffect} from "react";
import {useRating} from "@lib/hooks";
import {t} from "@lib/translations-provider";
import classNames from "classnames";
import StarFilled from "@icons/Star.svg";
import StarUnfilled from "@icons/Star.svg";
import {Icon} from "@components/elements";
export const RatingStarsWithoutAction = () => {
  const {rating, isLoading} = useRating();
  const [starValue, setStarValue] = useState<number>(0);
  const stars = [];
  useEffect(() => {
    if (!isLoading && rating) {
      const value = rating.stars.user_vote_value === 0 ? rating.stars.value : rating.stars.user_vote_value;
      setStarValue(value);
    }
  }, [isLoading]);
  for (let i = 1; i <= 5; i++) {
    if (i <= starValue) {
      stars.push(<Icon icon={StarFilled} className="peer peer-hover:fill-primary" key={i} />);
    } else {
      stars.push(<Icon icon={StarUnfilled} key={i} className="peer peer-hover:fill-primary"/>);
    }
  }
  return (
    <div className="flex items-center">
      <span className="">{rating?.stars.value}</span>
      <div>{stars}</div>
      <span>
        ({t("Rate")} {rating?.stars.total} )
      </span>
    </div>
  );
};

const RatingStars = () => {
  const {rating, rate, isLoading} = useRating();
  const [starValue, setStarValue] = useState<number>(0);
  useEffect(() => {
    if (!isLoading && rating) {
      const value = rating.stars.user_vote_value === 0 ? rating.stars.value : rating.stars.user_vote_value;
      setStarValue(value);
    }
  }, [isLoading]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (event.currentTarget != event.target) return;
    const starValue = parseInt(event.target.getAttribute("data-value"));
    rate("stars", starValue, undefined, undefined);
  };

  const stars = [];
  for (let i = 5; i >= 1; i--) {
    if (i <= starValue) {
      stars.push(
        <a
          href="#"
          data-value={i}
          key={i}
          aria-label={`Page ${i}`}
          onClick={handleSubmit}
          className={classNames(isLoading ? "disable-link" : "[& ~ a>svg]:fill-primary")}
        >
          <Icon icon={StarFilled} className="w-[22px] fill-primary h-[22px] pointer-events-none " />
        </a>,
      );
    } else {
      stars.push(
        <a
          href="#"
          data-value={i}
          aria-label={`Page ${i}`}
          key={i}
          onClick={handleSubmit}
          className={classNames(isLoading ? "disable-link" : "[& ~ a>svg]:fill-primary")}
        >
          <Icon icon={StarUnfilled} className="w-[22px] h-[22px]  pointer-events-none  peer-hover:fill-primary" />
        </a>,
      );
    }
  }
  return (
    <div className="flex gap-2 items-center flex-wrap">
      <p>{t("Page content rate")}</p>
      <div className="flex gap-2 flex-row-reverse">{stars}</div>
      <span>
        {t("({{rate}} Rating)", {
          rate: rating?.stars.total ?? 0,
        })}
      </span>
    </div>
  );
};

export default RatingStars;
