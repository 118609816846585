import {DatelistWebformElement, GenericControlController} from "@type/general";
import {cloneDeep, forEach, map} from "lodash";
import {useMemo} from "react";
import { t } from "@lib/translations-provider";
import SelectElement from "../SelectElement";
import {DatelistSelectElement, SelectValues} from "./types";
import {Field} from "@components/general";
const DatelistElement = ({
  value,
  onChange,
  webformElement,
}: GenericControlController<DatelistWebformElement, SelectValues>) => {
  const selectElements: DatelistSelectElement[] = useMemo(() => {
    const toCheck: Array<keyof SelectValues> = ["year", "month", "day", "hour", "minute"];
    const selectElements: DatelistSelectElement[] = [];
    forEach(toCheck, (key) => {
      if (webformElement[key]) {
        const newEl = cloneDeep(webformElement[key]) as DatelistSelectElement;
        newEl["#webform_key"] = key;
        selectElements.push(newEl);
      }
    });

    return selectElements;
  }, [webformElement]);

  const selectLabels: Record<keyof SelectValues, string> = useMemo(
    () => ({
      year: t("Year"),
      month: t("Month"),
      day: t("Day"),
      hour: t("Hour"),
      minute: t("Minute"),
    }),
    [],
  );

  /**
   * Handles the change.
   */
  const handleChange = (name: keyof SelectValues, selectValue: string) => {
    const newValues = (value && cloneDeep(value)) || {};
    newValues[name] = selectValue;
    onChange(newValues);
  };

  /**
   * Gets the safe value.
   */
  const getValue = (name: keyof SelectValues) => {
    return (value && value[name]) || "";
  };

  return (
    <div className="flex flex-wrap" >
      <>
        {map(selectElements, (el) => {
          if (!el) {
            return null;
          }

          return (
            <Field className="pe-3">
              <SelectElement
                className="border-light-border text-blue-300"
                key={el["#webform_key"]}
                webformElement={el}
                value={getValue(el["#webform_key"])}
                name={el["#webform_key"]}
                onChange={(e) => handleChange(el["#webform_key"], e.target.value)}
                emptyLabel={selectLabels[el["#webform_key"]]}
              />
            </Field>
          );
        })}
      </>
    </div>
  );
};

export default DatelistElement;
