/**
 * @file This file contains all the functionalities of webform states and conditions.
 */

import {WebformDefaultComponent, WebformDefaultComponents, WebformElement} from "@type/general";
import {WebformField} from "components/hoc/Webform/components/WebformField";
import {forEach, isEmpty, isObject, map, mapValues, omitBy} from "lodash";

/** Base classes and interfaces */
interface FormStateInterface {
  apply: (webformElement: WebformElement) => void;
  disable: (webformElement: WebformElement) => void;
}

export abstract class FormState implements FormStateInterface {
  abstract apply(webformElement: WebformElement): void;
  abstract disable(webformElement: WebformElement): void;
}

interface FormStateConditionInterface {
  isTrue: (value: any, conditionValue: any) => boolean;
}

export abstract class FormStateCondition implements FormStateConditionInterface {
  abstract isTrue(value: any, conditionValue: any): boolean;
}

/** States classes */
export class VisibleState extends FormState {
  apply(webformElement: WebformElement): void {
    webformElement["#access"] = true;
  }
  disable(webformElement: WebformElement): void {
    webformElement["#access"] = false;
  }
}

export class HiddenState extends FormState {
  apply(webformElement: WebformElement): void {
    webformElement["#access"] = false;
  }
  disable(webformElement: WebformElement): void {
    webformElement["#access"] = true;
  }
}

export class EnabledState extends FormState {
  apply(webformElement: WebformElement): void {
    webformElement["#disabled"] = false;
  }

  disable(webformElement: WebformElement): void {
    webformElement["#disabled"] = true;
  }
}

export class DisabledState extends FormState {
  apply(webformElement: WebformElement): void {
    webformElement["#disabled"] = true;
  }

  disable(webformElement: WebformElement): void {
    webformElement["#disabled"] = false;
  }
}

export class RequiredState extends FormState {
  apply(webformElement: WebformElement): void {
    webformElement["#required"] = true;
  }

  disable(webformElement: WebformElement): void {
    webformElement["#required"] = false;
  }
}

export class OptionalState extends FormState {
  apply(webformElement: WebformElement): void {
    webformElement["#required"] = false;
  }

  disable(webformElement: WebformElement): void {
    webformElement["#required"] = true;
  }
}

export class ReadonlyState extends FormState {
  apply(webformElement: WebformElement): void {
    webformElement["#readonly"] = true;
  }

  disable(webformElement: WebformElement): void {
    webformElement["#readonly"] = false;
  }
}

export class ReadWriteState extends FormState {
  apply(webformElement: WebformElement): void {
    webformElement["#readonly"] = false;
  }

  disable(webformElement: WebformElement): void {
    webformElement["#readonly"] = true;
  }
}

/** States Conditions */
export class FilledCondition extends FormStateCondition {
  isTrue(value: any, conditionValue: any): boolean {
    return value !== "" && value !== undefined && value !== null && !isEmpty(value);
  }
}

export class CheckedCondition extends FormStateCondition {
  isTrue(value: any, conditionValue: any): boolean {
    return !!value;
  }
}

export class UncheckedCondition extends FormStateCondition {
  isTrue(value: any, conditionValue: any): boolean {
    return !value;
  }
}

export class ValueCondition extends FormStateCondition {
  isTrue(value: any, conditionValue: any): boolean {
    return value === conditionValue;
  }
}

export class NotValueCondition extends FormStateCondition {
  isTrue(value: any, conditionValue: any): boolean {
    return value !== conditionValue;
  }
}

export class EmptyCondition extends FormStateCondition {
  isTrue(value: any, conditionValue: any): boolean {
    return isEmpty(value);
  }
}

/** List of supported states and conditions */
export const WEBFORM_STATES: Record<string, FormStateInterface | undefined> = {
  visible: new VisibleState(),
  hidden: new HiddenState(),
  enabled: new EnabledState(),
  disabled: new DisabledState(),
  required: new RequiredState(),
  optional: new OptionalState(),
  readonly: new ReadonlyState(),
  readwrite: new ReadWriteState(),
};

export const WEBFORM_CONDITIONS: Record<string, FormStateConditionInterface | undefined> = {
  "filled": new FilledCondition(),
  "checked": new CheckedCondition(),
  "unchecked": new UncheckedCondition(),
  "value": new ValueCondition(),
  "!value": new NotValueCondition(),
  "empty": new EmptyCondition(),
};

export const foreachElements = (elements: Record<string, WebformElement | unknown>) => {
  const filteredElements = omitBy(elements, (el, key) => {
    return key.substring(0, 1) === "#";
  }) as Record<string, WebformElement>;
  return map(filteredElements, (element, name) => {
    return <WebformField element={element} key={name} name={name} />;
  });
};

export const flattenElements = (elements: Record<string, any>): Record<string, WebformElement> => {
  const flattenedElements: Record<string, WebformElement> = {};

  forEach(elements, (element, name) => {
    if (!isWebformElement(element)) {
      return;
    }

    flattenedElements[name] = element;
    const subFlattenedElements = flattenElements(element);
    forEach(subFlattenedElements, (subElement, subName) => {
      flattenedElements[subName] = subElement;
    });
  });

  return flattenedElements;
};

export const hasAccessToElement = (element: WebformElement) => {
  return !(typeof element["#access"] === "boolean" && element["#access"] === false);
};

export const isWebformElement = (obj: any): obj is WebformElement => {
  return isObject(obj) && "#type" in obj;
};

export const normalizeValues = async (
  values: Record<string, any>,
  flattenedElements: Record<string, WebformElement>,
  components: WebformDefaultComponents,
) => {
  const {WEBFORM_FIELDS_COMPONENTS} = await import("@configuration/webform");
  const ALL_COMPONENTS = {...WEBFORM_FIELDS_COMPONENTS, ...components};
  return mapValues(values, (value, name) => {
    if (name === "captcha") {
      return value;
    }
    const element = flattenedElements[name];
    const fieldComponent = ALL_COMPONENTS[element["#type"]] as WebformDefaultComponent | undefined;
    if (element && fieldComponent && fieldComponent.type === "controller" && fieldComponent.normalizeFunction) {
      return fieldComponent.normalizeFunction(value);
    }

    return value;
  });
};

export const applyStatesAndConditions = (
  elements: Record<string, any>,
  flattenedElements: Record<string, WebformElement>,
  watch: (name: string) => any,
) => {
  forEach(elements, (element) => {
    if (!isWebformElement(element)) {
      return;
    }

    const states = element["#states"];

    // If it is a container, reapply state for all the elements inside.
    applyStatesAndConditions(element, flattenedElements, watch);

    if (!states) {
      return;
    }

    forEach(states, (selectors, stateName) => {
      // Check if the state is defined.
      const stateObject = WEBFORM_STATES[stateName];
      if (!stateObject) {
        return;
      }

      forEach(selectors, (conditions, selectorString) => {
        // Get the name form the selector string.
        const match = /name="([^"]+)"/g.exec(selectorString);
        if (!match) {
          return;
        }
        const isArrayValue = /\[(.*?)\]/.exec(match[1]);
        const fieldName = match[1];
        let fieldValue = watch(fieldName);
        if (isArrayValue) {
          fieldValue = fieldValue ? fieldValue : watch(fieldName.replace(isArrayValue[0], ""));
        }
        // Test the conditions.
        forEach(conditions, (conditionValue, conditionString) => {
          // Check if the condition object is defined.
          const conditionObject = WEBFORM_CONDITIONS[conditionString];
          if (!conditionObject) {
            return null;
          }
          if (isArrayValue && fieldValue instanceof Array && fieldValue.includes(isArrayValue[1])) {
            stateObject.apply(element);
            return;
          } else {
            if (
              conditionValue &&
              conditionObject.isTrue(fieldValue, conditionValue) &&
              flattenedElements[fieldName] &&
              hasAccessToElement(flattenedElements[fieldName])
            ) {
              stateObject.apply(element);
            } else {
              stateObject.disable(element);
            }
          }
        });
      });
    });
  });
};

export const getChildrenKeys = (webformElement: WebformElement) => {
  const childrenKeys: string[] = [];
  forEach(webformElement, (value, key) => {
    if (key.substring(0, 1) !== "#") {
      childrenKeys.push(key);
    }
  });

  return childrenKeys;
};
