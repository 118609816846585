import {Field} from "@components/general";
import {EmailConfirmValue, EmailConfirmWebformElement, GenericControlController} from "@type/general";
import React, {useEffect, useMemo, useState} from "react";
import {FormControl} from "react-bootstrap";

const EmailConfirmElement = ({
  webformElement,
  value,
  onChange,
  name,
}: GenericControlController<EmailConfirmWebformElement>) => {
  // Two states for the two email fields.
  const [email, setEmail] = useState<string>("");
  const [confirm, setConfirmEmail] = useState<string>("");
  const actualValue: EmailConfirmValue = useMemo(() => {
    if (value) {
      return value;
    } else {
      return {
        email: "",
        confirm: "",
      };
    }
  }, [value]);
  if (!webformElement.mail_1 || !webformElement.mail_2) {
    console.error("EmailConfirmElement: Missing mail_1 or mail_2");
    return null;
  }

  /**
   * Creates a helper wrapper for the email fields.
   */
  const handleChangeWrapper = (func: (str: string) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      func(e.target.value);
    };
  };

  /**
   * Sends the actual value to the webform.
   */
  useEffect(() => {
    onChange({
      email,
      confirm,
    });
  }, [email, confirm]);

  return (
    <>
      <Field label={webformElement.mail_1["#title"]}>
        <FormControl
          type="text"
          value={actualValue.email}
          name={`${name}[email]`}
          onChange={handleChangeWrapper(setEmail)}
        />
      </Field>
      <Field label={webformElement.mail_2["#title"] || "Confirm Email"}>
        <FormControl
          value={actualValue.confirm}
          name={`${name}[confirm]`}
          onChange={handleChangeWrapper(setConfirmEmail)}
          type="text"
        />
      </Field>
    </>
  );
};

export default EmailConfirmElement;
