import classNames from "classnames";
import React, {PropsWithChildren} from "react";

type SmallTeaserProps = {
  className?: string;
  pattern?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

type SmallTeaserHeaderProps = PropsWithChildren<{}> & React.HTMLAttributes<HTMLDivElement>;

const SmallTeaserHeader = (props: PropsWithChildren<SmallTeaserHeaderProps>) => {
  const {children} = props;
  return <div>{children}</div>;
};

const SmallTeaserBody = (props: PropsWithChildren<SmallTeaserProps> & React.HTMLAttributes<HTMLDivElement>) => {
  const {children, className, pattern, ...rest} = props;
  return (
    <div
      className={classNames(
        pattern && "body-small-left-teaser-pattern ",
        "flex flex-col h-full mt-14 pb-9 text-center items-center justify-between w-full",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
const SmallTeaserFooter = (props: PropsWithChildren<{}> & React.HTMLAttributes<HTMLDivElement>) => {
  const {children, className, ...rest} = props;
  return (
    <div
      className={classNames("flex flex-col h-full mt-14 pb-9 text-center items-center justify-between", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

const SmallTeaser = (props: PropsWithChildren<SmallTeaserProps>) => {
  const {children, className, pattern, ...rest} = props;
  return (
    <div
      className={classNames(
        " relative bg-white rounded-lg overflow-hidden shadow-main-menu-fixed border-[1px] border-light-border",
        className,
      )}
      {...rest}
    >
      <div className={classNames("p-5 h-full", pattern && "small-left-teaser-pattern", className)}>
        <div className="flex items-center flex-col h-full justify-between w-full">{children}</div>
      </div>
    </div>
  );
};

SmallTeaser.Header = SmallTeaserHeader;
SmallTeaser.Body = SmallTeaserBody;
SmallTeaser.Footer = SmallTeaserFooter;

export default SmallTeaser;
