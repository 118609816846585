import React from "react";
import {Icon} from "@components/elements";
import X from "@icons/x.svg";
import Linkedin from "@icons/linkedin.svg";
import Facebook from "@icons/facebook.svg";
import Youtube from "@icons/youtube.svg";
import Instagram from "@icons/instagram.svg";
import Snapchat from "@icons/Snapchat.svg";
import {DrupalMenuLinkContent} from "next-drupal";
import map from "lodash/map";
import classNames from "classnames";
import Link from "next/link";

interface SocialMenuProps {
  socialMenu: DrupalMenuLinkContent[];
  variant?: "blue" | "white";
}
const SocialMenu = ({socialMenu, variant}: SocialMenuProps) => {
  let classes = "";
  switch (variant) {
    case "blue":
      classes = "gap-[12px]";
      break;
    case "white":
      classes = "gap-[32px]";
      break;
  }
  return (
    <div className={classNames("flex max-laptop:hidden", classes)}>
      {map(socialMenu, (item) => {
        let icon;
        switch (item.title.toLowerCase()) {
          case "facebook":
            icon = Facebook;
            break;
          case "twitter":
            icon = X;
            break;
          case "x":
            icon = X;
            break;
          case "instagram":
            icon = Instagram;
            break;
          case "snapchat":
            icon = Snapchat;
            break;
          case "linkedin":
            icon = Linkedin;
            break;
          case "youtube":
            icon = Youtube;
            break;
        }
        return (
          <Link
            prefetch={false}
            aria-label={item.title.toLowerCase()}
            className="p-[0px!important] m-0"
            href={item.url}
            target="_black"
            key={item.id}
          >
            <Icon
              className={classNames(
                variant == "blue" ? "[&>*]:fill-blue" : "[&>*]:fill-white",
                "w-[32px] max-mobile:w-[20px] h-[32px]",
              )}
              icon={icon}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default SocialMenu;
