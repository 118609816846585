import {FieldFormattedBody} from "@type/fields";
import classNames from "classnames";
import {HTMLAttributes} from "react";
import striptags from "striptags";

type PropsWithField = {
  maxLength?: number;
  body: FieldFormattedBody;
  children?: undefined;
} & Omit<HTMLAttributes<HTMLDivElement>, "children">;

type PropsWithChildren = {
  maxLength?: number;
  body?: undefined;
  children?: string;
} & Omit<HTMLAttributes<HTMLDivElement>, "children">;

type Props = PropsWithField | PropsWithChildren;

const Summary = ({maxLength = 270, children, body, className, ...rest}: Props) => {
  let suffix = "";
  const regex = /&(nbsp|amp|quot|lt|gt);/g;

  if (typeof body !== "undefined") {
    if (!body) return null;
    if (body.summary) {
      if (body.summary.length > maxLength) {
        suffix = "...";
      }
      return (
        <div {...rest} className={classNames("font-scale-24", className)}>
          {striptags(body.summary).slice(0, maxLength).replace(regex, " ")} {suffix}
        </div>
      );
    } else {
      if (body.value.length > maxLength) {
        suffix = "...";
      }
      return (
        <div className={classNames("font-scale-24", className)} {...rest}>
          {striptags(body.value).slice(0, maxLength).replace(regex, " ")}
          {suffix}
        </div>
      );
    }
  } else if (children) {
    children = striptags(children);
    if (children && children.length > maxLength) {
      suffix = "...";
    }

    return (
      <div className={classNames("font-scale-24", className)} {...rest}>
        {children && children.slice(0, maxLength).replace(regex, " ")}
        {suffix}
      </div>
    );
  }

  return null;
};

export default Summary;
