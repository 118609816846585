import React from "react";
import {SectionWebformElement, WebformContainerComponent} from "@type/general";

const Section = ({webformElement, children, className}: WebformContainerComponent<SectionWebformElement>) => {
  const Title: any = webformElement["#title_tag"] || "h2";
  return (
    <section className={className}>
      <Title>
        {webformElement["#title"]}
        {webformElement["#required"] ? " *" : ""}
      </Title>
      {children}
    </section>
  );
};

export default Section;
