import classNames from "classnames";
import React from "react";
import Styles from "./subtitle.module.scss";

type VarientType = "blue" | "black" | "green" | "orange" | "red" | "white";

type SubtitleProps = {
  varient: VarientType;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Subtitle = ({children, className, varient}: SubtitleProps) => {
  return <span className={classNames(Styles.label, Styles[varient], className)}>{children}</span>;
};

export default Subtitle;
