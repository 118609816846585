import {DrupalMenuLinkContent} from "next-drupal";
import {getMenuItem} from "@lib/menu-utils";
import Link from "next/link";
import {HTMLAttributes} from "react";
import map from "lodash/map";
import {getLangcode} from "@lib/translations-provider";

type CopyRightMenuProps = {
  menuContent: DrupalMenuLinkContent[];
} & HTMLAttributes<HTMLDivElement>;
const CopyRightMenu = ({menuContent, ...rest}: CopyRightMenuProps) => {
  const menuItems = getMenuItem(menuContent);
  return (
    <div {...rest}>
      <ul className="text-white flex gap-[12px] rtl:[&>li:not(:last-child)]:border-l-[1px] ltr:[&>li:not(:last-child)]:border-r-[1px] ltr:[&>li:not(:last-child)]:pr-[12px] rtl:[&>li:not(:last-child)]:pl-[12px]">
        {map(menuItems, (item) => {
          if (item.url.includes("rss")) {
            return (
              <li key={item.id}>
                <a
                  aria-label="Rss"
                  href={`${item.url.replace(/\/[a-z]{2}/, "")}?langcode=${getLangcode()}` || "/"}
                  target="_blank"
                  rel="noreferrer"
                  aria-labelledby="Rss"
                  className={"text-[#1EADD8]"}
                  key={item.id}
                >
                  {item.title}
                </a>
              </li>
            );
          }
          return (
            <li key={item.id}>
              <Link aria-label={item.title} className="text-[#1EADD8]" href={item.url}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default CopyRightMenu;
