import {Button, Icon} from "@components/elements";
import {getAxiosClient, urlArguments} from "@lib/api";
import {errorMessage, handleError} from "@lib/utils";
import {ManagedFileElement, StatusString, WebformUploadResponse} from "@type/general";
import {INTERNAL_APIS} from "configuration/apis";
import toString from "lodash/toString";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {FormControl} from "react-bootstrap";
import {t} from "@lib/translations-provider";
import {Loader} from "@components/general";
import Folder from "@icons/folder.svg";
import PlusIcon from "@icons/add.svg";
import MinusIcon from "@icons/minus.svg";

type GeneralProps = {
  onChange: (fileId: string) => void;
  webformElement: ManagedFileElement;
  value?: string;
};

const FileUpload = ({onChange, value, webformElement}: GeneralProps) => {
  const [status, setStatus] = useState<StatusString>("ready");
  const fileCache = useRef<WebformUploadResponse | null>();
  const [file, setFile] = useState<WebformUploadResponse | null>();
  const fileInput = useRef<HTMLInputElement>(null);
  const allowedExtensions = useMemo(
    () => webformElement["#upload_validators"].file_validate_extensions[0].split(" "),
    [webformElement["#upload_validators"].file_validate_extensions[0]],
  );
  const isExtensionAllowed = (extension: undefined | string) => {
    return allowedExtensions.some((e) => e === extension);
  };

  /**
   * Handles uplaoding a file.
   */
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, t: (str: string) => string) => {
    // Ignore if no file is uploaded.
    if (!(e.target.files && e.target.files.length > 0)) {
      return;
    }
    setStatus("loading");

    // Post request.
    /* eslint-disable-next-line */
    try {
      const extension = e.target.files[0].name.split(".").pop();
      if (!isExtensionAllowed(extension)) {
        errorMessage(t("This extension is not allowed"));
        setStatus("ready");
        return;
      }

      const name = e.target.files[0].name;
      // before checking the length, we encode it.
      const encoded = encodeURIComponent(name);
      // Max length for name is 255.
      if (encoded.length > 255) {
        errorMessage(t("The file name is too long"));
        setStatus("ready");
        return;
      }

      const res = await getAxiosClient().post<WebformUploadResponse>(
        INTERNAL_APIS.POST.WEBFORM_FILE_UPLOAD,
        {
          file: e.target.files[0],
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            webform: webformElement["#webform"],
            field: webformElement["#webform_key"],
          },
        },
      );
      fileCache.current = res.data;
      onChange(toString(res.data.fid[0].value));
      setStatus("success");
    } catch (e) {
      handleError(e);
      onChange("");
      setStatus("failed");
    } finally {
    }
  };

  useEffect(() => {
    const fetchFile = async () => {
      if (!value) {
        return;
      }

      setStatus("loading");
      try {
        const file = await getAxiosClient().get(urlArguments(INTERNAL_APIS.GET.FILE, [value]));
        setFile(file.data);
        setStatus("success");
      } catch (e) {
        handleError(e);
        setStatus("ready");
      }
    };
    if (value) {
      if (fileCache.current) {
        setFile(fileCache.current);
        fileCache.current = null;
      } else {
        fetchFile();
      }
    } else {
      setFile(null);
    }
  }, [value]);

  const handleRemoveFile = () => {
    setFile(null);
    fileCache.current = null;
  };
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (fileInput.current) {
      e.preventDefault();
      fileInput.current.click();
    }
  };

  // random  id
  const random = Math.random().toString(36).substring(7);

  // TODO: Style and presentation.
  return (
    <div className="border border-light-border py-6 px-4 ">
      {file ? (
        <div className="flex flex-wrap justify-between">
          <div>
            <Icon icon={Folder} className="me-4 w-[32px] h-[32px] inline custom-stroke-blue" />

            <span className={"text-secondary font-400"}>{file.filename[0].value}</span>
          </div>
          <div className="flex max-tablet:mt-4">
            <Button aria-label="Remove filter" variant="primary-outline" className="" onClick={handleRemoveFile}>
              {t("Remove file")}
            </Button>
            <Icon
              onClick={handleRemoveFile as any}
              icon={MinusIcon}
              className="flex cursor-pointer items-center justify-center ms-6 [&>svg]:w-6 [&>svg]:h-6 w-10 h-10 p-2 border-2 border-secondary custom-stroke-blue rounded-full"
            />
          </div>
        </div>
      ) : (
        <Loader contentType="view" keepContent status={status} className="flex flex-wrap justify-between">
          <div className="text-secondary font-normal">
            <Icon icon={Folder} className="me-4 w-[32px] h-[32px] inline custom-stroke-blue" />
            {t("No file selected")}
          </div>
          <div className="flex max-tablet:mt-4">
            <Button aria-label="Upload file" variant="primary-outline" className="" onClick={handleClick}>
              {t("Upload file")}
            </Button>
            <Icon
              onClick={handleClick as any}
              icon={PlusIcon}
              className="flex cursor-pointer items-center justify-center ms-6 [&>svg]:w-6 [&>svg]:h-6 w-10 h-10 p-2 border-2 border-secondary custom-stroke-blue rounded-full"
            />
          </div>

          <FormControl
            ref={fileInput}
            hidden
            id={random}
            type="file"
            className="!hidden"
            onChange={(e) => {
              handleFileUpload(e as React.ChangeEvent<HTMLInputElement>, t);
            }}
          />
        </Loader>
      )}
    </div>
  );
};

export default FileUpload;
