import {LinkProps} from "next/dist/client/link";
import {useRouter} from "next/router";
import React from "react";

type Props = Omit<LinkProps, "href"> & {
  href: string;
};

const OptimizedLink = (props: Props) => {
  const {push} = useRouter();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (props.href && props.href !== "#") push(props.href);
  };

  return <a {...props} onClick={handleClick}></a>;
};

export default OptimizedLink;
