"use client";
import {Heading} from "@components/elements";
import {Loader} from "@components/general";
import {useView} from "@lib/hooks";
import {viewComponent, viewConfig, viewSkeleton} from "@lib/views";
import {BackendViewProps, FrontendViewProps} from "@type/general";
import {LabelDisplay, ViewPartComponentProps} from "@type/layout";
import {DEFAULT_VIEW_DISPLAY_PROPERTIES} from "configuration/views-settings";
import {clone, merge} from "lodash";
import {useMemo} from "react";
import TitlePattern from "@icons/title-pattern.svg";
type ViewProps = {
  label?: string;
  displayLabel?: LabelDisplay;
} & (BackendViewProps | FrontendViewProps);
const View = (props: ViewProps) => {
  const {label, displayLabel} = props;
  const {viewId, displayId, viewArguements} = useMemo(() => {
    let viewId: string;
    let displayId: string;
    let viewArguements: Array<string | number> | undefined;
    if (props.viewId && props.displayId) {
      viewId = props.viewId;
      displayId = props.displayId;
      viewArguements = props.viewArguements;
      return {viewId, displayId, viewArguements};
    } else if (props.extendedViewResults) {
      viewId = props.extendedViewResults.viewId;
      displayId = props.extendedViewResults.displayId;
      viewArguements = props.extendedViewResults.viewArguments;
      return {viewId, displayId, viewArguements};
    }

    throw new Error('Invalid arguments for "View" compoent');
  }, [props]);

  const propsConfig = viewConfig(viewId, displayId);
  const componentConfig = viewComponent(viewId, displayId);
  const allProporties = merge(clone(DEFAULT_VIEW_DISPLAY_PROPERTIES), clone({...propsConfig, ...componentConfig}));
  const skeleton = viewSkeleton(viewId, displayId);
  const {
    data: resources,
    viewData,
    setData: setResources,
    status,
    fetchData,
    setPage,
    setExposedFilters,
    setArgs,
    args,
    setSortBy,
    exposedFilters,
    sortBy,
    pagination,
  } = useView({
    automatic: props.extendedViewResults ? false : true,
    viewId: viewId,
    displayId: displayId,
    defaultValue: props.extendedViewResults,
    defaultPage: 0,
    viewArguements: viewArguements,
    layoutExposedFilters: props.extendedViewResults?.layoutExposedFilters ?? {},
  });

  const RowWrapper = allProporties.rowWrapper;
  const List = allProporties.list;
  const Pager = allProporties.pager || (() => null);
  const Header = allProporties.header;
  const Footer = allProporties.footer;
  const SideExposedFilter = allProporties.sideExposedFilter;
  const ExposedFilter = allProporties.exposedFilter;
  const ViewWrapper = allProporties.viewWrapper;
  const viewComponentAdditonalProps = allProporties ? allProporties.props : {};
  const renderBlockTitle = () => {
    if (displayLabel === "visible" && label) {
      return (
        <Heading className="mb-[30px]" icon={TitlePattern} iconPosition={"right"} element="h2" type="medium">
          {label}
        </Heading>
      );
    }
  };

  // TODO: Handle loadmore.
  return (
    <Loader status={props.noLoader ? false : status} contentType="view" skeleton={skeleton}>
      {() => {
        if (viewData) {
          const viewMode = viewData.meta?.row?.configurations?.view_mode;
          const viewComponentProps: ViewPartComponentProps = {
            displayId,
            viewId,
            viewData,
            meta: viewData?.meta,
            onResourcesChange: setResources,
            resources,
            viewMode,
            fetchData,
            setPage,
            setExposedFilters,
            setArgs,
            args,
            setSortBy,
            exposedFilters,
            sortBy,
            pagination,
            status,
            ...viewComponentAdditonalProps,
          };

          return (
            <>
              {renderBlockTitle()}
              <ViewWrapper
                rowWrapper={RowWrapper}
                list={List}
                pager={Pager}
                exposedFilter={ExposedFilter}
                header={Header}
                footer={Footer}
                viewComponentProps={viewComponentProps}
                sideExposedFilter={SideExposedFilter}
              />
            </>
          );
        } else {
          return null;
        }
      }}
    </Loader>
  );
};

export default View;
