import {AppProps} from "next/app";

import "@formatjs/intl-pluralrules/locale-data/ar";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/polyfill";
import {config} from "@fortawesome/fontawesome-svg-core";
import {SlugProps} from "@type/general";
import moment, {LocaleSpecification} from "moment";
import "moment/locale/ar";
import hijri from "moment-hijri";
import ThemeContextProvider from "@lib/ThemeContext";

import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import {Flip, ToastContainer} from "react-toastify";
import "regenerator-runtime/runtime";
import "photoswipe/dist/photoswipe.css";
import "react-loading-skeleton/dist/skeleton.css";
import "styles/footer.scss";
import "styles/header.scss";
import "styles/style.scss";
import {Loader} from "@components/general";

const symbolMap: Record<string, string> = {
  "1": "١",
  "2": "٢",
  "3": "٣",
  "4": "٤",
  "5": "٥",
  "6": "٦",
  "7": "٧",
  "8": "٨",
  "9": "٩",
  "0": "٠",
};
const numberMap: Record<string, string> = {
  "١": "1",
  "٢": "2",
  "٣": "3",
  "٤": "4",
  "٥": "5",
  "٦": "6",
  "٧": "7",
  "٨": "8",
  "٩": "9",
  "٠": "0",
};
config.autoAddCss = false;
hijri.updateLocale("en", {
  iMonths: [
    "Muharram",
    "Safar",
    "Rabi' al-awwal",
    "Rabi' al-thani",
    "Jumada al-awwal",
    "Jumada al-thani",
    "Rajab",
    "Sha'ban",
    "Ramadan",
    "Shawwal",
    "Dhu al-Qi'dah",
    "Dhu al-Hijjah",
  ],
});
hijri.updateLocale("ar-SA", {
  preparse: (string: string) => {
    return string
      .replace(/[١٢٣٤٥٦٧٨٩٠]/g, (match) => {
        return numberMap[match];
      })
      .replace(/،/g, ",");
  },
  postformat: (string: string) => {
    return string
      .replace(/\d/g, (match: string) => {
        return symbolMap[match];
      })
      .replace(/,/g, "،");
  },
  iMonths: [
    "محرم",
    "صفر",
    "ربيع الأول",
    "ربيع الثاني",
    "جمادى الأول",
    "جمادى الثاني",
    "رجب",
    "شعبان",
    "رمضان",
    "شوال",
    "ذو القعدة",
    "ذو الحجة",
  ],
});
moment.updateLocale("ar-SA", {
  months: [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ],
  relativeTime: {
    s: "ثوان",
    m: "دقيقة",
    mm: "%d دقيقة",
    h: "ساعة",
    hh: "%d ساعات",
    d: "يوم",
    dd: "%d أيام",
    M: "شهر",
    MM: "%d أشهر",
    y: "سنة",
    yy: "%d سنوات",
    past: "منذ %s",
  },
  meridiemParse: /صباحاً|مساءاً/,
  meridiem: (hours, minutes, isLower) => {
    return hours < 12 ? "صباحاً" : "مساءاً";
  },
} as LocaleSpecification);

function App({Component, pageProps}: AppProps<SlugProps>) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  hijri.locale(router.locale == "ar" ? "ar-SA" : router.locale);
  moment.locale(router.locale);

  const onRouteStart = () => {
    setLoading(true);
  };

  const onRouteEnd = () => {
    setLoading(false);

    // // Google analytics.
    // const GA_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
    // if (!GA_ID) {
    //   console.error("GA_ID is not defined");
    //   return;
    // }

    // if (window.ga) {
    //   window.ga("set", "page", router.asPath);
    //   window.ga("send", "pageview");
    // }
  };

  useEffect(() => {
    if (pageProps.resource && pageProps.resource.type) {
      document.querySelector("body")?.removeAttribute("class");
      document.querySelector("body")?.classList.add(pageProps.resource.type);
    }

    router.events.on("routeChangeStart", onRouteStart);
    router.events.on("routeChangeComplete", onRouteEnd);
    return () => {
      router.events.off("routeChangeStart", onRouteStart);
      router.events.off("routeChangeComplete", onRouteEnd);
    };
  }, [router]);

  // useMemo(() => {
  //   router.prefetch = async () => {};
  // }, [router]);
  return (
    <>
      <Loader keepContent status={loading ? "loading" : "success"} contentType="page">
        <ThemeContextProvider>
          <Component {...pageProps} />
        </ThemeContextProvider>
      </Loader>
      <ToastContainer position="bottom-left" transition={Flip} />
    </>
  );
}

// TODO: Use our custom translations.
export default App;
