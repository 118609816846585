/**
 * @file Provides helper functions for layout builder.
 */

import classNames from "classnames";
import {filter, forEach, sortBy} from "lodash";

import {
  CustomBlockComponent,
  LayoutComponent,
  LayoutRegion,
  PluginBlockComponent,
  ViewBlockComponent,
  WebformBlockComponent,
} from "@type/layout";

export const convertAttributes = (
  attributesString: string,
  ...mergedAttributes: Array<Record<string, string>>
): Record<string, string> => {
  const attributes: Record<string, string> = {};
  const attributesSplit = attributesString.split(",");
  attributesSplit.forEach((attributeString) => {
    const attributeFrags = attributeString.split("|");
    if (attributeFrags[0] && attributeFrags[1]) {
      if (attributeFrags[0] == "class") {
        attributeFrags[0] = "className";
      }
      attributes[attributeFrags[0]] = attributeFrags[1];
    }
  });
  forEach(mergedAttributes, (value) => {
    const attributesObject = value;
    forEach(attributesObject, (value, key) => {
      if (key === "class") {
        key = "className";
      }

      const mergedValue = classNames(attributes[key], value);
      attributes[key] = mergedValue;
    });
  });

  return attributes;
};

export const convertLayoutFieldAttributes = (
  attributes: Record<string, string[] | undefined>,
): Record<string, string> => {
  const newAttributes: Record<string, string> = {};

  forEach(attributes, (value, key) => {
    if (value) {
      newAttributes[key] = value.join(" ");
    }
  });

  return newAttributes;
};

export const getComponents = (components: Record<string, LayoutComponent>, region: LayoutRegion): LayoutComponent[] => {
  const filteredComponents = filter(components, (value) => value.region === region);
  return sortBy(filteredComponents, "weight");
};

export const isViewBlock = (component: LayoutComponent): component is ViewBlockComponent => {
  return component.configuration.id.startsWith("views_block");
};

export const isCustomBlock = (component: LayoutComponent): component is CustomBlockComponent => {
  return (
    component.configuration.id.startsWith("inline_block:") || component.configuration.id.startsWith("block_content:")
  );
};

export const isWebformBlock = (component: LayoutComponent): component is WebformBlockComponent => {
  return component.configuration.id === "webform_block";
};

export const isPluginBlock = (component: LayoutComponent): component is PluginBlockComponent => {
  return !isCustomBlock(component) && !isViewBlock(component) && !isWebformBlock(component);
};

/**
 * Gets the viewId and displayId from view block component.
 *
 */
export const getViewAndDisplayFromComponent = (components: ViewBlockComponent): {viewId: string; displayId: string} => {
  const viewAndDisplay = components.configuration.id.split(":")[1];
  const [viewId, displayId] = viewAndDisplay.split("-");
  return {viewId, displayId};
};
