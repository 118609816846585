import {Button, Heading, Icon} from "@components/elements";
import {ThemeContext} from "@lib/ThemeContext";
import React, {useContext, useEffect} from "react";
import Close from "@icons/close.svg";
import {t} from "@lib/translations-provider";
import {FontSize, GrayScale, VoiceRecognition} from "./components";
import Link from "next/link";
import AccessibilityIcon from "@icons/accessibility-tools.svg";
import classNames from "classnames";
import {useRouter} from "next/router";

const AccessibilityTools = () => {
  const ctx = useContext(ThemeContext);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const {locale} = useRouter();
  useEffect(() => {
    document.documentElement.style.setProperty("--font-size-adder", `${ctx.counter}px`);
    document.documentElement.style.setProperty("--line-height-adder", `${ctx.counter}px`);
  }, [ctx.counter]);
  useEffect(() => {
    // on click outside drawer
    const handleClickOutside = (event: any) => {
      if (showDrawer && !event.target.closest(".drawer")) {
        setShowDrawer(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDrawer]);
  return (
    <>
      <div
        className={classNames(
          "fixed top-0   right-0 left-0 bottom-0 bg-gray/50 z-[130] transition-all opacity-0 hidden",
          showDrawer && "opacity-100 !block",
        )}
      />
      <div className="drawer">
        <div className="drawer-content">
          <button
            aria-label="Show drawer"
            onClick={() => setShowDrawer(true)}
            className="inline-block cursor-pointer fixed max-laptop:ltr:right-[30px] max-laptop:rtl:left-[30px] ltr:right-[80px] rtl:left-[80px] rounded-full border-4 border-primary bg-primary bottom-[15.5%] z-20 shadow-main-submenu  items-center justify-center "
          >
            <Icon icon={AccessibilityIcon} className="w-[52px] h-[52px] " />
          </button>
          <div
            className={classNames(
              "fixed top-0 shadow-main-menu-fixed overflow-y-auto ltr:-right-1/2 rtl:-left-1/2 bottom-0 transition-all ltr:text-left rtl:text-right h-screen bg-white z-[131] opacity-0 w-0",
              showDrawer && "opacity-100 !w-full laptop:!w-[500px] p-8 ltr:right-0 rtl:left-0",
              "pt-0",
            )}
          >
            <div className="pt-8">
              <div className="flex justify-between items-center mb-[40px] bg-white sticky top-0 max-tablet::pt-8">
                <Heading type="medium">{t("Access Tools")}</Heading>
                <Icon icon={Close} className="cursor-pointer w-8 h-8" onClick={() => setShowDrawer(false)} />
              </div>

              <div className="flex flex-col gap-y-12">
                <div>
                  <VoiceRecognition setShowDrawer={setShowDrawer} />
                </div>
                <div>
                  <FontSize />
                </div>
                <div>
                  <GrayScale />
                </div>
                <div>
                  <div>
                    <Heading type="small" color="text-primary">
                      {t("Are You Facing Issues Navigating This Website ?")}
                    </Heading>
                  </div>
                  <div className="mt-4 py-2 ">
                    <Link aria-label="Contact us" onClick={() => setShowDrawer(false)} href={`/${locale}/contact-us`}>
                      <Button aria-label="Contact us" className="py-[9.5px] px-[24px] flex-[1 0 0]">{t("Contact Us")}</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessibilityTools;
