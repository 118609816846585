import {formatWebformOptions} from "@lib/utils";
import {GenericControlRef, SelectWebformElement} from "@type/general";
import React from "react";
import {FormSelectProps} from "react-bootstrap";
import { t } from "@lib/translations-provider";
import Select from "../Select";

type Props = {
  emptyLabel?: string;
};

const SelectElement = React.forwardRef<
  HTMLSelectElement,
  GenericControlRef<SelectWebformElement> & FormSelectProps & Props
>(({webformElement, emptyLabel, ...rest}, ref) => {
  const selectWebformElement = webformElement as SelectWebformElement;
  const options = selectWebformElement["#options"] ? formatWebformOptions(selectWebformElement["#options"]) : {};
  return (
    <Select

      emptyLabel={emptyLabel || (selectWebformElement["#required"] ? t("- Choose -") : t("- None -"))}
      options={options}
      {...rest}
      ref={ref}
    />
  );
});

export default SelectElement;
