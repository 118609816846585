import classNames from "classnames";
import React, {HTMLAttributes} from "react";
import Icon from "../Icon";
import {GeneralIcon} from "@type/general";

type HeadingType = "large" | "medium" | "small" | "extra-small" | "title";
type HeadingElements = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type HeaderProps = {
  type?: HeadingType;
  element?: HeadingElements;
  icon?: GeneralIcon;
  iconPosition?: "left" | "right";
  color?: string;
  children: React.ReactNode | React.ReactNode[];
} & HTMLAttributes<HTMLDivElement>;

const Heading = ({
  children,
  color = "blue",
  element = "h1",
  type = "small",
  icon,
  iconPosition = "left",
  className,
  ...rest
}: HeaderProps) => {
  const Element = element;
  let scale = 0;
  switch (type) {
    case "large":
      scale = 90;
      break;
    case "title":
      scale = 48;
      break;
    case "medium":
      scale = 40;
      break;
    case "small":
      scale = 28;
      break;
    case "extra-small":
      scale = 26;
      break;
    default:
      scale = 40;
      break;
  }

  if (iconPosition === "left") {
    return (
      <Element {...rest} className={classNames(type, color, `font-scale-${scale}`, className)}>
        {children}
        {icon && <Icon className="ms-[20px] w-[60px] h-[30px] inline" icon={icon} />}
      </Element>
    );
  } else {
    return (
      <Element {...rest} className={classNames(type, color, `font-scale-${scale}`, className)}>
        {icon && <Icon className="me-[20px] w-[60px] h-[30px] inline" icon={icon} />}
        {children}
      </Element>
    );
  }
};

export default Heading;
