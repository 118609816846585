/**
 * @file Provides helper functions for APIs and Axios.
 */
import {DrupalMediaFile} from "@type/entity";
import {FieldFile} from "@type/fields";
import axios, {AxiosError} from "axios";
import {forEach, isError} from "lodash";
import qs from "querystring";
import {getLangcode} from "./translations-provider";
import {trimChar} from "./utils";

export const getAxiosClient = () => {
  // If authentication is used, make sure to hide from frontend using 'window === undefined'.
  // We don't want to expose the token.
  const AxiosClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_NEXTJS_DOMAIN,
    paramsSerializer: (params) => {
      const newValues: string[] = [];
      forEach(params, (value, key) => {
        if (typeof value !== "undefined" && value !== null && value !== "") {
          if (value instanceof Array && value.length > 0) {
            forEach(value, (valueInArray) => {
              newValues.push(`${key}[]=${valueInArray}`);
            });
          } else {
            newValues.push(`${key}=${value}`);
          }
        }
      });

      // If we are on front-end, and there is no langcode param, add the langcode from html.
      if (typeof window !== "undefined" && !params.langcode) {
        const langcode = getLangcode();
        if (langcode) {
          newValues.push(`langcode=${langcode}`);
        }
      }

      return newValues.join("&");
    },
  });

  return AxiosClient;
};

/**
 * Builds url arguments.
 */
export const urlArguments = (url: string, args: Array<string | number> = [], params: Record<string, any> = {}) => {
  args.forEach((arg) => {
    arg = typeof arg === "number" ? arg.toString() : arg;
    url = url.replace(/\{\w+\}/, arg);
  });
  const allParams = qs.stringify(params);
  return url + (allParams ? "?" + allParams : "");
};

export const urlToDrupal = (url: string, args: string[] = [], params: Record<string, any> = {}) => {
  url = urlArguments(url, args, params);
  return `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL?.trim()}/${trimChar(url, "/")}`;
};

/**
 * Builds a new url to handle files.
 */
export const urlToFile = (url: string | FieldFile | DrupalMediaFile, external: boolean = false): string => {
  // Remove NEXT_PUBLIC_DRUPAL_BASE_URL from url.

  let urlString = "";
  if (typeof url === "object") {
    if ("uri" in url) {
      urlString = url.uri.url;
    } else if (url.drupal_internal__mid) {
      return urlToFile(url.field_media_file);
    } else {
      console.warn("Failed to get url from file object. (url.uri.value is undefined");
      urlString = "";
    }
  } else {
    const httpUrl = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL?.replace("https://", "http://");
    const httpsUrl = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL?.replace("http://", "https://");
    urlString = url.replace(httpUrl as string, "");
    urlString = urlString.replace(httpsUrl as string, "");
  }

  // Trim '/' from url.
  url = trimChar(urlString, "/");
  // return "/api/files/" + url;
  return external ? process.env.NEXT_PUBLIC_NEXTJS_DOMAIN + "/api/files/" + url : "/api/files/" + url;
};
export const urlToNextJs = (url: string) => {
  if (!process.env.NEXT_PUBLIC_NEXTJS_DOMAIN) {
    throw new Error("NEXT_PUBLIC_NEXTJS_DOMAIN is not set");
  }

  // Trim '/' from url.
  url = trimChar(url, "/");
  const nextjsUrl = trimChar(process.env.NEXT_PUBLIC_NEXTJS_DOMAIN, "/");
  return `${nextjsUrl}/${url}`;
};

export const isAxiosError = (e: any): e is AxiosError => {
  if (isError(e) && "response" in e) {
    return true;
  }
  return false;
};
