import React from "react";
import {AdvancedHtmlWebformElement, HtmlWebformElement, WebformDumbComponent} from "@type/general";

const MarkupElement = ({webformElement}: WebformDumbComponent<HtmlWebformElement | AdvancedHtmlWebformElement>) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: webformElement["#type"] === "webform_markup" ? webformElement["#markup"] : webformElement["#text"],
      }}
    />
  );
};

export default MarkupElement;
