/**
 * @file Provides general functions which does not have any category.
 */

import {
  DrupalComplexWebform,
  DrupalTermWithColor,
  DrupalWebform,
  DrupalWebformResponse,
  EntityWithBody,
  EntityWithBreadcrumbs,
  EntityWithCanonical,
  EntityWithLanguages,
  EntityWithLayout,
  EntityWithPathAlias,
  EntityWithRating,
  ExtendedDrupalNode,
  ExtendedDrupalTaxonomyTerm,
  FileEntity,
  FileResource,
  PageNode,
  UncleanDrupalComplexWebform,
} from "@type/entity";
import {
  CompoundValue,
  HierarchyRequest,
  HierarchyValue,
  RegionChildValue,
  WebformElement,
  WizardPageElement,
} from "@type/general";
import {AxiosError} from "axios";
import classNames from "classnames";
import {clone, cloneDeep, forEach, isError, isObject, mapValues, pickBy, some} from "lodash";
import {DrupalTaxonomyTerm, JsonApiResource} from "next-drupal";
import {ApiError} from "next/dist/server/api-utils";
import {FieldError} from "react-hook-form";
import {toast, ToastOptions} from "react-toastify";
import striptags from "striptags";
import {t} from "./translations-provider";

export const getDirection = (): string => {
  return document.getElementsByTagName("html")[0]?.getAttribute("dir") || "ltr";
};

export const isApiError = (err: unknown): err is ApiError => {
  if (err && typeof err === "object" && "statusCode" in err && "message" in err) {
    return true;
  }

  return false;
};

export const replaceDomain = (str: string) =>
  str.replace(
    new RegExp(process.env.NEXT_PUBLIC_DRUPAL_BASE_URL || "", "g"),
    process.env.NEXT_PUBLIC_NEXTJS_DOMAIN || "",
  );

export const getAttributes = (
  attributes: Record<string, string>,
  ...otherAttrArr: Array<Record<string, string>>
): Record<string, string> => {
  const mergedAttributes: Record<string, string> = clone(attributes);
  delete mergedAttributes.class;
  forEach(otherAttrArr, (otherAttr: Record<string, string>) => {
    forEach(otherAttr, (value: string, key: string) => {
      let mergedValue = value;
      if (mergedAttributes[key]) {
        mergedValue = classNames(value, mergedAttributes[key]);
      }

      mergedAttributes[key] = mergedValue;
    });
  });
  return mergedAttributes;
};

export const getFieldErrorMessage = (error: FieldError): string => {
  if (error.message) {
    return error.message;
  }

  switch (error.type) {
    case "required":
      return t("This field is required");
    default:
      return t("Validation of the field has failed");
  }
};

export const errorMessage = (error: string, options: ToastOptions = {}): void => {
  const toastClassName = classNames(options?.className?.toString() || "", "toast-wrapper");
  delete options.className;
  toast.error(error, {
    rtl: getDirection() === "rtl",
    position: getDirection() === "rtl" ? "top-left" : "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    progressClassName: "toast-progress",
    className: toastClassName,
    ...options,
  });
};

export const handleError = (error: unknown) => {
  // TODO: Handle messages.
  if (isError(error)) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 403) {
        return errorMessage(t("Permission denied"));
      }
      if (error.response?.status === 500) {
        return errorMessage(t("Something went wrong"));
      }
      return errorMessage(t(error.response?.data?.message ?? error.message));
    }

    errorMessage(`Unexpected error happened: ${error.message}`);
    return;
  }

  console.error("Unexpected error happened", error);
};

export const formatWebformOptions = (options: Record<string, string>): Record<string, string> => {
  let newOptions = clone(options);
  if (typeof newOptions[""] !== "undefined") {
    delete newOptions[""];
  }

  // If the values are empty, use the key as the label.
  newOptions = mapValues(newOptions, (value: string, key: string) => {
    if (!value || value === "") {
      return key;
    }

    return value;
  });

  return newOptions;
};

export const isComplexWebform = (webform: DrupalWebformResponse): webform is DrupalComplexWebform => {
  return some(webform.elements, (element) => {
    return element["#webform_plugin_id"] === "webform_wizard_page";
  });
};

export const cleanUpComplexWebform = (webform: UncleanDrupalComplexWebform) => {
  const newWebform = cloneDeep(webform);
  newWebform.elements = pickBy(webform.elements, (element) => {
    let el: WebformElement | null = null;
    if (element && typeof element === "object") {
      el = element as WebformElement;
    }

    return el && el["#webform_plugin_id"] === "webform_wizard_page";
  });
  const cleanWebform = newWebform as DrupalComplexWebform;
  cleanWebform.elements = mapValues(cleanWebform.elements, (wizardPage: WizardPageElement) => {
    const w = pickBy<WizardPageElement>(wizardPage, (el, key) => {
      if (!el || typeof el !== "object") {
        return true;
      }

      return el && el["#type"] !== "webform_actions";
    });

    return w as WizardPageElement;
  });

  return cleanWebform;
};

export const trimChar = (str: string, ch: string) => {
  let start = 0;
  let end = str.length;

  while (start < end && str[start] === ch) ++start;

  while (end > start && str[end - 1] === ch) --end;

  return start > 0 || end < str.length ? str.substring(start, end) : str;
};

export const isUrlEmpty = (url: string) => {
  if (url.trim().length !== 0 || url.includes("#")) {
    return false;
  } else {
    return true;
  }
};

const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const isExternal = (url: string) => {
  if (typeof window !== "undefined") {
    const newUrl = isValidUrl(url) && new URL(url);
    return newUrl && newUrl.host !== window.location.host;
  }
};

export const isDrupalNode = (resource: any): resource is ExtendedDrupalNode => {
  return resource && resource.title;
};

export const isDrupalTerm = (resource: any): resource is ExtendedDrupalTaxonomyTerm => {
  return resource && resource.drupal_internal__tid;
};

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const getEntityTypeAndBundleFromResource = (resource: JsonApiResource): [string, string] => {
  // Explode '--' to get entity type and bundle.
  const [entityType, bundle] = resource.type.split("--");
  return [entityType, bundle];
};

export const isDrupalPage = (node: JsonApiResource): node is PageNode => {
  return node.type === "node--seeds_page";
};

export const isLayoutBuilderPage = (entity: JsonApiResource | null): entity is EntityWithLayout => {
  return (
    entity != null &&
    "layout_builder_serialized" in entity &&
    isObject(entity.layout_builder_serialized) &&
    "sections" in entity.layout_builder_serialized &&
    Array.isArray(entity.layout_builder_serialized?.sections)
  );
};

export const isWebform = (resource: any): resource is DrupalWebform => {
  return resource && resource.type === "webform--webform";
};

export const hasBreadcrumbs = (resource: JsonApiResource): resource is EntityWithBreadcrumbs => {
  return resource && "breadcrumbs" in resource && resource.breadcrumbs instanceof Array;
};

export const ensureJsonFormat = (url: string) => {
  // If _format=json is not at the end of the url, add it.
  if (!url.includes("_format=json")) {
    url = url.includes("?") ? `${url}&_format=json` : `${url}?_format=json`;
  }

  return url;
};

export const reconstructUrl = (pathname: string, params: Record<string, string | undefined | null>) => {
  const url = new URL(pathname, process.env.NEXT_PUBLIC_NEXTJS_DOMAIN as string);
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value);
    }
  });

  // Return only the path with query.
  return url.pathname + url.search;
};

/**
 * Toast success message.
 */
export const successMessage = (message: string, options: ToastOptions = {}) => {
  const toastClassName = classNames(options?.className?.toString() || "", "toast-wrapper");
  delete options.className;
  toast.success(t(message), {
    rtl: true,
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    progressClassName: "toast-progress",
    className: toastClassName,
    ...options,
  });
};

/**
 * Check if a resource has languages.
 */
export const hasLanguages = (resource: any): resource is EntityWithLanguages => {
  return !!(resource && "languages" in resource && resource.languages instanceof Array);
};

/**
 * Checks if a resource has a canonical link.
 */
export const hasCanonical = (resource: JsonApiResource): resource is EntityWithCanonical => {
  return resource && "canonical" in resource && typeof resource.canonical === "string";
};

export const hasBody = (resource: JsonApiResource): resource is EntityWithBody => {
  return resource && "body" in resource && typeof resource.body === "object";
};

export const hasSeedsBody = (resource: JsonApiResource): resource is EntityWithBody => {
  return resource && "seeds_body" in resource && typeof resource.seeds_body === "object";
};

export const hasPathAlias = (resource: JsonApiResource): resource is EntityWithPathAlias => {
  return resource && "path" in resource && typeof resource.path === "object";
};

export const handleEmptyDescription = (resource: JsonApiResource, t: (str: string) => string): string | undefined => {
  let description: string | null = null;
  if (hasBody(resource)) {
    const value = resource.body?.value || "";
    const summary = resource.body?.summary || "";
    description = summary || striptags(value).substring(0, 200);
  } else if (hasSeedsBody(resource)) {
    const value = resource.seeds_body?.value || "";
    const summary = resource.seeds_body?.summary || "";
    description = summary || striptags(value).substring(0, 200);
  } else if (isDrupalTerm(resource)) {
    description = resource.description;
  }

  return description ? description : t("INSERT WEBSITE DESCRIPTION");
};
export const isCompoundValue = (value: any): value is CompoundValue => {
  return typeof value === "object" && value && value.value && value.label;
};

export const isHierarchyRequest = (request: any): request is HierarchyRequest => {
  return typeof request === "object" && request && "parentValue" in request && "childValue" in request;
};

export const isHierarchy = (value: any): value is HierarchyValue => {
  return typeof value === "object" && value && value.value && value.label && value.parent;
};

export const toHierarchy = (child: RegionChildValue, siblings?: HierarchyValue[]) => {
  const tmpArray: HierarchyValue = {
    value: child.tid,
    label: child.name,
    parent: child.parent,
  };
  if (siblings) {
    tmpArray.siblings = siblings;
  }
  return tmpArray;
};
export const toObject = (terms: DrupalTaxonomyTerm[]) => {
  const objects: Record<string, string> = {};
  terms.forEach((t) => {
    objects[t.drupal_internal__tid] = t.name;
  });

  return objects;
};

export const toLabelValue = (terms: DrupalTaxonomyTerm[]) => {
  const objects: {
    label: string;
    value: string;
  }[] = [];
  if (terms != undefined && terms.length > 0) {
    terms.forEach((t) => {
      objects.push({
        label: t.name,
        value: t.drupal_internal__tid,
      });
    });
  }

  return objects;
};

export const handleResponse = async (response: Response) => {
  if (!response?.ok) {
    // @ts-ignore
    const error = await response.json();
    if ("message" in error) {
      throw new ApiError(response.status, error.message);
    } else if ("error" in error && "message" in error.error) {
      throw new ApiError(response.status, error.error.message);
    } else {
      throw new Error("Unexpected Error");
    }
  }
};

export const stripHtml = (html: string) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const hasRating = (resource: JsonApiResource): resource is EntityWithRating => {
  // @ts-ignore
  return typeof resource.field_rating_stars !== "undefined" && typeof resource.field_rating_useful !== "undefined";
};

export const isTermWithColor = (term: any): term is DrupalTermWithColor => {
  return !!term.field_color;
};
export const toFileEntity = (file: FileResource): FileEntity => {
  return {
    fid: file.fid,
    filename: file.filename,
    fullUrl: file.fullUrl,
  };
};
