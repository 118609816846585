import classNames from "classnames";
import {useEffect, useState} from "react";
import {ButtonPagination} from "./components";
import InputPagination from "./components/InputPagination";
import NumberPagination from "./components/NumberPagination";
import {PaginationConfig, StatusString} from "@type/general";

export type FullPaginationProps = {
  size?: number;
  listRef?: React.MutableRefObject<HTMLDivElement | null>;
  type?: "buttonPagination" | "numberPagination";
  setPage: (page: number) => void;
  fetchData: () => void;
  pagination: PaginationConfig;
  status?: StatusString;
} & Record<any, any>;

const FullPaginationExtended = ({
  setPage,
  fetchData,
  pagination,
  listRef,
  size = 2,
  paginationProps,
  status,
}: FullPaginationProps) => {
  const {totalPages, currentPage: activePage} = pagination;
  const [selectedPage, setSelectedPage] = useState<string | number>(activePage + 1);
  useEffect(() => {
    setSelectedPage(activePage + 1);
  }, [activePage]);
  const scrollToView = () => {
    if (listRef?.current != null && status === "success") {
      listRef.current.parentElement?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };
  const onPageChange = (page: number) => {
    scrollToView();
    setPage(page);
    fetchData();
  };

  const disableFirstButtons = activePage == 0;
  const disableLastButtons = activePage >= totalPages - 1;
  const pages = [activePage + 1];
  for (let i = 0; i < size; i++) {
    const prev = pages[0] - 1;
    if (prev > 0) {
      pages.unshift(prev);
    }

    const next = pages[pages.length - 1] + 1;
    if (next < totalPages + 1) {
      pages.push(next);
    }
  }

  const props = {
    disableFirstButtons,
    disableLastButtons,
    activePage,
    totalPages,
    onPageChange,
    selectedPage,
    setSelectedPage,
    pagination,
    size,
  };

  const render = () => {
    switch (paginationProps?.type) {
      case "number":
        return pagination && totalPages > 1 ? <NumberPagination props={props} /> : null;

      case "button":
        return pagination && totalPages > 1 ? <ButtonPagination props={props} /> : null;

      default:
        return pagination && totalPages > 1 ? (
          <div className={classNames("pagination-wrapper flex flex-wrap mt-20 max-tablet:items-end ")}>
            <NumberPagination props={props} />
            <InputPagination props={props} />
          </div>
        ) : null;
    }
  };
  useEffect(() => {
    if (status == "success" && activePage != 0) {
      scrollToView();
    }
  }, [status, activePage]);
  return render();
};

export default FullPaginationExtended;
