import React from "react";
import {t} from "@lib/translations-provider";
import {FormProvider, useForm} from "react-hook-form";
import {NewsletterFormSchemaType} from "./types";
import {NewsletterFormSchema} from "./schema";
import {zodResolver} from "@hookform/resolvers/zod";
import {getAxiosClient} from "@lib/api";
import {INTERNAL_APIS} from "@configuration/apis";
import {errorMessage, handleError, successMessage} from "@lib/utils";
import NewsletterFooter from "./components/NewsletterFooter";
import NewsletterBlock from "./components/NewsletterBlock";
import {AxiosError} from "axios";

type Props = {
  view: "footer" | "block";
};

const NewsletterSubscriber = ({view}: Props) => {
  const newsLetterFormProps = useForm<NewsletterFormSchemaType>({
    mode: "onChange",
    resolver: zodResolver(NewsletterFormSchema),
  });

  const handleSubmit = async (data: NewsletterFormSchemaType) => {
    try {
      const client = getAxiosClient();
      const res = await client.post(INTERNAL_APIS.POST.SUBSCRIBE_NEWSLETTER, data);
      if (res.status == 200) {
        newsLetterFormProps.reset();
        successMessage(t(res.data.message));
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.code == "ERR_BAD_REQUEST") {
          errorMessage(t(e.response?.data.message));
        } else {
          handleError(e);
        }
      }
    }
  };
  return (
    <FormProvider {...newsLetterFormProps}>
      {view == "block" ? <NewsletterBlock onSubmit={handleSubmit} /> : <NewsletterFooter onSubmit={handleSubmit} />}
    </FormProvider>
  );
};

export default NewsletterSubscriber;
