import {getAxiosClient} from "@lib/api";
import {getLangcode} from "@lib/translations-provider";
import {
  getEntityTypeAndBundleFromResource,
  handleError,
  hasRating,
  isDrupalNode,
  isDrupalTerm,
  successMessage,
} from "@lib/utils";
import {RatingProviderContextValues, RatingResponse} from "@type/general";
import {INTERNAL_APIS} from "configuration/apis";
import {JsonApiResource} from "next-drupal";
import {useRouter} from "next/router";
import React, {useEffect} from "react";
import RatingStars, {RatingStarsWithoutAction} from "./RatingStars";
import RatingUseful from "./RatingUseful";
import {isAxiosError} from "axios";

type Props = Omit<React.AllHTMLAttributes<HTMLDivElement>, "resource"> & {
  resource?: JsonApiResource;
  children: any;
};

export const RatingContextProvider = React.createContext<null | RatingProviderContextValues>(null);

const RatingProvider = ({resource, children, ...rest}: Props) => {
  const [rating, setRating] = React.useState<RatingResponse>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const router = useRouter();

  const getEntityType = () => {
    if (!resource) return;
    const [entityType] = getEntityTypeAndBundleFromResource(resource);
    return entityType;
  };

  const getEntityId = () => {
    if (!resource) return;
    let entityId: string | number;
    if (isDrupalNode(resource)) {
      entityId = resource.drupal_internal__nid;
    } else if (isDrupalTerm(resource)) {
      entityId = resource.drupal_internal__tid;
    } else {
      // Throw error explaining that the resource is not supported.
      throw new Error(`Resource of type ${getEntityType()} is not supported.`);
    }

    return entityId;
  };

  useEffect(() => {
    const entityType = getEntityType();
    const entityId = getEntityId();
    const fetch = async () => {
      if (!resource) return;
      try {
        setIsLoading(true);
        const res = await getAxiosClient().get<RatingResponse>(INTERNAL_APIS.GET.RATING, {
          params: {
            entityType,
            entityId,
          },
        });
        setRating(res.data);
      } catch (e) {
        if (isAxiosError(e)) {
          // Do not throw an error message if permission is denied, fall silently.
          if (e.response?.status !== 403) {
            handleError(e);
          }
        }
      } finally {
        setIsLoading(false);
      }
    };
    // Do not fetch if the resource does not have rating fields.
    if (resource && (resource.field_rating_stars || resource.field_rating_useful) && hasRating(resource)) {
      fetch();
    }
  }, [resource]);

  const post = async (
    type: "useful" | "stars",
    value: number,
    list: string[] | undefined,
    otherText: string | undefined,
  ) => {
    const entityType = getEntityType();
    const entityId = getEntityId();
    if (!resource) {
      return null;
    }
    const data: Record<string, string | string[] | number | undefined> = {
      voteType: type,
      entityType: entityType,
      entityId: entityId,
      value: value,
      list,
      otherText,
    };
    try {
      setIsLoading(true);
      const res = await getAxiosClient().post(INTERNAL_APIS.POST.RATING, data, {
        params: {
          langcode: getLangcode(),
        },
      });
      setRating(res.data);

      // Call this function when you want to refresh the data
      successMessage(res.data.message);
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
      if (resource.type === "node--service_catalog" || resource.type === "node--championship") {
        router.reload();
      }
    }
  };
  return (
    <RatingContextProvider.Provider
      value={{
        isLoading,
        rating,
        rate: post,
      }}
    >
      {children}
    </RatingContextProvider.Provider>
  );
};

RatingProvider.Stars = RatingStars;
RatingProvider.Useful = RatingUseful;
RatingProvider.StarsWithoutAction = RatingStarsWithoutAction;

export default RatingProvider;
