import {GeneralIcon} from "@type/general";
import classNames from "classnames";
import React from "react";
import Icon from "../Icon";

export type ButtonWithIconProps = {
  children?: React.ReactNode;
  icon?: GeneralIcon;
  iconPosition?: "left" | "right";
  variant?:
    | "minimal"
    | "primary"
    | "primary-outline"
    | "secondary"
    | "secondary-outline"
    | "white"
    | "transparent"
    | "border-bottom";
  iconClassName?: string;
  rounded?: boolean;
  ref?: React.RefObject<HTMLButtonElement>;
} & React.AllHTMLAttributes<HTMLButtonElement> &
  JSX.IntrinsicElements["button"];

const Button = React.forwardRef<HTMLButtonElement, ButtonWithIconProps>(
  (
    {
      children,
      icon,
      iconPosition = "left",
      variant = "primary",
      iconClassName = "ms-[8px] w-[10px] h-[14px]",
      rounded = true,
      className,
      ...rest
    },
    ref,
  ) => {
    let classes = "";
    switch (variant) {
      case "minimal":
        classes = "";
        break;
      case "primary":
        classes = "px-[24px] py-[10px]  bg-primary text-white border-solid border border-primary hover:opacity-80";
        break;
      case "primary-outline":
        classes =
          "px-[24px] py-[10px]  bg-transparent text-primary border-2  border-primary hover:text-secondary hover:border-light-blue";
        break;
      case "secondary":
        classes = "px-[24px] py-[10px]  bg-gray text-white hover:bg-gray-600";
        break;
      case "secondary-outline":
        classes =
          "px-[24px] py-[10px]  bg-transparent text-secondary border-2 border-light-blue hover:border-primary hover:text-primary";
        break;
      case "white":
        classes = "px-[24px] py-[10px]  bg-transparent text-white border border-white hover:bg-white hover:text-blue";
        break;
      case "border-bottom":
        classes = "bg-transparent  text-primary border-b-2 rounded-0 border-primary hover:bg-white ";
        break;
      case "transparent":
        classes = "px-[24px] py-[10px]  bg-transparent";
        break;
      default:
        classes = "px-[24px] py-[10px]  bg-primary text-white hover:bg-primary";
        break;
    }
    rounded ? (classes += " rounded-full") : (classes = classes);
    if (iconPosition === "left") {
      return (
        <button {...rest} ref={ref} className={classNames("rfs-24 inline-flex", classes, className)}>
          {icon ? <Icon className={classNames(iconClassName, "inline")} icon={icon} /> : null} {children}
        </button>
      );
    } else {
      return (
        <button
          {...rest}
          ref={ref}
          className={classNames(
            "rfs-24 items-center transition-colors inline-flex duration-300 ease-in-out",
            classes,
            className,
          )}
        >
          {children} {icon ? <Icon className={classNames(iconClassName, "inline")} icon={icon} /> : null}
        </button>
      );
    }
  },
);

export default Button;
