import {ViewPartComponentProps} from "@type/layout";
import React from "react";
import {t} from "@lib/translations-provider";
import DrupalLink from "@components/fields/DrupalLink";

type ViewGenericHeaderProps = ViewPartComponentProps & {
  headerProps?: Record<string, string>;
};

const ViewGenericHeader = ({headerProps}: ViewGenericHeaderProps) => {
  return (
    <>
      {headerProps && (
        <div className="view-header-wrapper">
          <p className="view-header-title">{t(headerProps?.title)}</p>
          <DrupalLink className="greenLink" link={headerProps?.link}>
            {t(headerProps.linktext)}
          </DrupalLink>
        </div>
      )}
    </>
  );
};

export default ViewGenericHeader;
