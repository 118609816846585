// import Modal from "@components/general/Modal";

import {t} from "@lib/translations-provider";
import SearchIcon from "@icons/search.svg";
import {useRouter} from "next/router";
import {useRef, useState} from "react";
import SearchTextField from "@components/form/SearchTextField";
import * as Dialog from "@radix-ui/react-dialog";
import {Button, Heading, Icon} from "@components/elements";
import classNames from "classnames";
import CloseIcon from "@icons/close.svg";

const SearchBlock = () => {
  const router = useRouter();

  const searchWrapperRef = useRef(null);
  const [search, setSearch] = useState<string>("");
  const handleChange = (e: string) => {
    setSearch(e);
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      router.push({
        pathname: "/search",
        query: {
          search_any: search,
        },
      });
      setShow(false);
    }
  };
  /**
   * Handles clicking on advanced search.
   *
   */
  const handleAdvancedSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    router.push({
      pathname: "/search",
      query: {
        advanced: 1,
      },
    });
    setShow(false);
  };
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(!show);
  };
  return (
    <div className="flex" ref={searchWrapperRef}>
      <Dialog.Root open={show} modal={true}>
        <Dialog.Trigger asChild className="flex items-center">
          <Button
            aria-label="Search"
            onClick={handleToggle}
            variant="transparent"
            className="!p-0 bg-transparent focus-visible:border-0 focus-visible:outline-none"
            tabIndex={0}
          >
            <SearchIcon className="w-6 h-6" />
          </Button>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Overlay className="bg-gray/30  fixed inset-0  z-100" />
          <Dialog.Content
            onInteractOutside={() => {
              setShow(false);
            }}
            className={classNames(
              "transition-all duration-300 flex z-100 flex-col gap-8 items-center fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[580px] translate-x-[-50%] translate-y-[-50%] border border-[#E4E7EB] bg-white z-[101]  rounded-lg",
              `data-[state=open]:animate-fadeIn`,
              `data-[state=closed]:animate-fadeOut`,
            )}
          >
            <div className="p-[24px] flex flex-col gap-8 items-center w-full">
              <div className="flex w-full items-center justify-between">
                <Heading className="font-somer text-[34px] font-bold" type="medium">
                  {t("Search on the site")}
                </Heading>
                <Dialog.Close onClick={handleToggle}>
                  <Icon icon={CloseIcon} className="w-6 h-6" />
                </Dialog.Close>
              </div>

              <div className="flex w-full items-center justify-between flex-col gap-8 ">
                <SearchTextField
                  tabIndex={0}
                  onChange={(e) => handleChange(e)}
                  onKeyPress={handleKeyPress}
                  value={search}
                  placeholder={t("Find what interests you...")}
                />
                <a href="#advanced" className="text-2xl py-[9.5px] px-6" onClick={handleAdvancedSearch}>
                  {t("Advanced search")}
                </a>
              </div>
            </div>
            <div className="w-full h-full modal-pattern"></div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default SearchBlock;
