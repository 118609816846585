import map from "lodash/map";
import React from "react";
import {FormCheck} from "react-bootstrap";
import {EntityRadiosWebformElement, GenericControlRef, RadiosWebformElement} from "@type/general";
import { t } from "@lib/translations-provider";

const RadioOptionsElement = React.forwardRef<any, GenericControlRef<RadiosWebformElement | EntityRadiosWebformElement>>(
  ({webformElement, ...rest}, ref) => {
    return (
      <>
        {!webformElement["#required"] && <FormCheck type="radio" {...rest} value="" label={t("None")} />}
        {map(webformElement["#options"], (label, value) => (
          <FormCheck
            key={value}
            {...rest}
            id={`${webformElement["#webform_key"]}${value}`}
            value={value}
            label={label}
            ref={ref}
            type="radio"
          />
        ))}
      </>
    );
  },
);

export default RadioOptionsElement;
