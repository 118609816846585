import {Button, Heading, Icon} from "@components/elements";
import {t} from "@lib/translations-provider";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import Microphone from "@icons/microphone.svg";
import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import CloseIcon from "@icons/close.svg";
import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";
import {getAxiosClient} from "@lib/api";
import {INTERNAL_APIS} from "@configuration/apis";
import {handleError} from "@lib/utils";
import {useRouter} from "next/router";

type VoiceRecognitionProps = {
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
};
const baseUrl = process.env.NEXT_PUBLIC_NEXTJS_DOMAIN;

const VoiceRecognition = ({setShowDrawer}: VoiceRecognitionProps) => {
  const [show, setShow] = useState(false);
  const router = useRouter();
  const [browserSupport, setBrowserSupport] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    handVoiceCommand();
    setShow(true);
    setShowDrawer(false);
  };
  const {transcript, resetTranscript, listening, browserSupportsSpeechRecognition} = useSpeechRecognition();

  const handVoiceCommand = async () => {
    try {
      const res = await getAxiosClient().get(INTERNAL_APIS.GET.VOICE_RECOGNITAION, {
        params: {
          langcode: router.locale,
        },
      });
      setFetchedData(res.data);
    } catch (error) {
      handleError(error);
    }
  };
  const closModalHandler = () => {
    setShow(false);
    handleClose();
    resetTranscript();
    SpeechRecognition.stopListening();
  };

  useEffect(() => {
    fetchedData.forEach((element: any) => {
      if (transcript.toLocaleLowerCase().includes(element.command[0])) {
        router.push(`${baseUrl}${element.url}`);
        resetTranscript();
        setShow(false);
      }
    });
  }, [transcript]);

  const startListingHandler = async () => {
    SpeechRecognition.startListening({language: router.locale === "ar" ? "ar-JO" : "en-US"});
    if (!browserSupportsSpeechRecognition) {
      setBrowserSupport(true);
    }
  };
  return (
    <div>
      <Heading type="small" color="text-primary">
        {t("Voice commands")}
      </Heading>
      <div className="mt-4 p-2">
        <Dialog.Root open={show}>
          <Dialog.Trigger asChild>
            <Button
              aria-label="Voice commands"
              onClick={handleShow}
              variant="transparent"
              className={classNames(
                "!p-0 w-8 h-8 inline-flex justify-center items-center rounded-full bg-transparent group hover:bg-primary cursor-pointer  transition-all",
              )}
              tabIndex={0}
            >
              <Icon
                icon={Microphone}
                className="w-6 h-6 [&>*]:stroke-primary group-hover:[&>*]:stroke-[white!important] "
              />
            </Button>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className="bg-gray/30 data-[state=open]:animate-overlayShow fixed inset-0  z-10" />
            <Dialog.Content className="data-[state=open]:animate-contentShow flex z-[130] flex-col gap-8 items-center fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[580px] translate-x-[-50%] translate-y-[-50%] border border-[#E4E7EB] bg-white   rounded-lg">
              <div className="p-[24px] flex flex-col gap-8 items-center w-full">
                <div className="flex w-full items-start justify-between">
                  <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                    <Heading className="font-somer text-[34px] font-bold" type="medium">
                      {t("Speak your Mind")}
                    </Heading>
                  </Dialog.Title>
                  <Dialog.Close onClick={closModalHandler}>
                    <Icon icon={CloseIcon} className="w-6 h-6" />
                  </Dialog.Close>
                </div>
                <div className="flex w-full items-center justify-between flex-col gap-8 ">
                  <span className="flex flex-col items-center justify-center gap-4">
                    <Icon
                      className={classNames(
                        listening ? "border-2 border-red-700" : "",
                        "stroke-primary w-12 h-12 rounded-full",
                      )}
                      icon={Microphone}
                    />
                    <span className="text-gray-darker text-xl">{t("For Example Say: contact us")}</span>
                  </span>
                  <div className="flex gap-8">
                    <Button aria-label="Start" onClick={startListingHandler} disabled={listening}>
                      {t("Start")}
                    </Button>
                    <Button aria-label="Stop" variant="primary-outline" onClick={SpeechRecognition.stopListening}>
                      {t("Stop")}
                    </Button>
                  </div>
                  {browserSupport && <p>{t("Browser doesn't support speech recognition.")}</p>}
                </div>
              </div>
              <div className="w-full h-full modal-pattern"></div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </div>
  );
};

export default VoiceRecognition;
