import {
  DefaultViewWrapper,
  FullPaginationExtended,
  ViewGenericList,
  ViewGenericRowsWrapper,
} from "@components/views-components";
import {DefaultViewDisplayProperties} from "@type/layout";

/**
 * The default properties for each view.
 *
 */
export const DEFAULT_VIEW_DISPLAY_PROPERTIES: DefaultViewDisplayProperties = {
  viewWrapper: DefaultViewWrapper,
  list: ViewGenericList,
  rowWrapper: ViewGenericRowsWrapper,
  pager: FullPaginationExtended,
};
