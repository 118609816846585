/**
 * @file Provides editable configurations. This contains views, displays, blocks, webform elements and includes.
 *
 * @todo Seperate the configurations.
 */
import CheckboxElement from "@components/webform-elements/CheckboxElement";
import CheckboxesOptionsElement from "@components/webform-elements/CheckboxesOptionsElement";
import DatelistElement from "@components/webform-elements/DatelistElement";
import Details from "@components/webform-elements/Details";
import EmailConfirmElement from "@components/webform-elements/EmailConfirmElement/EmailConfirmElement";
import FileUpload from "@components/webform-elements/FileUpload";
import MarkupElement from "@components/webform-elements/MarkupElement";
import RadioOptionsElement from "@components/webform-elements/RadioOptionsElement";
import Section from "@components/webform-elements/Section";
import SelectElement from "@components/webform-elements/SelectElement";
import SelectOtherElement from "@components/webform-elements/SelectOtherElement";
import TelephoneElement from "@components/webform-elements/TelephoneElement";
import TextareaElement from "@components/webform-elements/TextareaElement";
import GenericInputElement from "@components/webform-elements/TextElement";
import WebformDatePicker from "@components/webform-elements/WebformDatePicker";
import WebformDatetimePicker from "@components/webform-elements/WebformDatetimePicker";
import {normalizeDatelist, validateConfirmEmail, validateDatelist, validateEmailWebform} from "@lib/forms";
import {EmailConfirmValue, WebformDefaultComponents} from "@type/general";
import {FormControl} from "react-bootstrap";
/**
 * Defines webform fields.
 *
 * The key is the type of the webform field, the value defines the type of
 * the compoent and the used component.
 *
 */
export const WEBFORM_FIELDS_COMPONENTS: WebformDefaultComponents = {
  textfield: {
    component: GenericInputElement,
    type: "ref",
  },
  textarea: {
    type: "ref",
    component: TextareaElement,
  },
  select: {
    type: "ref",
    component: SelectElement,
  },
  email: {
    type: "ref",
    component: GenericInputElement,
    validate: validateEmailWebform,
  },
  webform_email_confirm: {
    type: "controller",
    component: EmailConfirmElement,
    normalizeFunction: (value: EmailConfirmValue | undefined) => value && value.email,
    validate: validateConfirmEmail,
  },
  webform_select_other: {
    type: "controller",
    component: SelectOtherElement,
  },
  container: {
    type: "container",
    component: "div",
  },
  details: {
    type: "container",
    component: Details,
  },
  webform_section: {
    type: "container",
    component: Section,
  },
  processed_text: {
    type: "dumb",
    component: MarkupElement,
  },
  webform_markup: {
    type: "dumb",
    component: MarkupElement,
  },
  checkbox: {
    type: "ref",
    component: CheckboxElement,
    hideLabel: true,
  },
  radios: {
    type: "ref",
    component: RadioOptionsElement,
  },
  webform_entity_radios: {
    type: "ref",
    component: RadioOptionsElement,
  },
  checkboxes: {
    type: "controller",
    component: CheckboxesOptionsElement,
  },
  webform_entity_checkboxes: {
    type: "controller",
    component: CheckboxesOptionsElement,
  },
  webform_term_checkboxes: {
    type: "controller",
    component: CheckboxesOptionsElement,
  },
  number: {
    type: "ref",
    component: GenericInputElement,
  },
  tel: {
    type: "controller",
    component: TelephoneElement,
  },
  url: {
    component: GenericInputElement,
    type: "ref",
  },
  managed_file: {
    type: "controller",
    component: FileUpload,
  },
  webform_document_file: {
    type: "controller",
    component: FileUpload,
  },
  webform_audio_file: {
    type: "controller",
    component: FileUpload,
  },
  webform_video_file: {
    type: "controller",
    component: FileUpload,
  },
  webform_image_file: {
    type: "controller",
    component: FileUpload,
  },
  date: {
    type: "controller",
    component: WebformDatePicker,
  },
  datetime: {
    type: "controller",
    component: WebformDatetimePicker,
  },
  datelist: {
    type: "controller",
    component: DatelistElement,
    validate: validateDatelist,
    normalizeFunction: normalizeDatelist,
  },
};
