import {Icon} from "@components/elements";
import {DrupalLink} from "@components/fields";
import LeftTriangle from "@icons/left-triangle.svg";
import {useMediaQuery} from "@lib/hooks";
import {getMenuItem} from "@lib/menu-utils";
import * as Accordion from "@radix-ui/react-accordion";
import {FieldLink} from "@type/fields";
import {MenuParentProps} from "@type/general";
import map from "lodash/map";
import {DrupalMenuLinkContent} from "next-drupal";
import {AccordionContent, AccordionItem, AccordionTrigger} from "./MenuAccordion";

type FooterMenuProps = {
  menuContent: DrupalMenuLinkContent[];
};

type FooterMenuMobileProps = {
  menuContent: MenuParentProps[];
};
/**
 * Checks if the link is external.
 */
const isExternal = (link: string | FieldLink) => {
  let toCheck: string;
  if (typeof link === "string") {
    toCheck = link;
  } else {
    toCheck = link.url ? link.url : link.uri;
  }
  // Check if link starts with a protocol.
  return toCheck.match(/^(?:[a-zA-Z]+:)?\/\//);
};
const FooterMenuMobile = ({menuContent}: FooterMenuMobileProps) => {
  return (
    <Accordion.Root type="single" collapsible>
      {map(menuContent, (item) => {
        return (
          <AccordionItem key={item.id} value={item.id}>
            <AccordionTrigger>{item.title}</AccordionTrigger>
            <AccordionContent>
              {item.children.length > 0 &&
                map(item.children, (nestedItem) => {
                  return (
                    <div key={nestedItem.id}>
                      <DrupalLink
                        target={isExternal(nestedItem.url) ? "_blank" : "_self"}
                        aria-label={nestedItem.title}
                        className="text-[24px] pb-[10px] flex items-center gap-[12px] text-white"
                        link={nestedItem.url}
                        variant="minimal"
                      >
                        <Icon icon={LeftTriangle} className="w-[12px] h-[9px]  ltr:rotate-180" />
                        {nestedItem.title}
                      </DrupalLink>
                    </div>
                  );
                })}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion.Root>
  );
};

type FooterMenuDesktopProps = {
  menuContent: MenuParentProps[];
};

const FooterMenuDesktop = ({menuContent}: FooterMenuDesktopProps) => {
  return (
    <ul className="text-white flex flex-wrap gap-4 laptop:gap-0  ">
      {map(menuContent, (item) => {
        return (
          <li key={item.id} className="flex-[100%] laptop:flex-[33%]">
            <span className="text-[26px] font-[700] pb-[16px] block">{item.title}</span>
            <ul>
              {item.children.length > 0 &&
                map(item.children, (nestedItem) => {
                  return (
                    <li key={nestedItem.id}>
                      <DrupalLink
                        target={isExternal(nestedItem.url) ? "_blank" : "_self"}
                        aria-label={nestedItem.title}
                        className="text-[24px] pb-[10px] flex items-center gap-[12px] w-full flex-row"
                        link={nestedItem.url}
                        variant="minimal"
                        contentWrapperClassName="flex items-center  gap-[12px]"
                      >
                        <Icon icon={LeftTriangle} className="w-[12px] h-[9px] ltr:rotate-180" />
                        {nestedItem.title}
                      </DrupalLink>
                    </li>
                  );
                })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

const FooterMenu = ({menuContent}: FooterMenuProps) => {
  const menuItems = getMenuItem(menuContent);
  const media = useMediaQuery(1023);
  return (
    <div className="laptop:flex-[76%] pt-[32px] max-laptop:w-full  max-laptop:flex-[100%]  ">
      {!media ? <FooterMenuDesktop menuContent={menuItems} /> : <FooterMenuMobile menuContent={menuItems} />}
    </div>
  );
};
export default FooterMenu;
