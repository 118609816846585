import {EntityRenderer} from "@components/hoc";
import {ViewWrapperComponentFunction} from "@type/layout";
import classNames from "classnames";
import map from "lodash/map";

const ViewGenericList = ({resources, rowSpace, meta, equalizer, rowClassName}: ViewWrapperComponentFunction) => {
  return (
    <div className={classNames(rowClassName, rowSpace && "row-spaces")}>
      {map(resources, (resource) => (
        <div
          key={resource.id}
          className={classNames("views-row", meta.style?.configurations?.row_class, equalizer && "equalizer")}
        >
          <EntityRenderer resource={resource} viewMode={meta.row.configurations.view_mode} />
        </div>
      ))}
    </div>
  );
};

export default ViewGenericList;
