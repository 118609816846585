import moment from "moment";
import React, {forwardRef, useEffect, useRef, useState} from "react";
import ReactDatePicker from "react-datepicker";
import type {ReactDatePickerProps} from "react-datepicker";
import {t} from "@lib/translations-provider";
import classNames from "classnames";
import classes from "./DatepickerField.module.scss";

import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

import Button from "@components/elements/Button";

export type DatePickerFieldProps = {
  onChange: (value: string) => void;
  onDebounceChange?: (value: string) => void;
  name?: string;
  placeholder?: string;
  variant?: "rounded" | "white";
} & Omit<ReactDatePickerProps, "onChange" | "onBlur" | "onFocus">;

const DatePickerField = ({
  onChange,
  onDebounceChange,
  className,
  placeholder,
  variant = "rounded",
  ...rest
}: DatePickerFieldProps) => {
  const [focused, setFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const timeoutRef = useRef<any>();
  if (!placeholder) {
    placeholder = t("Search");
  }

  const handleBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    setIsFilled(!!rest.value);
    setFocused(!!rest.value);
  }, [rest.value]);

  useEffect(() => {
    if (!onDebounceChange) {
      return;
    }
    const value = rest.value;
    clearTimeout(timeoutRef.current);

    if (typeof value === "string") {
      timeoutRef.current = setTimeout(() => {
        onDebounceChange(value);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [rest.value]);

  const CalendarHeading = forwardRef<any, any>(({value, onClick, ...rest}, ref) => {
    const handleClick = () => {
      setFocused(!focused);
      onClick();
    };
    const variantClassName = classes[`${variant}Variant`];
    return (
      <Button
        aria-label="Open datepicker"
        className={classNames(classes.customInput, variantClassName)}
        onClick={handleClick}
        iconPosition="right"
        icon={!value ? (focused ? faAngleUp : faAngleDown) : undefined}
      >
        {value ? value : t("Date")}
      </Button>
    );
  });
  return (
    <div className={classNames(classes.datepickerWrapper, (focused || isFilled) && classes.focused, className)}>
      <ReactDatePicker
        {...rest}
        onBlur={handleBlur}
        onClickOutside={() => setFocused(false)}
        customInput={<CalendarHeading />}
        className={variant === "rounded" ? classes.datepickerInput : classes.datepickerInputWhite}
        onChange={(e) => onChange(moment(e).format("YYYY-MM-DD"))}
      />
    </div>
  );
};

export default DatePickerField;
