import classNames from "classnames";
import React from "react";
import Webform from "@components/hoc/Webform";
import {WebformBlockComponent} from "@type/layout";
type WebformBlockProps = {
  component: WebformBlockComponent;
};

const WebformBlock = ({component}: WebformBlockProps) => {
  if (component.props) {
    return (
      <div className={classNames("webform-block", `webform--${component.configuration.webform_id}`)}>
        {component.configuration.label_display === "visible" && (
          <h2 className="tw-text-large tw-mb-3">{component.props.name}</h2>
        )}
        <Webform webform={component.props} />
      </div>
    );
  }

  return <p className="text-danger">Missing props for webform block "{component.configuration.webform_id}"</p>;
};

export default WebformBlock;
