import classNames from "classnames";
import Image from "next/image";
import React, {HTMLAttributes} from "react";
import {ImageStyleId, ImageStyleObject, ResponsiveImageStyleId} from "@type/general";
import {ResponsiveImageStyle} from "@configuration/responsive-image";

type ResponsiveImageProps = HTMLAttributes<HTMLPictureElement> & {
  alt?: string;
  shadowBlack?: boolean;
  shadowBlue?: boolean;
  eager?: boolean;
  imageStyle?: ResponsiveImageStyleId;
  priority?: boolean;
  showTitle?: boolean;
  margin?: boolean;
  image: Record<ImageStyleId, ImageStyleObject>;
  hover?: boolean;
};

type ResponsiveImageConfig = {
  sm: ImageStyleId;
  md: ImageStyleId;
  fallback: ImageStyleId;
};

const ResponsiveImage = ({
  imageStyle = "seeds_large",
  alt,
  priority,
  image,
  title,
  shadowBlue,
  shadowBlack,
  eager,
  showTitle = false,
  hover = false,
  margin = false,
  ...rest
}: ResponsiveImageProps) => {
  const ResponsiveImageConfig: ResponsiveImageConfig = ResponsiveImageStyle[imageStyle as ResponsiveImageStyleId];
  const ImageStyles = {
    sm: image[ResponsiveImageConfig.sm],
    md: image[ResponsiveImageConfig.md],
    fallback: image[ResponsiveImageConfig.fallback],
  };

  rest.className = classNames(
    "responsive-image ",
    hover && "group overflow-hidden",
    shadowBlue && "image-shadow-blue",
    shadowBlack && "image-shadow-black",
  );
  if (!ImageStyles.fallback) {
    console.error('There is no fallback image for the "ResponsiveImage" component');
  }
  const maxImage: {
    maxWidth: string;
  } = {maxWidth: "100%"};

  const convertSrcNextPath = (src: string, width: number, quality: number): string => {
    const encodeURI = encodeURIComponent(src);
    return `/_next/image?url=${encodeURI}&w=${width}&q=${quality}`;
  };
  return (
    <div className={rest.className}>
      <picture>
        {ImageStyles.sm && (
          <source
            media="(max-width: 767px)"
            srcSet={convertSrcNextPath(ImageStyles.sm.href, 750, 75)}
            height={ImageStyles.sm?.meta?.height}
            width={ImageStyles.sm?.meta?.width}
          />
        )}

        {ImageStyles.md && (
          <source
            media="(max-width: 1024px)"
            srcSet={convertSrcNextPath(ImageStyles.md.href, 1200, 75)}
            height={ImageStyles.md?.meta?.height}
            width={ImageStyles.md?.meta?.width}
          />
        )}
        {ImageStyles.fallback && (
          <Image
            alt={alt ?? "alt"}
            src={ImageStyles.fallback.href}
            height={ImageStyles.fallback?.meta?.height}
            width={ImageStyles.fallback?.meta?.width}
            title={title}
            loading={eager ? "eager" : "lazy"}
            priority={priority}
            style={maxImage}
            className={classNames(hover && "group-hover:scale-110 2k:w-full transition-all duration-500 hover:scale-125")}
          />
        )}
      </picture>
    </div>
  );
};

export default ResponsiveImage;
