import {GenericControlRef, WebformElement} from "@type/general";
import React from "react";
import {Form} from "react-bootstrap";

const GenericInputElement = React.forwardRef<any, GenericControlRef<WebformElement>>(
  ({webformElement, ...rest}, ref) => {
    let type = webformElement["#type"] as string;
    const placeholder = webformElement["#placeholder"];
    // We force the email to be 'text' because it does not work well with react-hook-form validation.
    if (type === "email") {
      type = "text";
    }
    return <Form.Control placeholder={placeholder} type={type} ref={ref} {...rest} />;
  },
);

export default GenericInputElement;
