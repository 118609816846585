export const INTERNAL_APIS = {
  POST: {
    WEBFORM_FILE_UPLOAD: "/api/webform/file-upload",
    CAPTCHA: "/api/captcha",
    RATING: "/api/rating",
    FILE_UPLOAD: (entity_type_id: string, bundle: string, field_name: string) =>
      `/api/championship-attachment?entity_type_id=${entity_type_id}&bundle=${bundle}&field_name=${field_name}`,
    // FILE_UPLOAD: "/api/file-upload",
    HORSE_DATA: "/api/horse-data",
    CREATE_COMMENT: "/api/comments/{entity_type}/{entity_id}",
    CHAMPIONSHIP_REGISTER: "/api/championship-resource",
    SUBSCRIBE_NEWSLETTER: "/api/newsletter",
    MODIFY_NEWSLETTER: "/api/newsletter/{sid}/{timestamp}/{hash}/modifiy",
    DELETE_NEWSLETTER: "/api/newsletter/{sid}/{timestamp}/{hash}/cancel",

  },
  GET: {
    NEWSLETTER: "/api/newsletter",
    FILE: "/api/file/{fid}",
    RATING: "/api/rating",
    VOICE_RECOGNITAION: "/api/voice-recognition",
    HORSE_DATA: "/api/horse-data",
    SOLR: "/api/solr",
    COUNTRY: "/api/country",
  },
};
export const DRUPAL_APIS = {
  POST: {
    WEBFORM_FILE_UPLOAD: "/webform_rest/{webform_id}/upload/{field_name}",
    RATING: "/api/v1/vote/",
    CREATE_COMMENT: "/api/v1/comments/{entity_type}/{entity_id}",
    FILE_UPLOAD: (entity_type_id: string, bundle: string, field_name: string) =>
      `/file/upload/${entity_type_id}/${bundle}/${field_name}`,
    CHAMPIONSHIP_REGISTER: "/api/v1/champion-registration",
    SUBSCRIBE_NEWSLETTER: "/api/v1/newsletter",
    CONFIRM_NEWSLETTER: "/api/v1/newsletter/{sid}/{timestamp}/{hash}",
    DELETE_NEWSLETTER: "/api/v1/newsletter/{sid}/{timestamp}/{hash}",

  },
  GET: {
    // TODO: Make it from front-end.
    BREADCRUMBS: "/api/v1/breadcrumb/{entity_type}/{entity_id}",
    FILE: "/entity/file/{fid}",
    COUNTRY: "/api/v1/country",
    SUBSCRIBED_NEWSLETTERS: "/api/v1/newsletter/{sid}/{timestamp}/{hash}",
    VOICE_RECOGNITAION: "/api/v1/voice_recognition",
    RATING: "/api/v1/vote/{entity_type}/{entity_id}",
    GALLERY_META_DATA: "/api/v1/gallery_meta",
    HORSE_CARE_META_DATA: "{langcode}/api/v1/horse_care_meta",
    SOLR_SEARCH: "/api/v1/search",
  },
};
