import React, {useEffect, useRef} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Style from "./Recaptcha.module.scss";
type RecaptchaComponent = {
  className?: string;
  onChange: (value: string | null) => void;
  value: string | null;
};

const Recaptcha = ({className, onChange, value}: RecaptchaComponent) => {
  const ref = useRef<ReCAPTCHA>(null);
  useEffect(() => {
    if (!value) {
      ref.current?.reset();
    }
  }, [value]);
  const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  return siteKey ? (
    <div className={Style.CaptchaWrapper}>
      <div>
        <ReCAPTCHA onChange={onChange} ref={ref} className={className} sitekey={siteKey} />
      </div>
    </div>
  ) : null;
};

export default Recaptcha;
