import React, {useState} from "react";
import Heading from "../../elements/Heading";
import * as Dialog from "@radix-ui/react-dialog";
import {Button, Icon} from "@components/elements";
import classNames from "classnames";
import {t} from "@lib/translations-provider";
import CloseIcon from "@icons/close.svg";

type DialogAnimation = {
  open: string;
  closed: string;
};

type ModalProps = {
  title: string;
  toggleIcon: React.FC<React.HTMLAttributes<SVGElement>>;
  animation: DialogAnimation;
} & React.HTMLAttributes<HTMLDivElement>;

type ModalBodyProps = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const ModalBody = (props: ModalBodyProps) => {
  return <>{props.children}</>;
};

const Modal = (props: ModalProps) => {
  const {
    title,
    children,
    toggleIcon: TriggerIcon,
    animation = {
      open: "fadeIn",
      closed: "fadeOut",
    },
  } = props;
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(!show);
  };

  return (
    <div ref={wrapperRef}>
      <Dialog.Root open={show} modal={true}>
        <Dialog.Trigger asChild>
          <Button
            aria-label="Open modal"
            onClick={handleToggle}
            variant="transparent"
            className="!p-0 bg-transparent flex items-center"
            tabIndex={0}
          >
            <TriggerIcon className="w-6 h-6" />
          </Button>
        </Dialog.Trigger>

        <Dialog.Portal container={wrapperRef.current}>
          <Dialog.Overlay className="bg-gray/30  fixed inset-0  z-[2]" />
          <Dialog.Content
            onInteractOutside={() => {
              setShow(false);
            }}
            className={classNames(
              "transition-all duration-300 flex z-50 flex-col gap-8 items-center fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[580px] translate-x-[-50%] translate-y-[-50%] border border-[#E4E7EB] bg-white   rounded-lg",
              animation?.open && `data-[state=open]:animate-${animation.open} `,
              animation?.closed && `data-[state=closed]:animate-${animation.closed}`,
            )}
          >
            <div className="p-[24px] flex flex-col gap-8 items-center w-full">
              <div className="flex w-full items-center justify-between">
                <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                  <Heading className="font-somer text-[34px] font-bold" type="medium">
                    {t(title)}
                  </Heading>
                </Dialog.Title>
                <Dialog.Close onClick={handleToggle}>
                  <Icon icon={CloseIcon} className="w-6 h-6" />
                </Dialog.Close>
              </div>
              <div className="flex w-full items-center justify-between flex-col gap-8">{children}</div>
            </div>
            <div className="w-full h-full modal-pattern"></div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
Modal.Body = ModalBody;

export default Modal;
