import {Button} from "@components/elements";
import FieldError from "@components/form/FieldError";
import {t} from "@lib/translations-provider";
import React from "react";
import {NewsletterFormSchemaType} from "../types";
import {useFormContext} from "react-hook-form";
import {Form} from "@radix-ui/react-form";
import classNames from "classnames";
import Image from "next/image";
import newsLetter from "@images/icons/newsletter.png";
import KAAH from "@images/icons/white-logo.png";
import Link from "next/link";
import {Heading} from "@components/elements";

type Props = {
  onSubmit: (data: NewsletterFormSchemaType) => void;
};

const NewsletterBlock = ({onSubmit}: Props) => {
  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitting},
  } = useFormContext<NewsletterFormSchemaType>();
  return (
    <div className={classNames("relative w-full green-pattern h-[600px] text-center  ")}>
      <Image src={newsLetter.src} className="rounded-[8px]" fill alt="news letter" />
      <div className="text-white z-[2] w-full   h-full absolute pt-[32px]">
        <Link href="/" aria-label="Newsletter">
          <Image height={104} width={104} className="h-[104px]  w-[104px] m-auto" src={KAAH.src} alt="Logo" />
        </Link>
        <Heading
          element="h2"
          color="text-white"
          className="font-somer pt-[18px] text-white text-[34px] font-bold"
          type="medium"
        >
          {t("Stay Informed")}
        </Heading>
        <p className="w-[217px] m-auto text-[28px] font-normal ">{t("Logos about upcoming updates and shows")}</p>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          className={classNames(
            "news-letter-wrapper relative h-[40px] flex flex-wrap justify-center w-full px-[20px] mb-[16px] items-center",
            isSubmitting && "pointer-events-none",
          )}
        >
          <div className="max-laptop:w-full px-8">
            <input
              placeholder={t("E-mail")}
              type="text"
              {...register("newsletter_mail")}
              className=" text-[#BFBFBF] mt-[32px] mb-[16px] font-somer text-[20px] rounded-[100px] w-full m-auto "
            />
            {errors.newsletter_mail && <FieldError error={errors.newsletter_mail} />}
          </div>
          <Button aria-label="Subscription" className="text-[24px]">
            {t("Subscription")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default NewsletterBlock;
