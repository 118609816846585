"use client";
import {Button} from "@components/elements";
import {LayoutContext, useMediaQuery} from "@lib/hooks";
import {getLangcode, t} from "@lib/translations-provider";
import {hasLanguages} from "@lib/utils";
import languageEn from "@icons/english.svg";
import languageAr from "@icons/arabic.svg";
import MobileLanguageIcon from "@icons/language.svg";
import {useContext} from "react";

type Props = {
  className?: string;
};

const LanguageSwitcher = ({className}: Props) => {
  const {resource} = useContext(LayoutContext);
  const media = useMediaQuery(1024);
  if (!hasLanguages(resource)) {
    return null;
  }
  const languages = resource.languages;
  const langcode = getLangcode();
  const currentLanguageObject = languages.find((language) => language.lang === langcode);

  // If there is only 1 languege, don't show the language switcher
  if (languages.length <= 1) {
    return null;
  }

  // If there are only two languages, display a link to the other language
  if (languages.length === 2) {
    const otherLanguage = languages.find((language) => language.lang !== langcode);

    if (otherLanguage) {
      let LanguageIcon;

      if (!media) {
        if (otherLanguage.lang === "en") {
          LanguageIcon = languageEn;
        } else if (otherLanguage.lang === "ar") {
          LanguageIcon = languageAr;
        }
      } else {
        LanguageIcon = MobileLanguageIcon;
      }
      return (
        <a className="hover:bg-transparent p-[0px!important] text-[24px]" href={`${otherLanguage.href}`}>
          {media ? (
            <LanguageIcon className="w-6 h-6 stroke-blue" />
          ) : (
            <LanguageIcon className="h-[32px] w-[73px]" />
          )}
        </a>
      );
    } else {
      console.error("There are only two languages, but the other language is not found in the list of languages");
      return null;
    }
  }

  if (!currentLanguageObject) {
    return null;
  }

  // If there are more than two languages, display a modal with all the languages
  return (
    <>
      <Button aria-label="Choose Language">{t("Choose language")}</Button>
    </>
  );
};

export default LanguageSwitcher;
