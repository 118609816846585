import {GenericControlController, SelectOtherWebformElement} from "@type/general";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import React, {useEffect, useMemo, useState} from "react";
import {FormSelectProps} from "react-bootstrap";
import { t } from "@lib/translations-provider";
import SelectElement from "../SelectElement";
import OtherElement from "./components/OtherElement";
import classes from "./SelectOtherElement.module.scss";

/**
 * Select other element
 */
const SelectOtherElement = ({
  webformElement,
  value,
  onChange,
  name,
  ...rest
}: GenericControlController<SelectOtherWebformElement> & Omit<FormSelectProps, "onChange">) => {
  const [selectValue, setSelectValue] = useState<string>();
  const [otherValue, setOtherValue] = useState<string>("");

  if (!webformElement.select || !webformElement.other) {
    return null;
  }

  /**
   * Clone the select element and change the '_other_' option to a certian label.
   */
  const selectWebformElement = useMemo(() => {
    const select = cloneDeep(webformElement.select);
    if (typeof select["#options"]["_other_"] !== "undefined") {
      select["#options"]["_other_"] = t("Other");
    }
    return select;
  }, [webformElement.select]);

  /**
   * Handles the select change.
   */
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(e.target.value);
  };

  /**
   * This will send the actual value to the webform.
   */
  useEffect(() => {
    if (selectValue === "_other_") {
      onChange(otherValue);
    } else {
      onChange(selectValue);
    }
  }, [selectValue, otherValue]);

  return (
    <>
      <SelectElement
        {...rest}
        value={selectValue}
        onChange={handleSelectChange}
        webformElement={selectWebformElement}
        name={`${name}[select]`}
        className={classNames("form-group mb-4", selectValue !== "_other_" && classes.notOtherValue)}
      />

      {selectValue === "_other_" && (
        <OtherElement
          other={webformElement.other}
          value={otherValue}
          onChange={setOtherValue}
          parentName={name}
        />
      )}
    </>
  );
};

export default SelectOtherElement;
