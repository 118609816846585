import {CompoundValue, HierarchyValue} from "@type/general";
import classNames from "classnames";
import {map, pickBy} from "lodash";
import {useMemo, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {t} from "@lib/translations-provider";
import Radio from "../Radio";
import SearchTextField from "../SearchTextField";
import classes from "./RadioSelect.module.scss";
import RoundedVariant from "./RoundedVariant";

export type RadiosSelectVarientProps = {
  label: string;
  className?: string;
  onChange: (value: CompoundValue | null) => void;
  value?: HierarchyValue | CompoundValue | null;
  toggle?: boolean;
  placeholder?: string;
};

export type RadiosSelectProps = {
  onChange: (value: CompoundValue | null) => void;
  value?: CompoundValue | HierarchyValue | string | null;
  items: Record<string, string>;
  name?: string;
  buttonClassName?: string;
  dropDownClassname?: string;
  search?: boolean;
  /**
   * Choose if you can unselect the radio when clicking on the toggle button.
   */
  toggle?: boolean;
  /**
   * This will show the current selected option.
   */
  showCurrentSelectedOption?: boolean;
  nullableLabel?: string;
  label: string;
  showLabelWithSelectedOption?: boolean;
  variant?: "rounded" | "white" | "minimal";
  showCircle?: boolean;
  unchangableLabel?: string;
  placeholder?: string;
};

/**
 * This component has two types of return, 'normal' and 'compound', the reason for this is that we want to use 'compount' in closable exposed filters. This
 * will make it easier.
 */
const RadioSelect = ({
  items,
  onChange,
  value,
  name = "radio-select",
  label,
  buttonClassName,
  dropDownClassname,
  search = true,
  toggle = false,
  nullableLabel,
  showCircle = true,
  variant = "rounded",
  placeholder,
}: RadiosSelectProps) => {
  const [searchValue, setSearchValue] = useState("");
  const compoundValue: CompoundValue | HierarchyValue | null | undefined = useMemo(() => {
    let actualValue: CompoundValue | HierarchyValue | null | undefined;
    if (typeof value === "string") {
      actualValue = {
        value: value,
        label: items[value],
      };
    } else {
      actualValue = value;
    }

    return actualValue;
  }, [value]);

  /**
   * This will return the label for the current selected option.
   */
  const filteredItems = useMemo(() => {
    const allItems = {...items};

    // Filter items using searchValue.
    if (!searchValue) {
      return allItems;
    }
    const lowerCaseSearchValue = searchValue.toLowerCase();
    // Use pickBy.
    return pickBy(allItems, (value) => {
      return value === "" || value.toLowerCase().includes(lowerCaseSearchValue);
    });
  }, [searchValue, items, nullableLabel]);

  const handleChange = (value: string, label: string) => {
    onChange({value, label});
  };

  const isChecked = (val: string) => {
    if (!compoundValue) {
      return false;
    }
    return val === compoundValue.value;
  };

  const varientProps: RadiosSelectVarientProps = {
    toggle,
    value: compoundValue,
    label,
    onChange,
    placeholder,
  };
  const variantClassName = classes[`${variant}Variant`];
  return (
    <Dropdown className={classNames(classes.dropdown, buttonClassName, variantClassName)}>
      <RoundedVariant {...varientProps} />
      {/* Menu */}
      <Dropdown.Menu className={classNames(classes.dropdownMenu, dropDownClassname)}>
        {/* Search */}
        {Object.keys(items ?? {}).length != 0 ? (
          search &&
          Object.keys(items ?? {}).length > 5 && (
            <SearchTextField className={classes.searchField} onChange={setSearchValue} value={searchValue} />
          )
        ) : (
          <div className={classes.noOptions}>{t("No options available")}</div>
        )}
        <div className={variant != "minimal" ? classes.itemWrapper : classes.minimalWrapper}>
          {/* Nullable value */}
          {nullableLabel && Object.keys(items ?? {}).length != 0 && (
            <Dropdown.Item as="div" bsPrefix="radios-dropdown-item" className={classes.dropDownItem}>
              <Radio
                onChange={() => onChange(null)}
                name={name}
                id={`${name}-empty-value`}
                label={nullableLabel}
                showCircle={showCircle}
                className={classNames(variant === "minimal" ? classes.minimalRadio : "", !value && classes.checked)}
                value=""
                checked={!value}
              />
            </Dropdown.Item>
          )}

          {/* Map items */}
          {map(filteredItems, (label, value) => {
            return (
              <Dropdown.Item as="div" key={value} bsPrefix="radios-dropdown-item" className={classes.dropDownItem}>
                <Radio
                  onChange={() => handleChange(value, label)}
                  name={name}
                  id={value}
                  showCircle={showCircle}
                  className={classNames(
                    variant === "minimal" ? classes.minimalRadio : "",
                    isChecked(value) && classes.checked,
                    classes.dropDownRadio,
                  )}
                  label={label}
                  value={value}
                  checked={isChecked(value)}
                  placeholder={label}
                />
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RadioSelect;
