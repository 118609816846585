import {useLayout} from "@lib/hooks";
import {isDrupalPage} from "@lib/utils";
import map from "lodash/map";
import classes from "./ThirdLevelNavigation.module.scss";
import {Heading} from "@components/elements";
import classNames from "classnames";
import Link from "next/dist/client/link";

const ThirdLevelNavigation = () => {
  const {resource} = useLayout();
  if (
    !isDrupalPage(resource) ||
    !resource.menu_items ||
    !resource.menu_items.items ||
    resource.menu_items.items.length === 0
  ) {
    return null;
  }

  return (
    <>
      {resource.field_boolean_2 && (
        <div className={classNames(resource.parent_banner ? "banner" : "")}>
          <div className="container mx-auto">
            <Link aria-label={resource.parent_menu_title} href={resource.parent_canonical}>
              <Heading type="medium">{resource.parent_menu_title}</Heading>
            </Link>
            {resource.parent_summary && <p className={classes.summary}>{resource.parent_summary}</p>}
          </div>
        </div>
      )}
      <div className="container mx-auto">
        <nav className={classes.menuWrapper}>
          <ul className={classes.menu}>
            {map(resource.menu_items.items, (item) => (
              <li key={item.id}>
                <Link aria-label={item.title} className={classes.link} href={item.url}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default ThirdLevelNavigation;
