import {SkeletonLoaderConfig, StatusString} from "@type/general";
import {default as classname, default as classNames} from "classnames";
import Logo from "@icons/loader-logo.svg";
import {Icon} from "@components/elements";
import {motion, Variants} from "framer-motion";
interface LoaderStatus {
  contentType: "page" | "view";
  success?: boolean;
}

const variants: Variants = {
  open: {opacity: 1, y: 0, transition: {duration: 0.5}, zIndex: 113},
  closed: {opacity: 0, y: "-300%", transition: {duration: 0.5}, zIndex: -1},
};

export const LoaderStatus = ({contentType, success}: LoaderStatus) => {
  if (contentType == "view") {
    return null;
  }
  return (
    <motion.div
      animate={success ? "closed" : "open"}
      variants={variants}
      layout
      className={classNames("loader bg-white min-h-screen w-full z-[113] fixed")}
    >
      <div className="footer-pattern">
        <div className="flex justify-center flex-col items-center h-screen">
          <Icon className={"w-[145px] h-[145px]"} icon={Logo} />
          <div className="flex animate-pulse mt-6">
            <div className="w-4 h-4 mr-1 bg-primary rounded-full"></div>
            <div className="w-4 h-4 mr-1 bg-primary rounded-full"></div>
            <div className="w-4 h-4 mr-1 bg-primary rounded-full"></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const SkeletonStatus = ({skeleton, success}: {skeleton: SkeletonLoaderConfig; success: boolean}) => {
  const Skeleton = skeleton.loader as React.FC;
  if (success) {
      return null;
  }
  return (
    <div className={classNames( success && "hidden")}>
      <div className={skeleton.wrapperClassname}>
        {Array.from({length: skeleton.items as number}, (_, i) => (
          <Skeleton key={i} />
        ))}
      </div>
    </div>
  );
};

type StatusPropsInterface = {
  status: StatusString | StatusString[] | boolean | boolean[];
  keepContent?: boolean;
  failRender?: string;
  children?: any;
  className?: string;
  skeleton?: SkeletonLoaderConfig;
  contentType: "page" | "view";
};

const Loader = ({
  status,
  keepContent = false,
  children,
  skeleton = {
    items: undefined,
    loader: undefined,
    keepContent: undefined,
  },
  contentType = "page",
  className = "page",
}: StatusPropsInterface) => {
  let failed: boolean;
  let loading: boolean;
  if (status instanceof Array) {
    // Check for fail.

    failed = status.some((status) => status === "failed");
    loading = status.some((status) => status === true || status === "loading");
  } else if (typeof status === "boolean") {
    loading = status;
    failed = false;
  } else {
    failed = status === "failed";
    loading = status === "loading";
  }
  const success = !loading && !failed;

  const renderLoader = () => {
    // if loading is true, check if there is skeleton loader, then get number of items to render the skeleton loader
    if (skeleton.items && skeleton.loader) {
      return <SkeletonStatus skeleton={skeleton} success={success} />;
    } else {
      return <LoaderStatus contentType={contentType} success={success} />;
    }
  };

  const childrenRenderMode = () => {
    if (keepContent) {
      return typeof children === "function" ? children() : children;
    } else {
      if (loading) {
        return null;
      } else {
        return typeof children === "function" ? children() : children;
      }
    }
  };
  return (
    <div className="">
      {renderLoader()}
      <div className={classname("status-block-content relative", className)}>{childrenRenderMode()}</div>
    </div>
  );
};

export default Loader;
