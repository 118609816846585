import {Icon} from "@components/elements";
import {t} from "@lib/translations-provider";
import {FieldLink} from "@type/fields";
import {GeneralIcon} from "@type/general";
import classNames from "classnames";
import {useRouter} from "next/router";
import React, {AnchorHTMLAttributes, useMemo} from "react";
import OptimizedLink from "./OptimizedLink";
type Props = {
  link: FieldLink | string;
  label?: string;
  children?: React.ReactNode;
  className?: string;
  icon?: GeneralIcon;
  iconClassName?: string;
  iconPosition?: "left" | "right";
  contentWrapperClassName?: string;
  variant?:
    | "minimal"
    | "primary"
    | "primary-outline"
    | "secondary"
    | "secondary-outline"
    | "transparent"
    | "white"
    | "read-more"
    | "blue";
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">;

const DrupalLink = ({
  link,
  children,
  label,
  variant = "primary",
  icon,
  iconClassName,
  iconPosition = "left",
  className,
  contentWrapperClassName,
  ...rest
}: Props) => {
  if (!link) return null;
  const router = useRouter();
  let classes = "";
  switch (variant) {
    case "minimal":
      classes = "bg-transparent";
      break;
    case "primary":
      classes =
        " rfs-22 px-[24px] py-[10px]  bg-primary text-white border-solid border border-primary hover:opacity-80  ";
      break;
    case "primary-outline":
      classes =
        " rfs-22  px-[24px] py-[10px]  bg-transparent text-primary border border-primary hover:bg-primary hover:text-white";
      break;
    case "secondary":
      classes = " rfs-22  px-[24px] py-[10px]  bg-gray text-white hover:bg-gray-600";
      break;
    case "secondary-outline":
      classes =
        " rfs-22  px-[24px] py-[10px]  bg-transparent text-gray border border-gray hover:bg-gray hover:text-white";
      break;
    case "white":
      classes =
        " rfs-22  px-[24px] py-[10px] bg-transparent text-white border border-white hover:bg-white hover:text-blue";
      break;
    case "transparent":
      classes = " rfs-22 px-[24px] py-[10px]  bg-transparent ";
      break;
    case "read-more":
      classes = " rfs-22 bg-transparent text-primary";
      break;
    case "blue":
      classes = " rfs-26 bg-transparent text-blue-link";
      break;
    default:
      classes = " rfs-22 bg-primary text-white hover:bg-primary";
      break;
  }
  /**
   * Checks if the link is external.
   */
  const isExternal = useMemo(() => {
    let toCheck: string;
    if (typeof link === "string") {
      toCheck = link;
    } else {
      toCheck = link.url ? link.url : link.uri;
    }
    // Check if link starts with a protocol.
    return toCheck.match(/^(?:[a-zA-Z]+:)?\/\//);
  }, [link]);

  /**
   * Returns the correct link based on the link type.
   */
  const handleLink = (link: FieldLink | string) => {
    if (typeof link === "string") {
      return link;
    }

    if (link.url) {
      return link.url;
    }
    if (link.uri.startsWith("entity:")) {
      return "/" + router.locale + link.uri.replace("entity:", "/");
    } else if (link.uri.startsWith("internal:")) {
      return "/" + router.locale + link.uri.replace("internal:", "");
    } else {
      const finalLink = isExternal ? link.uri : "/" + router.locale + link.uri;
      return finalLink;
    }
  };

  /**
   * Returns the correct link component based on if the link is external or not.
   */
  const LinkComponent = isExternal ? "a" : OptimizedLink;
  return children ? (
    <LinkComponent
      {...rest}
      className={classNames("rounded-[1000px]", classes, className)}
      href={handleLink(link)}
      target={isExternal ? "_blank" : "_self"}
      rel="follow"
      aria-label={label ?? "Link"}
    >
      {iconPosition == "left" ? (
        <div className={contentWrapperClassName}>
          {icon && (
            <Icon
              className={classNames(iconClassName ? iconClassName : "inline me-[8px] w-[20px] h-[20px]")}
              icon={icon}
            />
          )}
          {children}
        </div>
      ) : (
        <div className={contentWrapperClassName}>
          {children}
          {icon && (
            <Icon
              className={classNames(iconClassName ? iconClassName : "inline ms-[8px] w-[20px] h-[20px]")}
              icon={icon}
            />
          )}
        </div>
      )}
    </LinkComponent>
  ) : (
    <LinkComponent
      {...rest}
      href={handleLink(link)}
      target={isExternal ? "_blank" : "_self"}
      rel="follow"
      aria-label={label ?? "Link"}
      className={classNames("rounded-[1000px]", classes, className)}
    >
      {iconPosition == "left" ? (
        <span className={contentWrapperClassName}>
          {icon && (
            <Icon
              icon={icon}
              className={classNames(iconClassName ? iconClassName : "inline me-[8px] w-[20px] h-[20px]")}
            />
          )}
          {label ? label : t("Read more")}
        </span>
      ) : (
        <span className={contentWrapperClassName}>
          {label ? label : t("Read more")}
          {icon && (
            <Icon
              className={classNames(iconClassName ? iconClassName : "inline ms-[8px] w-[20px] h-[20px]")}
              icon={icon}
            />
          )}
        </span>
      )}
    </LinkComponent>
  );
};

export default DrupalLink;
