import {getFieldErrorMessage} from "@lib/utils";
import {FieldGroupProps, WebformElement} from "@type/general";
import classNames from "classnames";
import React, {useMemo} from "react";
import {Form} from "react-bootstrap";
import classes from "./field.module.scss";
import {useRouter} from "next/router";
const Field = ({
  label,
  children,
  error,
  required = false,
  className,
  invisibleLabel = false,
  ...rest
}: FieldGroupProps) => {
  /**
   * Gets the elemnt id from the children
   */

  const router = useRouter();
  const elementId = useMemo(() => {
    let elementIdToReturn: string | undefined;
    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }

      if (
        (child.props as Record<string, any>) &&
        "id" in (child.props as Record<string, any>) &&
        (child.props as Record<string, any>).id
      ) {
        elementIdToReturn = (child.props as Record<string, any>).id;
      }

      if (child.props && (child.props as Record<string, any>).webformElement) {
        const webformElement = (child.props as Record<string, any>).webformElement as WebformElement;
        if (webformElement["#type"] === "radios") {
          elementIdToReturn = undefined;
        }
      }
    });
    return elementIdToReturn;
  }, [children, router.route]);

  const childrenArray = children instanceof Array ? children : [children];
  let elementType = "";
  if (!(children instanceof Array) && children.props && children.props.webformElement) {
    const webformElement = children.props.webformElement as WebformElement;
    elementType = webformElement["#type"];
  }
  return (
    <Form.Group
      className={classNames("form-group mb-4 ", className, `type-${elementType}`, required && "required")}
      {...rest}
    >
      {label && (
        <Form.Label htmlFor={elementId} className={classNames(invisibleLabel && "d-none", classes.fieldLabel)}>
          {label}
          {required ? <span> * </span> : ""}
        </Form.Label>
      )}
      {React.Children.map(childrenArray, (child) => React.cloneElement(child as JSX.Element, {isInvalid: !!error}))}
      {error && <div className="block text-error font-normal" dangerouslySetInnerHTML={{__html: getFieldErrorMessage(error)}} />}
    </Form.Group>
  );
};

export default Field;
