import {Button as ButtonElement} from "@components/elements";
import {ButtonWithIconProps} from "@components/elements/Button";
import DatePickerField, {DatePickerFieldProps} from "@components/form/DatepickerField/DatePickerField";
import RadioSelect, {RadiosSelectProps} from "@components/form/RadioSelect";
import SearchTextField, {SearchTextFieldProps} from "@components/form/SearchTextField";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useExposedFiltersContext} from "@lib/hooks";
import ar from "date-fns/locale/ar";

import {isCompoundValue} from "@lib/utils";
import {CompoundValue, ExposedFitlerValue, RegionChildValue, SortValue, useExposedFiltersReturn} from "@type/general";
import classNames from "classnames";
import {cloneDeep, filter, find, forEach, indexOf, map, omit, pickBy, some, toArray} from "lodash";
import moment from "moment";
import React, {HTMLAttributes, useContext, useEffect, useMemo, useState} from "react";
import {t} from "@lib/translations-provider";
import classes from "./ExposedFilters.module.scss";
import {useRouter} from "next/router";
type Props = {
  children: JSX.Element | JSX.Element[];
  noActiveFilters?: boolean;
  customWrapping?: boolean;
  hierarchyDeepest?: boolean;
  leftWrapperClassName?: string;
  rightWrapperClassName?: string;
  rightWrapperMinWidth?: boolean;
} & useExposedFiltersReturn<ExposedFitlerValue> &
  HTMLAttributes<HTMLDivElement>;

export const ExposedFiltersContext = React.createContext<useExposedFiltersReturn>({} as useExposedFiltersReturn);

const ExposedFilters = ({
  children,
  noActiveFilters,
  setExposedFilter,
  values,
  setViewArguments,
  setSortBy,
  rightWrapperMinWidth = true,
  sortValue,
  setValues,
  args,
  leftWrapperClassName,
  rightWrapperClassName,
  customWrapping = false,
  className,
  hierarchyDeepest = false,
  ...rest
}: Props) => {
  const providerValues = {setExposedFilter, values, setSortBy, sortValue, setValues, setViewArguments, args};
  const renderActiveFilters = () => {
    return (
      <div className={classes.activeFilters}>
        {map(values, (fieldValue, fieldName) => {
          if (isCompoundValue(fieldValue)) {
            const newClearedValues = cloneDeep(omit(values, fieldName));
            return (
              <ButtonElement
                className={classes.activeFilterButton}
                icon={faXmark}
                key={fieldName}
                iconPosition="right"
                onClick={() => setValues(newClearedValues)}
              >
                {fieldValue.label}
              </ButtonElement>
            );
          } else if (fieldValue instanceof Array) {
            return map(fieldValue, (fieldValueInArray, index) => {
              const newClearedValues = cloneDeep(fieldValue);
              newClearedValues.splice(index, 1);

              return (
                <ButtonElement
                  className={classes.activeFilterButton}
                  icon={faXmark}
                  iconPosition="right"
                  key={index}
                  onClick={() => setExposedFilter(fieldName, newClearedValues)}
                >
                  {fieldValueInArray.label}
                </ButtonElement>
              );
            });
          } else {
            return null;
          }
        })}
        {(checkObjectLength(values as Record<string, ExposedFitlerValue>) ||
          checkObjectChildrenLength(values as Record<string, ExposedFitlerValue>)) && (
          <ButtonElement
            className={classes.activeFilterButton}
            icon={faXmark}
            iconPosition="right"
            onClick={() => {
              setValues({});
              setViewArguments([]);
            }}
          >
            {t("Clear all")}
          </ButtonElement>
        )}
      </div>
    );
  };
  const hasActiveFilters = useMemo(() => {
    return some(values, (value) => isCompoundValue(value) || value instanceof Array) && !noActiveFilters;
  }, [values, noActiveFilters]);
  const checkObjectChildrenLength = (values: Record<string, ExposedFitlerValue>) => {
    let result = false;
    const newValues = omit(values, "startDate", "endDate", "title", "decisionType");
    forEach(newValues, (value, key) => {
      if (isCompoundValue(value)) {
        result = result || checkObjectChildrenLength(value);
      } else if (value instanceof Array) {
        result = key === "city" ? false : result || value.length > 1;
      } else if (!value) {
        result = false;
      }
    });
    return result;
  };
  const checkObjectLength = (values: Record<string, ExposedFitlerValue>) => {
    let newValues = omit(values, "startDate", "endDate", "title", "decisionType");
    forEach(newValues, (value, key) => {
      if (isCompoundValue(value)) {
        checkObjectLength(value);
      } else if (value instanceof Array) {
        if (value.length < 1) {
          newValues = omit(newValues, key);
        }
      } else if (!value) {
        newValues = omit(newValues, key);
      }
    });
    return Object.keys(newValues).length > 1;
  };

  if (customWrapping) {
    return (
      <ExposedFiltersContext.Provider value={providerValues}>
        <div {...rest} className={className}>
          {children}
        </div>
        {hasActiveFilters && renderActiveFilters()}
      </ExposedFiltersContext.Provider>
    );
  }

  const leftWrapper: JSX.Element[] = [];
  const rightWrapper: JSX.Element[] = [];

  // Filter and sort children.
  const forEachChildren = (children: JSX.Element | JSX.Element[]) => {
    if (!children) {
      return;
    }
    map(children as JSX.Element[], (child, index) => {
      if (child) {
        if (child.type.displayName === "Buttons") {
          const buttonsChildren = child.type(child.props)?.props?.children;
          forEachChildren(buttonsChildren);
        } else if (child.props.position) {
          if (child.props.position === "left") {
            leftWrapper.push(
              <div
                key={index}
                className={classNames(
                  child.type?.displayName === "Radios" || child.type?.displayName === "DatePicker"
                    ? classes.element
                    : classes.buttonElement,
                )}
              >
                {child}
              </div>,
            );
          } else if (child.props.position === "right") {
            rightWrapper.push(
              <div
                key={index}
                className={classNames(
                  child.type?.displayName === "Radios" || child.type?.displayName === "DatePicker"
                    ? classes.element
                    : classes.buttonElement,
                )}
              >
                {child}
              </div>,
            );
          }
        } else if (child.type?.displayName === "Search") {
          rightWrapper[0] = <React.Fragment key={index}>{child}</React.Fragment>;
        } else if (child.type?.displayName === "Sort") {
          rightWrapper[1] = <React.Fragment key={index}>{child}</React.Fragment>;
        } else if (child.type?.displayName === "Hierarchy") {
          leftWrapper.push(
            <div key={index} className={classNames(hierarchyDeepest ? classes.HierarchyElements : classes.element)}>
              {child}
            </div>,
          );
        }
      }
    });
  };

  forEachChildren(children);
  return (
    <ExposedFiltersContext.Provider value={providerValues}>
      <div
        className={classNames(classes.filtersWrapper, noActiveFilters && classes.noActiveFilter, className)}
        {...rest}
      >
        <div className={classes.topWrapper}>
          <div className={classNames(classes.leftWrapper, leftWrapperClassName)}>{leftWrapper}</div>
          <div
            className={classNames(
              classes.rightWrapper,
              rightWrapperClassName,
              rightWrapperMinWidth ? classes.rightWrapperMinWidth : "",
            )}
          >
            {rightWrapper}
          </div>
        </div>

        {hasActiveFilters && renderActiveFilters()}
      </div>
    </ExposedFiltersContext.Provider>
  );
};

/**
 * Radios inside exposed filter
 *
 */
type RadiosInsideExposedFilters = Omit<RadiosSelectProps, "name" | "onChange" | "value"> & {
  name: string;
  unlimited?: boolean;
  onChange?: (value: CompoundValue | null) => void;
  position?: "left" | "right";
  placeholder?: string;
  label?: string;
  useSideContext?: boolean;
};

const Radios: React.FC<RadiosInsideExposedFilters> & {displayName?: string} = ({
  name,
  buttonClassName,
  unlimited = false,
  items,
  useSideContext = false,
  ...rest
}: RadiosInsideExposedFilters) => {
  const {setExposedFilter, values} = useExposedFiltersContext<CompoundValue | CompoundValue[] | null>();
  const radiosValue = useMemo(() => {
    const defaultValue = unlimited ? [] : null;
    return values[name] ? values[name] : defaultValue;
  }, [values, name, unlimited]);

  const filteredItems = useMemo(() => {
    if (unlimited && radiosValue instanceof Array && radiosValue.length > 0) {
      return pickBy(items, (label, value) => {
        return !find(radiosValue, (selectedItem) => selectedItem.value === value);
      });
    } else {
      return items;
    }
  }, [items, unlimited, radiosValue]);

  if (!name) {
    throw new Error("ExposedFilters.Radios: name is required");
  }

  const handleChange = (value: CompoundValue | null) => {
    let newValues;
    if (unlimited && value) {
      newValues = radiosValue instanceof Array ? cloneDeep(radiosValue) : [];
      newValues.push(value);
    } else {
      newValues = value;
    }
    setExposedFilter(name, newValues);
  };

  const getValue = () => {
    if (!unlimited && isCompoundValue(radiosValue)) {
      return radiosValue;
    }

    return null;
  };

  return (
    <RadioSelect
      name={name}
      value={getValue()}
      buttonClassName={classNames(classes.exposedInput, buttonClassName)}
      onChange={handleChange}
      items={filteredItems}
      {...rest}
    />
  );
};
ExposedFilters.Radios = Radios;
Radios.displayName = "Radios";

// hierarchy exposed filter.

type HierarchyType = Omit<RadiosSelectProps, "name" | "onChange" | "value" | "items"> & {
  parentName: string;
  childName: string;
  parentLabel: string;
  childLabel: string;
  parentItems: Record<string, string>;
  childItems: RegionChildValue[];
  exposedFilters: any;
  showDeepest?: boolean;
  className?: string;
  useSideContext?: boolean;
};
const Hierarchy: React.FC<HierarchyType> & {displayName?: string} = ({
  parentName,
  childName,
  className,
  buttonClassName,
  parentLabel,
  childLabel,
  parentItems,
  childItems,
  exposedFilters,
  showDeepest = false,
  useSideContext = false,

  ...rest
}: HierarchyType) => {
  const {setExposedFilter, values} = useExposedFiltersContext<string>();
  const [parentValue, setParentValue] = useState<CompoundValue | null | undefined>(
    (values[parentName] as CompoundValue) || null,
  );
  useEffect(() => {
    setParentValue((values[parentName] as CompoundValue) || null);
    setParentValue((values[childName] as CompoundValue) || null);
  }, []);

  const allChildItemsObject = useMemo(() => {
    const objects: Record<string, string> = {};

    // Filter by selected reigon.
    const filtered = filter(childItems, (child) => {
      if (values[parentName]) {
        return !parentValue || parentValue.value == child.parent;
      } else return true;
    });

    // forEach.
    forEach(filtered, (child) => {
      objects[child.tid] = child.name;
    });

    return objects;
  }, [childItems, parentValue]);
  const onChangeParent = (value: CompoundValue | null) => {
    setParentValue(value);
    setExposedFilter(parentName, value as Record<number, string | number>);
  };

  const onChangeChild = (value: CompoundValue | null) => {
    setExposedFilter(childName, value as Record<number, string | number>);
  };

  return (
    <div className={classNames(classes.HerarichyWrapper, className)}>
      <RadioSelect
        toggle={true}
        name={parentName}
        value={values[parentName] || null}
        nullableLabel={t(`${parentLabel}`)}
        items={parentItems}
        showLabelWithSelectedOption
        onChange={onChangeParent}
        buttonClassName={classNames(classes.select, !showDeepest && classes.fullWidth)}
        label={t(`${parentLabel}`)}
      />
      {showDeepest && (
        <RadioSelect
          {...rest}
          toggle={true}
          name={childName}
          nullableLabel={t(`${childLabel}`)}
          value={values[childName] || null}
          items={allChildItemsObject}
          onChange={onChangeChild}
          buttonClassName={classNames(classes.lastSelect, classes.select)}
        />
      )}
    </div>
  );
};

ExposedFilters.Hierarchy = Hierarchy;
Hierarchy.displayName = "Hierarchy";
/**
 * Sort inside exposed filters.
 */
type SortInsideExposedFilters = {items: SortValue[]; defaultValue?: string; useSideContext?: boolean} & Omit<
  RadiosInsideExposedFilters,
  "name" | "items"
>;

const Sort: React.FC<SortInsideExposedFilters> & {displayName?: string} = ({
  buttonClassName,
  items,
  useSideContext = false,
  ...rest
}: SortInsideExposedFilters) => {
  const {setSortBy} = useContext(ExposedFiltersContext);
  const [actualValue, setActualValue] = useState<CompoundValue | null>(null);
  useEffect(() => {
    const defaultValue = "created";
    const item: SortValue[] = filter(items, (item) => item.name === defaultValue);
    const index = indexOf(items, item[0]);
    setActualValue({value: `${index}`, label: item[0].label});
  }, []);
  // Convert items into Record<string,string>
  const actualItems = useMemo(() => {
    const newItems: Record<string, string> = {};
    forEach(items, (item, index) => {
      newItems[index] = item.label;
    });
    return newItems;
  }, [items]);

  const getOrder = (value: number): "asc" | "desc" => {
    return items[value].sortOrder;
  };

  const getSortBy = (value: number): string => {
    return items[value].name;
  };

  const handleChange = (value: CompoundValue | null) => {
    setSortBy(value ? {sort_by: getSortBy(parseInt(value.value)), sort_order: getOrder(parseInt(value.value))} : null);
    // This looks wrong. Why are we setting both value and actualValue?
    setActualValue(value);
  };

  return (
    <RadioSelect
      {...rest}
      name="sort_by"
      showCurrentSelectedOption
      items={actualItems}
      value={actualValue}
      buttonClassName={classNames(classes.exposedInput, buttonClassName)}
      dropDownClassname={classes.sortDropdown}
      onChange={handleChange}
    />
  );
};
ExposedFilters.Sort = Sort;
Sort.displayName = "Sort";

/**
 * Textfield inside exposed filters.
 */
type SearchFieldInsideExposedFilters = Omit<SearchTextFieldProps, "name" | "onChange" | "value"> & {
  name: string;
  position?: "left" | "right";
  useSideContext?: boolean;
};

const Search: React.FC<SearchFieldInsideExposedFilters> & {displayName?: string} = ({
  name,
  className,
  useSideContext = false,
  ...rest
}: SearchFieldInsideExposedFilters) => {
  const {setExposedFilter, values} = useExposedFiltersContext<string>();
  if (!name) {
    throw new Error("ExposedFilters.Search: name is required");
  }

  return (
    <SearchTextField
      value={values[name] as string}
      {...rest}
      className={classNames(classes.exposedInput, className)}
      onChange={(value) => setExposedFilter(name, value, true)}
    />
  );
};

ExposedFilters.Search = Search;
Search.displayName = "Search";

/**
 * Button inside exposed filters.
 */
type ButtonInsideExposedFilters = Omit<ButtonWithIconProps, "name" | "onClick" | "variant" | "children"> & {
  name: string;
  value: string;
  label: string;
  canCancel?: boolean;
  useSideContext?: boolean;
};

const Button: React.FC<ButtonInsideExposedFilters> & {displayName?: string} = ({
  name,
  value,
  className,
  label,
  canCancel = false,
  useSideContext = false,
  ...rest
}: ButtonInsideExposedFilters) => {
  const {setExposedFilter, values} = useExposedFiltersContext<string | null>();
  const isActive = values[name] === value;
  return (
    <ButtonElement
      className={classNames(classes.exposedInput, className)}
      {...rest}
      onClick={() => setExposedFilter(name, canCancel && isActive ? null : value)}
    >
      {label}
    </ButtonElement>
  );
};

ExposedFilters.Button = Button;
Button.displayName = "Button";

/**
 * Button inside exposed filters.
 */
type ButtonsInsideExposedFilters = Omit<ButtonInsideExposedFilters, "value" | "label"> & {
  name: string;
  items: Record<string, string>;
};

const Buttons: React.FC<ButtonsInsideExposedFilters> & {displayName?: string} = ({
  name,
  items,
  className,
  ...rest
}: ButtonsInsideExposedFilters) => {
  if (toArray(items).length === 0) {
    return null;
  }

  return (
    <>
      {map(items, (label, value) => {
        return <Button {...rest} key={value} label={label} value={value} name={name} />;
      })}
    </>
  );
};

ExposedFilters.Buttons = Buttons;
Buttons.displayName = "Buttons";

/**
 * Date picker inside exposed filters.
 */

type DatePickerFieldInsideExposedFilters = Omit<DatePickerFieldProps, "name" | "onChange" | "value"> & {
  useSideContext?: boolean;
};

export const DatePicker: React.FC<DatePickerFieldInsideExposedFilters> = ({
  className,
  useSideContext = false,
  ...rest
}: DatePickerFieldInsideExposedFilters) => {
  const {setViewArguments, args} = useExposedFiltersContext<string>();
  const handleChange = (value: string) => {
    setViewArguments([moment(value).format("YYYYMM")]);
    setDateObject(moment(value).toDate());
  };

  const handleReset = (e: React.MouseEvent<HTMLElement>) => {
    setDateObject(new Date());
    setViewArguments([]);
  };
  const [dateObject, setDateObject] = useState<Date | undefined>(new Date());
  const locale = useRouter().locale === "en" ? "en" : ar;
  return (
    <div className={classes.datePickerWrapper}>
      <DatePickerField
        locale={locale}
        variant="white"
        placeholder={t("Date")}
        selected={dateObject}
        showMonthYearPicker
        popperPlacement="bottom-end"
        showPopperArrow={false}
        value={args && args.length >= 1 ? moment(args[0]).format("MMMM-YYYY") : ""}
        className={classNames(classes.exposedInput, classes.datePicker, className)}
        onChange={handleChange}
        {...rest}
      />
      {args && args.length >= 1 && (
        <ButtonElement onClick={handleReset} className={classes.resetDate} variant="minimal" icon={faXmark} />
      )}
    </div>
  );
};

ExposedFilters.DatePicker = DatePicker;
DatePicker.displayName = "DatePicker";

export default ExposedFilters;
