import {getMenuItem} from "@lib/menu-utils";
import {DrupalMenuLinkContent} from "next-drupal";
import React, {Dispatch, SetStateAction, useEffect} from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {filter, map} from "lodash";
import Link from "next/link";
import classNames from "classnames";
import {MenuParentProps} from "@type/general";
import CloseIcon from "@icons/close.svg";
import HamburgerMenuIcon from "@icons/hamburger-menu.svg";
import ChevronLeftIcon from "@icons/chevron-left.svg";
import {AnimatePresence} from "framer-motion";
import {motion} from "framer-motion";
import {Router, useRouter} from "next/router";
type MobileMenuProps = {
  mobileMenu: {
    setShowMenuMobile: Dispatch<SetStateAction<boolean>>;
    showMenuMobile: boolean;
  };
  childrenMenu: {
    setShowMenuChildren: Dispatch<SetStateAction<boolean>>;
    showMenuChildren: boolean;
  };
  setMenuLinks: Dispatch<SetStateAction<DrupalMenuLinkContent[]>>;
  menuContent: DrupalMenuLinkContent[];
};
type ListItemType = {
  className?: string;
  menuLink: DrupalMenuLinkContent;
  lastItem: boolean;
  isFirst?: boolean;
};
const ListItem = React.forwardRef<HTMLAnchorElement, ListItemType>(
  ({className, menuLink, lastItem, isFirst, ...props}, forwardedRef) => {
    return (
      <Link
        prefetch={false}
        aria-label={menuLink.title}
        href={menuLink.url}
        className={classNames(
          "text-primary pb-4 mb-4  px-4 group flex select-none items-center justify-between font-scale-28 font-medium leading-none !outline-none focus:!shadow-none",
          className,
          !lastItem && "border-b-[1px] border-b-[#ECE7E3]",
          isFirst && "pt-4",
        )}
        passHref
        {...props}
        ref={forwardedRef}
      >
        {menuLink.title}
      </Link>
    );
  },
);
type ListDropdown = {
  className?: string;
  menuLink: MenuParentProps;
  lastItem: boolean;
  isFirst?: boolean;
};
const ListDropdown = React.forwardRef<HTMLDivElement, ListDropdown>(
  ({className, menuLink, lastItem, isFirst, ...props}, forwardedRef) => {
    return (
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent className="bg-white rounded-md flex flex-col z-10">
          {map(menuLink.children, (child, i) => {
            const lastItem = i === menuLink.children.length - 1;
            return (
              <DropdownMenu.Item key={child.id}>
                <ListItem menuLink={menuLink} lastItem={lastItem} />
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    );
  },
);
type RenderMenuType = {
  menuContent: DrupalMenuLinkContent[];
  setShowMenuChildren: Dispatch<SetStateAction<boolean>>;
  setMenuLinks: Dispatch<SetStateAction<DrupalMenuLinkContent[]>>;
  showMenuChildren: boolean;
};
const renderMenu = ({menuContent, setShowMenuChildren, showMenuChildren, setMenuLinks}: RenderMenuType) => {
  const menuItems = getMenuItem(menuContent);
  const menuLinks =
    filter(menuItems, (menuLink: MenuParentProps) => {
      return menuLink.children.length > 0;
    }).length == 0
      ? menuContent
      : menuItems;
  return (
    <>
      {map(menuLinks, (menuLink: MenuParentProps, index: number) => {
        const lastItem: boolean = index === menuLinks.length - 1;
        return menuLink.children && menuLink.children.length > 0 ? (
          <DropdownMenu.Sub key={menuLink.id} onOpenChange={() => {}}>
            <motion.div
              initial={{
                translateX: 100,
                background: "transparent",
              }}
              key="list-menu"
              animate={{translateX: 0, background: "white"}}
              exit={{translateX: 100, background: "transparent"}}
            >
              <DropdownMenu.SubTrigger
                onClick={() => {
                  setShowMenuChildren(!showMenuChildren);
                  setMenuLinks(menuLink.children);
                }}
                className={classNames(
                  "text-primary pb-4 mb-4 px-4 group flex select-none items-center cursor-pointer justify-between font-scale-28 font-medium leading-none !outline-none focus:!shadow-none",
                  !lastItem && "border-b-[1px] border-b-[#ECE7E3]",
                  index == 0 && "pt-4",
                )}
              >
                {menuLink.title}
                <ChevronLeftIcon
                  className="text-primary stroke-primary w-6 h-6 top-[1px] ltr:rotate-180 transition-transform duration-[250] ease-in cursor-pointer"
                  aria-hidden
                />
              </DropdownMenu.SubTrigger>
            </motion.div>

            <ListDropdown menuLink={menuLink} lastItem={lastItem} isFirst={index == 0} />
          </DropdownMenu.Sub>
        ) : (
          <DropdownMenu.Item key={menuLink.id}>
            <motion.div
              initial={{
                translateX: 100,
                background: "transparent",
                zIndex: 100,
              }}
              key="item-menu"
              animate={{translateX: 0, background: "white", zIndex: 100}}
              exit={{translateX: 100, background: "transparent", zIndex: 100}}
            >
              <ListItem menuLink={menuLink} lastItem={lastItem} isFirst={index == 0} />
            </motion.div>
          </DropdownMenu.Item>
        );
      })}
    </>
  );
};

const MobileMenu = ({menuContent, childrenMenu, mobileMenu, setMenuLinks}: MobileMenuProps) => {
  const {showMenuChildren, setShowMenuChildren} = childrenMenu;
  const {setShowMenuMobile, showMenuMobile} = mobileMenu;
  const ref = React.useRef<HTMLDivElement>(null);
  const MobileMenuIcon = !showMenuMobile ? HamburgerMenuIcon : CloseIcon;
  const router = useRouter();
  useEffect(() => {
    Router.events.on("routeChangeStart", () => {
      setShowMenuMobile(false);
      setShowMenuChildren(false);
      setMenuLinks([]);
      document.body.style.setProperty("overflow", "");
    });
  });
  return (
    <div className="mobile-dropdown main-menu  z-50" ref={ref}>
      <div id="mobile-menu" className="mobile-menu w-full relative flex items-center">
        <DropdownMenu.Root
          dir={router.locale === "ar" ? "rtl" : "ltr"}
          modal={false}
          open={showMenuMobile}
          onOpenChange={() => {}}
        >
          <DropdownMenu.Trigger
            type="button"
            className="!outline-none focus:!shadow-none"
            onClick={() => {
              setShowMenuChildren(false);
              if (showMenuMobile) {
                setMenuLinks([]);
                document.body.style.setProperty("overflow", "");
              } else {
                document.body.style.setProperty("overflow", "hidden");
                setMenuLinks(menuContent);
              }
              setShowMenuMobile(!showMenuMobile);
            }}
          >
            <MobileMenuIcon className="w-6 h-6 max-laptop:w-[32px] max-laptop:h-[32px]" />
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal container={ref.current}>
            <AnimatePresence>
              {(showMenuMobile || showMenuChildren) && (
                <DropdownMenu.Content
                  className="w-full right-0 bg-white min-h-screen  flex flex-col z-10 p-[5px]"
                  asChild
                  forceMount
                  sideOffset={16}
                >
                  {renderMenu({menuContent, setShowMenuChildren, setMenuLinks, showMenuChildren})}
                </DropdownMenu.Content>
              )}
            </AnimatePresence>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

export default MobileMenu;
