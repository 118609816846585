import React from "react";
import {LabelDisplay, ViewBlockComponent as ViewBlockComponentType} from "@type/layout";
import {View} from "@components/hoc";

type Props = {
  component: ViewBlockComponentType;
  label?: string;
  displayLabel?: LabelDisplay;
};

const ViewBlockComponent = ({component, label, displayLabel}: Props) => {
  const {props} = component;
  if (props) {
    return <View extendedViewResults={props} label={label} displayLabel={displayLabel} />;
  }
  return null;
};

export default ViewBlockComponent;
