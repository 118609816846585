import {EntityDisplayProps} from "@type/general";
import {JsonApiResource} from "next-drupal";
import dynamic from "next/dynamic";
import React from "react";
type Props = {
  resource: JsonApiResource;
  viewMode: string;
};

const loadComponent = async (bundleId: string, contentTypeId: string, viewMode: string) => {
  let res;
  let component: React.ComponentType<
    EntityDisplayProps & {
      toogleActiveKey?: (nid: number) => void;
    }
  >;
  try {
    // Use dynamic import to load the component
    if (viewMode != "default") {
      res = await import(`@components/displays/${bundleId}/${contentTypeId}/displays/${viewMode}`);
    } else {
      res = await import(`@components/displays/${bundleId}/${contentTypeId}/default/index`);
    }
    if (res.default == undefined) {
      component = (await import("./BrokenEntity")).default;
    } else {
      component = res.default;
    }
  } catch (error) {
    component = (await import("./BrokenEntity")).default;
  }
  return component;
};

const EntityRenderer = ({resource, viewMode}: Props) => {
  let bundleId: string = "";
  const resourceType = resource.type.split("--");
  if (resourceType.length > 0) {
    bundleId = resourceType[0];
  }
  const Component = dynamic<EntityDisplayProps>(() => loadComponent(bundleId, resource.type, viewMode), {
    ssr: false,
  });
  return <Component resource={resource} contentTypeId={resource.type} viewMode={viewMode} />;
};
export default React.memo(EntityRenderer, (prevProps, nextProps) => {
  return prevProps.viewMode === nextProps.viewMode && prevProps.resource.id === nextProps.resource.id;
});
