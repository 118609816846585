import React from "react";
import {FormControl} from "react-bootstrap";
import classNames from "classnames";

import {FormControlProps} from "react-bootstrap";
import {Icon} from "@components/elements";
import SearchIcon from "@icons/search.svg";

export type SearchTextFieldProps = {
  onChange: (value: string) => void;
  placeholder?: string;
  label?: string;
} & Omit<FormControlProps, "onChange" | "onBlur" | "onFocus">;

const SearchTextField = ({onChange, className, label = undefined, placeholder, ...rest}: SearchTextFieldProps) => {
  return (
    <div className={classNames(className, "w-full px-4 py-[8.5px] flex items-center gap-4 bg-gray-lighter")}>
      <Icon icon={SearchIcon} className="w-[24px] h-[24px] [&>*]:stroke-blue" />
      <FormControl
        {...rest}
        className="w-full autofill:bg-gray-lighter bg-gray-lighter !border-0 text-[26px] font-medium text-secondary placeholder:text-secondary focus:!outline-none focus:!ring-0 focus:!shadow-none focus:!border-0"
        onChange={(e) => onChange(e.target.value)}
        type="text"
        placeholder={placeholder}
        maxLength={50}
      />
    </div>
  );
};

export default SearchTextField;
