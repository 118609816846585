import {LabelDisplay, PluginBlockComponent, PluginBlockProps} from "@type/layout";
import React from "react";
import dynamic from "next/dynamic";
import {Heading} from "@components/elements";
import TitlePattern from "@icons/title-pattern.svg";
type Props = {
  component: PluginBlockComponent;
  label?: string;
  displayLabel?: LabelDisplay;
};

const PluginBlock = ({component, label, displayLabel}: Props) => {
  let Component;
  if (component.configuration.id) {
    Component = dynamic<PluginBlockProps>(() =>
      import(`@components/plugin-blocks/${component.configuration.id}`).then((res) => {
        return res.default;
      }),
    );
    const renderBlockTitle = () => {
      if (displayLabel === "visible" && label) {
        return (
          <Heading className="mb-[30px]" icon={TitlePattern} iconPosition={"right"} element="h2" type="medium">
            {label}
          </Heading>
        );
      }
    };
    if (Component) {
      return (
        <>
          {renderBlockTitle()}
          <div className="plugin-block">
            <Component component={component} />
          </div>
        </>
      );
    }
  }

  return <p>No component found for plugin block &quot;{component.configuration.id}&quot; was not found.</p>;
};
export default React.memo(PluginBlock, (prevProps, nextProps) => {
  return prevProps.component.uuid === nextProps.component.uuid;
});
