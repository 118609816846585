import EntityRenderer from "@components/hoc/EntityRenderer";
import map from "lodash/map";
import React, {useEffect} from "react";
import {ViewPartComponentProps} from "@type/layout";
import classNames from "classnames";
import {JsonApiResource} from "next-drupal";
import {ViewMeta} from "@type/general";

const isEven = (n: number) => {
  return n % 2 == 0;
};

type ColumnType = Pick<ViewPartComponentProps, "resources" | "rowSpace" | "meta" | "equalizer" | "rowClassName">;
const fetchData = (data: JsonApiResource[], meta: ViewMeta<Record<string, any>>, equalizer: any) => {
  return map(data, (resource, index) => {
    return <EntityRenderer key={resource.id} resource={resource} viewMode={meta.row.configurations.view_mode} />;
  });
};

const TwoColumnList = ({resources, rowSpace, meta, equalizer, rowClassName = ""}: ColumnType) => {
  useEffect(() => {
    let Rows = Math.ceil(document.querySelectorAll(".views-row").length / 2);
    // setting interval to allow css to load.
    if (window.innerWidth > 992) {
      const interval = setInterval(() => {
        while (Rows >= 0) {
          const firstItem = document.querySelectorAll(`.row-${Rows} .accordion-item `)[0] as HTMLElement;
          const secondItem = document.querySelectorAll(`.row-${Rows} .accordion-item`)[1] as HTMLElement;
          firstItem &&
            secondItem &&
            (firstItem.clientHeight > secondItem.clientHeight
              ? (secondItem.style.minHeight = firstItem.clientHeight + "px")
              : (firstItem.style.minHeight = secondItem.clientHeight + "px"));
          Rows--;
        }
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [resources]);
  const evenResources: JsonApiResource[] = [];
  const oddResources: JsonApiResource[] = [];
  map(resources, (resource, index) => {
    isEven(index)
      ? evenResources.push({
          ...resource,
          index: index + 1,
        })
      : oddResources.push({
          ...resource,
          index: index + 1,
        });
  });
  return (
    <div className={classNames(rowClassName)}>
      <div>{fetchData(evenResources, meta, equalizer)}</div>
      <div>{fetchData(oddResources, meta, equalizer)}</div>
    </div>
  );
};

const OneColumnList = ({resources, rowSpace, meta, equalizer, rowClassName = ""}: ColumnType) => {
  return <div className={classNames(rowClassName)}>{fetchData(resources, meta, equalizer)}</div>;
};

export const AccordionList = ({
  resources,
  rowSpace,
  meta,
  equalizer,
  rowClassName = "",
  columns = 1,
}: ViewPartComponentProps) => {
  return columns == 1 ? (
    <OneColumnList
      resources={resources}
      rowSpace={rowSpace}
      meta={meta}
      equalizer={equalizer}
      rowClassName={rowClassName}
    />
  ) : (
    <TwoColumnList
      resources={resources}
      rowSpace={rowSpace}
      meta={meta}
      equalizer={equalizer}
      rowClassName={rowClassName}
    />
  );
};

export default AccordionList;
