import {Button} from "@components/elements";
import {useWebform} from "@lib/hooks";
import {foreachElements, hasAccessToElement} from "@lib/webform";
import {WebformElement, WebFormUnknownComponent, WebformValues} from "@type/general";
import classNames from "classnames";
import {WEBFORM_FIELDS_COMPONENTS} from "configuration/webform";
import omitBy from "lodash/omitBy";
import {Controller, useFormContext} from "react-hook-form";
import {t} from "@lib/translations-provider";
import Style from "../Webform.module.scss";
type WebformFieldProps = {
  element: WebformElement;
  name: string;
};

export const WebformField = ({element, name}: WebformFieldProps) => {
  const {
    register,
    control,
    formState: {isSubmitting},
    getFieldState,
  } = useFormContext<WebformValues>();

  const {fieldGroupComponent: Field, components} = useWebform();
  const allComponents = {...WEBFORM_FIELDS_COMPONENTS, ...components};
  if (!hasAccessToElement(element)) {
    return null;
  }

  if (element["#type"] === "webform_actions") {
    return (
      <Button aria-label="Submit" type="submit" disabled={isSubmitting} className={Style.submitButton}>
        {element["#submit__label"] ?? "Submit"}
      </Button>
    );
  }

  const webformComponent = allComponents[element["#type"]];
  if (webformComponent) {
    if (webformComponent.type === "dumb") {
      const DumbComponent = webformComponent.component;
      return <DumbComponent webformElement={element} />;
    }

    const showLabel = typeof element["#title_display"] === "string" && element["#title_display"] !== "none";
    if (webformComponent.type === "container") {
      const ContainerComponent = webformComponent.component;
      const subElements = omitBy(element, (value, key) => key.substring(0, 1) === "#") as Record<
        string,
        WebformElement
      >;
      return (
        <ContainerComponent
          webformElement={element}
          className={classNames("form-container", `form-container--${name}`)}
        >
          {foreachElements(subElements)}
        </ContainerComponent>
      );
    } else if (webformComponent.type === "ref") {
      const FieldComponent = webformComponent.component;
      return (
        <Field
          label={showLabel && !webformComponent.hideLabel ? element["#title"] : undefined}
          invisibleLabel={element["#title_display"] === "invisible"}
          required={element["#required"]}
          error={getFieldState(name).error}
        >
          <FieldComponent
            webformElement={element}
            label={element["#title"]}
            id={name}
            disabled={element["#disabled"]}
            readOnly={element["#readonly"]}
            {...register(name, {
              required: element["#required"],
              validate: (value) =>
                webformComponent.validate ? webformComponent.validate(value, element, t) : undefined,
            })}
          />
        </Field>
      );
    } else if (webformComponent.type === "controller") {
      const FieldComponent = webformComponent.component;
      return (
        <Field
          label={showLabel && !webformComponent.hideLabel ? element["#title"] : undefined}
          invisibleLabel={element["#title_display"] === "invisible"}
          required={element["#required"]}
          error={getFieldState(name).error}
        >
          <Controller
            name={name}
            control={control}
            rules={{
              required: element["#required"],
              validate: (value) => (webformComponent.validate ? webformComponent.validate(value, element, t) : true),
            }}
            render={({field}) => (
              <FieldComponent
                readOnly={element["#readonly"]}
                disabled={element["#disabled"]}
                id={name}
                webformElement={element}
                {...field}
              />
            )}
          />
        </Field>
      );
    } else {
      const unknownWebformComponent = webformComponent as unknown as WebFormUnknownComponent;
      throw new Error(`Unknown webform component type "${unknownWebformComponent.type}"`);
    }
  } else {
    return (
      <div>
        <small className="text-danger">Field of type "{element["#type"]}" was not found!</small>
      </div>
    );
  }
};
