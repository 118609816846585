import {EntityRenderer} from "@components/hoc";
import {ViewWrapperComponentFunction} from "@type/layout";
import map from "lodash/map";
import React from "react";
import {t} from "@lib/translations-provider";

const TableList = ({resources, headersTable, meta}: ViewWrapperComponentFunction) => {
  return (
    <div className="table-wrapper">
      <table>
        <thead>
          <tr>
            {map(headersTable, (headerTable) => (
              <th className="text-start">{t(headerTable)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {map(resources, (resource) => (
            <EntityRenderer resource={resource} viewMode={meta.row.configurations.view_mode} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableList;
