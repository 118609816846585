import {Button} from "@components/elements";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {getDirection, t} from "@lib/translations-provider";

type Props = {
  props: {
    disableFirstButtons: boolean;
    disableLastButtons: boolean;
    totalPages: number;
    activePage: number;
    onPageChange: (number: number) => void;
    setSelectedPage: (number: number | string) => void;
    selectedPage: number | string;
  };
};

const ButtonPagination = ({props}: Props) => {
  const isRtl = getDirection() === "rtl";
  const {disableFirstButtons, disableLastButtons, activePage, onPageChange, setSelectedPage} = props;
  return (
    <div>
      {!disableFirstButtons && (
        <Button
          aria-label="Previous page"
          icon={isRtl ? faArrowRight : faArrowLeft}
          onClick={() => {
            onPageChange(activePage - 1);
            setSelectedPage(activePage);
          }}
        >
          {t("Previous Page")}
        </Button>
      )}
      {!disableLastButtons && (
        <Button
          aria-label="Next page"
          icon={isRtl ? faArrowLeft : faArrowRight}
          onClick={() => {
            onPageChange(activePage + 1);
            setSelectedPage(activePage + 2);
          }}
          iconPosition="right"
        >
          {t("Next Page")}
        </Button>
      )}
    </div>
  );
};

export default ButtonPagination;
