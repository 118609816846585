import {ImageStyleId, ResponsiveImageStyleId} from "@type/general";

export const ResponsiveImageStyle: Record<
  ResponsiveImageStyleId,
  {
    md: ImageStyleId;
    sm: ImageStyleId;
    fallback: ImageStyleId;
  }
> = {
  seeds_banner: {
    md: "seeds_banner_992px",
    sm: "seeds_banner_less_992px",
    fallback: "seeds_banner_992px",
  },
  seeds_large: {
    md: "seeds_large_over_992px",
    sm: "seeds_large_less_992px",
    fallback: "seeds_large_over_992px",
  },
  seeds_medium: {
    md: "seeds_medium_over_992_px",
    sm: "seeds_medium_less_992px",
    fallback: "seeds_medium_over_992_px",
  },
  seeds_small: {
    md: "seeds_small_over_992px",
    sm: "seeds_small_less_992px",
    fallback: "seeds_small_over_992px",
  },
  seeds_slider: {
    md: "scale_and_crop_700_800",
    sm: "scale_and_crop_767_800",
    fallback: "scale_and_crop_700_800",
  },
  seeds_full_slider: {
    md: "scale_and_crop_1920_600",
    sm: "scale_and_crop_767_800",
    fallback: "scale_and_crop_1920_600",
  },
  service_catalog: {
    md: "scale_and_crop_767_800",
    sm: "scale_and_crop_767_800",
    fallback: "scale_and_crop_1920_342",
  },
  champions_landing: {
    md: "scale_and_crop_767_800",
    sm: "scale_and_crop_767_800",
    fallback: "scale_and_crop_1920_470",
  },
  card: {
    md: "scale_and_crop_575_480",
    sm: "scale_and_crop_750_650",
    fallback: "scale_and_crop_575_480",
  },
  teaser: {
    md: "scale_and_crop_480_325",
    sm: "scale_and_crop_550_325",
    fallback: "scale_and_crop_575_480",
  },
  large_right_teaser: {
    md: "scale_and_crop_220_265",
    sm: "scale_and_crop_767_800",
    fallback: "scale_and_crop_220_265",
  },
  canvas: {
    md: "canvas_small",
    sm: "canvas_small",
    fallback: "canvas_large",
  },
  canvas_large: {
    md: "canvas_150",
    sm: "canvas_150",
    fallback: "canvas_150",
  },
  photo_gallery_large: {
    md: "scale_and_crop_400_400",
    sm: "scale_and_crop_400_400",
    fallback: "scale_and_crop_400_400",
  },
  photo_gallery_medium: {
    md: "scale_and_crop_180_365",
    sm: "scale_and_crop_180_365",
    fallback: "scale_and_crop_180_365",
  },
  photo_gallery_small: {
    md: "scale_and_crop_200_200",
    sm: "scale_and_crop_200_200",
    fallback: "scale_and_crop_200_200",
  },
  scale_width: {
    md: "scale_width_350",
    sm: "scale_width_350",
    fallback: "scale_width_350",
  },
  landing: {
    md: "scale_and_crop_1145_500",
    sm: "scale_and_crop_1145_500",
    fallback: "scale_and_crop_1145_500",
  },

  logo: {
    md: "logo_canvas",
    sm: "logo_canvas",
    fallback: "logo_canvas",
  },
  paragraph_description: {
    md: "scale_and_crop_310_530",
    sm: "scale_and_crop_310_530",
    fallback: "scale_and_crop_310_530",
  },
};
