import classNames from "classnames";
import React from "react";
import {Form, FormCheck, FormCheckProps} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import Style from "./CheckBox.module.scss";

type Props = Omit<FormCheckProps, "type">;
const Checkbox = React.forwardRef<HTMLInputElement, Props>(({id, label, checked, is, ...rest}, ref) => {
  const {register} = useFormContext();
  return (
    <FormCheck
      bsPrefix="circle-checkbox"
      className={classNames(is ? Style.other : undefined, checked ? Style.active : "")}
      {...rest}
    >
      <FormCheck.Label htmlFor={id}>
        <FormCheck.Input className={classNames(Style.checkInput)} {...rest} type="checkbox" id={id} ref={ref} />
        {label}
        {is && <Form.Control {...register(is)} type="text" />}
      </FormCheck.Label>
    </FormCheck>
  );
});

export default Checkbox;
