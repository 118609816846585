import classNames from "classnames";
import React from "react";
type Props = {
  children: any;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Card = ({children, className, ...rest}: Props) => {
  return (
    <article
      className={classNames(
        "relative overflow-hidden text-white group transition-all duration-700 overlay max-tablet:after:bg-blue hover:after:bg-blue   ",
        className,
      )}
      tabIndex={0}
    >
      {children}
    </article>
  );
};

type BackgroundProps = {
  children: any;
} & React.HTMLAttributes<HTMLDivElement>;

const Background = ({children, className, ...rest}: BackgroundProps) => {
  return <div>{children}</div>;
};

type ForegroundProps = {
  children: any;
} & React.HTMLAttributes<HTMLDivElement>;

const Foreground = ({children, className, ...rest}: ForegroundProps) => {
  return (
    <div
      className={classNames(
        className ? className :
        "absolute z-[2] left-0 right-0 top-[100%] flex justify-center items-center flex-col  transition-all duration-700 max-tablet:top-[50%] max-tablet:translate-y-[-25%]  group-hover:top-[50%] group-hover:translate-y-[-25%]",
      )}
    >
      {children}
    </div>
  );
};

Card.Background = Background;
Card.Foreground = Foreground;

export default Card;
