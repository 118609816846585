import {FieldFormattedBody} from "@type/fields";
import classNames from "classnames";
import {Element} from "domhandler/lib/node";
import parse, {HTMLReactParserOptions} from "html-react-parser";
import isString from "lodash/isString";
import Image from "next/legacy/image";

const option: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      if (domNode.name === "img") {
        const {src, alt, width, height} = domNode.attribs;
        let source = src;
        if (isString(src) && src.startsWith("/")) {
          source = "/api/files" + src;
        }

        return (
          <Image
            src={`${source}`}
            width={Number(width)}
            height={Number(height)}
            alt={alt as string}
            layout="intrinsic"
            objectFit="cover"
          />
        );
      }
    }
    if (domNode instanceof Element) {
      if (domNode.name === "a") {
        domNode.attribs.style = "direction:ltr; display:inline-block;";
      }
    }
  },
};

type Props = {
  /**
   * Use body.
   *
   * @deprecated in favour of entity field_name
   */
  children?: string;
  body?: FieldFormattedBody;
} & React.HTMLAttributes<HTMLDivElement>;

const Body = ({body, children, className, ...rest}: Props) => {
  if (body) {
    return (
      <div {...rest} className={classNames(className, "text-formatted-body")}>
        {parse(body.processed, option)}
      </div>
    );
  } else if (children) {
    return (
      <div className={classNames(className)} {...rest}>
        {parse(children, option)}
      </div>
    );
  } else {
    return null;
  }
};

export default Body;
