import {Button} from "@components/elements";
import {ButtonWithIconProps} from "@components/elements/Button";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {Dropdown} from "react-bootstrap";
import {RadiosSelectVarientProps} from ".";
import classes from "./RadioSelect.module.scss";
import React from "react";
import arrow from "@icons/arrow.svg";
const RoundedVariant = React.forwardRef<"div", RadiosSelectVarientProps>(
  ({label, className, onChange, toggle, value, placeholder}, ref) => {
    return (
      <div className={classNames(classes.dropdownInfo, className)}>
        <span>{label}</span>
        {!value || !toggle ? (
          // Toggle dropdown
          <Dropdown.Toggle
            bsPrefix="radios-select-dropdown"
            as={DropdownButton}
            variant="minimal"
            className={classNames(classes.dropdownToggle, className)}
          >
            <span>{value ? value?.label : placeholder}</span>
          </Dropdown.Toggle>
        ) : (
          // Cancel button
          <Button
            aria-label="Radio select input"
            onClick={() => onChange(null)}
            variant="minimal"
            className={classNames(classes.dropdownToggle, className)}
            iconPosition="right"
            icon={faTimes}
          >
            {value ? value?.label : placeholder}
          </Button>
        )}
      </div>
    );
  },
);

/**
 * We want to get 'aria-expanded' from the Dropdown component.
 */
const DropdownButton = React.forwardRef<"div", ButtonWithIconProps>(({children, ...rest}, ref) => {
  return (
    <Button
      aria-label="Dropdown button"
      {...rest}
      type="button"
      className={classNames(rest["aria-expanded"] ? classes.arrowUp : classes.arrowDown, rest.className)}
      iconPosition="right"
      icon={arrow}
    >
      {children}
    </Button>
  );
});
export default RoundedVariant;
