import {View} from "@components/hoc";
import {INTERNAL_APIS} from "@configuration/apis";
import {getAxiosClient, urlArguments} from "@lib/api";
import {getLangcode, t} from "@lib/translations-provider";
import {handleError, successMessage} from "@lib/utils";
import CommentsForm from "./components/CommentsForm";

type Props = {
  entityType?: string;
  entityId: string | number;
};

export type CommentRequestBody = {
  name: string;
  body: string;
  email: string;
};

export type CommentResponseBody = {
  message: string;
  data: {
    requires_approval: 0 | 1;
    entity: CommentRequestBody & {id: string};
  };
};

/**
 * Displays comments, also, exposes a form.
 */
const Comments = ({entityType = "node", entityId}: Props) => {
  const handleSubmit = async (values: CommentRequestBody): Promise<CommentRequestBody | false> => {
    try {
      const axios = getAxiosClient();
      const res = await axios.post<CommentResponseBody>(
        urlArguments(INTERNAL_APIS.POST.CREATE_COMMENT, [entityType, entityId]),
        values,
        {
          params: {
            langcode: getLangcode(),
          },
        },
      );
      const {data, message} = res.data;
      successMessage(message);
      return data.entity;
    } catch (e) {
      handleError(e);
      return false;
    }
  };

  return (
    <div className="comments-wrapper">
      <View viewId="comments" displayId="all" viewArguements={[entityType, entityId]} />
      <label className="rfs-34 mb-6 font-bold text-blue"> {t("Leave a comment")}</label>
      <CommentsForm onSubmit={handleSubmit} />
    </div>
  );
};

export default Comments;
