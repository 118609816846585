import classNames from "classnames";
import React from "react";
type Props = {
  children: any;
  className?: string;
  pattern?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const SmallLeftTeaser = ({children, pattern = true, className}: Props) => {
  return (
    <article
      className={classNames(
        className,
        "shadow-teaser relative border-solid border px-[24px] pt-[24px] pb-[52px] border-[#E4E7EB] rounded-[8px] flex flex-wrap items-center max-tablet:flex-col",
        pattern && "small-left-teaser-pattern",
      )}
    >
      {children}
    </article>
  );
};

type HeaderProps = {
  children: any;
  className?: string;
  pattern?: boolean;
};

const Action = ({children, className, pattern = false}: HeaderProps) => {
  return <div className={classNames("max-tablet:pt-[10px]", className)}>{children}</div>;
};
const Content = ({children, className, pattern = false}: HeaderProps) => {
  return (
    <div
      className={classNames("flex-1 pe-[30px] flex max-tablet:flex-col max-tablet:w-full max-tablet:pe-0", className)}
    >
      {children}
    </div>
  );
};

const SideBar = ({children, className}: Props) => {
  return (
    <div className={classNames("pe-[20px] max-tablet:self-center max-tablet:pe-0 max-tablet:pb-[20px]", className)}>
      {children}
    </div>
  );
};

const Main = ({children, className}: Props) => {
  return <div className={classNames("flex-1", className)}>{children}</div>;
};

SmallLeftTeaser.SideBar = SideBar;
SmallLeftTeaser.Main = Main;
SmallLeftTeaser.Content = Content;
SmallLeftTeaser.Action = Action;

export default SmallLeftTeaser;
